import React, { useContext, useState, useEffect } from 'react'
import './StorageUsage.scss'

const StorageUsage = ({storageUsage, dexieActivity}) => {
    const [showUsage, setShowUsage] = useState(false)
    const [timer, setTimer] = useState(-1)

    useEffect(() => {
        if( !dexieActivity ) {
            if( timer ) {
                clearTimeout(timer)
            }
            let _timer = setTimeout(() => {
                setShowUsage(true)
                if( timer ) {
                    clearTimeout(timer)
                }
            }, 400)
            setTimer(_timer)
        }
        setShowUsage(false)
    }, [dexieActivity])

    return <div className={!showUsage ? 'storage-usage hide' : 'storage-usage show'}>
              <div>storage usage <span>{storageUsage.percentage}</span></div>
            </div>
}
export default StorageUsage;


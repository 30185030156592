import React, {useContext} from 'react'
import { StoreContext } from "../../context/StoreContext"
import './GridControl.scss'
const GridControl = ({showGrid}) => {
    const { state } = useContext(StoreContext)

    const onChangeShowGrid = evt => {
        showGrid(evt.target.checked ? true : false)
    }

    return (
        <div className="grid-control" style={{pointerEvents: state.overlay ? "none" : ""}}>
            <div>grid</div>
            <div className="slider-switch">
                <label className="switch">
                    <input type="checkbox"
                        onChange={onChangeShowGrid}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
    )
}

export default GridControl
import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './InputRadioGroup.scss'

const InputRadioGroup = ({ layerKey, input, setValue, useValue }) => {
    const [initialEffect, setInitialEffect] = useState(true)
    const [radioOptions, setRadioOptions] = useState([])
    const [valuePicked, setValuePicked] = useState(null)
    const { state } = useContext(StoreContext)

    useEffect(() => {
        let stringForArray = input.stringForArray
        let rdos = stringForArray.split(',')
        setRadioOptions(rdos)

        if (!useValue) {
            useValue = rdos[0]
        }
        setValuePicked(useValue)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let controlWidth = 0
        var ele = document.getElementById(`input_radio_options_${input.inputKey}`);
        if (ele) {
            controlWidth += ele.offsetWidth
            ele = document.getElementById(`input_radio_group_named_${input.inputKey}`);
            if (ele) {
                controlWidth += ele.offsetWidth
                if (controlWidth > 320) {
                    ele = document.getElementById(`input_radio_group_${input.inputKey}`);
                    if (ele) {
                        ele.style.display = 'block'
                    }
                }
            }
        }
    }, [radioOptions])

    const onChange = evt => {
        ;
    }

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (valuePicked !== useValue) {
                setValuePicked(useValue)
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClick = evt => {
        setValuePicked(evt.target.value)
    }

    useEffect(() => {
        if (valuePicked) {
            if (initialEffect) {
                setInitialEffect(false)
                return
            }
            if (valuePicked) {

                let existingValuePicked = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
                // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
                if (existingValuePicked !== valuePicked) {
                    setValue(input.inputKey, valuePicked)
                }
            }
        }
    }, [valuePicked]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id={`input_radio_group_${input.inputKey}`} className="default-input-container input-radio-group">
            <div className="named-container">
                <div id={`input_radio_group_named_${input.inputKey}`} className="named">
                    {input.comment && input.comment.includes('weight') ? 'weight ' : input.named}
                </div>
                <div className="colon">:</div>
            </div>

            <div id={`input_radio_options_${input.inputKey}`} className="radio-options">
                {
                    radioOptions.map((rdo, index) => <div key={index}>
                        <input type="radio" id={`inputRadio_${layerKey}${input.inputKey}_${index}`} name={`inputRadio_${layerKey}${input.inputKey}_${index}`} value={rdo}
                            checked={valuePicked === rdo}
                            onChange={onChange}
                            onClick={onClick}
                        />
                        <label htmlFor={`inputRadio_${layerKey}${input.inputKey}_${index}`} >{radioOptions[index]}</label>
                    </div>)
                }
            </div>

        </div>
    )
}

export default InputRadioGroup
import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const Triangle = {

    draw: (g, settings) => {
        let derivedValues = {}

        settings.forEach(setting => {
            switch (setting.name) {
                case 'label': break
                case 'variable spacer': break
                case 'type':
                    derivedValues.type = setting.value
                    break
                case 'side length':
                    derivedValues.sideLength = Number(setting.value)
                    break
                case 'length two sides':
                    derivedValues.twoSidesLength = Number(setting.value)
                    break
                case 'length third side':
                    derivedValues.thirdSideLength = Number(setting.value)
                    break
                case 'length a':
                    derivedValues.aLength = Number(setting.value)
                    break
                case 'length c':
                    derivedValues.cLength = Number(setting.value)
                    break
                case 'angle B':
                    derivedValues.angleB = Number(setting.value)
                    break
                case 'center xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.centerXpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.centerYpos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'fill color': derivedValues.fillColor = setting.value
                    break
                case 'stroke color':
                    derivedValues.stroke = setting.value
                    break
                case 'stroke width':
                    derivedValues.strokeWidth = Number(setting.value)
                    break
                case 'rotation':
                    derivedValues.rotation = Number(setting.value)
                    break
                case 'opacity':
                    derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'spacer': break
                default: console.warn('we seem to have missed one: ', setting.name, ' with value ', setting.value, ' setting:', setting)
            }
        })

        let fillColorObj = derivedValues.fillColor
        let fillInfo = ''
        if (fillColorObj.fillType === 'solid') {
            fillInfo = fillColorObj.fillColor
        }
        else { // gradient
            let type = derivedValues.fillColor.gradientDirection === 'radial' ? 'r' : 'l'
            let pxStart = derivedValues.fillColor.xyPositionStart.split(',')[0]
            let pyStart = derivedValues.fillColor.xyPositionStart.split(',')[1]
            let pxEnd = derivedValues.fillColor.xyPositionEnd.split(',')[0]
            let pyEnd = derivedValues.fillColor.xyPositionEnd.split(',')[1]
            let color1 = derivedValues.fillColor.colorStart
            let color2 = derivedValues.fillColor.colorMid
            let color3 = derivedValues.fillColor.colorEnd
            let defg = `${type}(${pxStart}, ${pyStart}, ${pxEnd}, ${pyEnd})${color1}-${color2}-${color3}`
            let parent = g.parent()
            var cg = parent.gradient(defg);
            fillInfo = cg
        }
        derivedValues.fill = fillInfo

        if (derivedValues.type === 'equilateral') {
            Triangle.drawEquilateralTriangle(g, derivedValues)
        }
        if (derivedValues.type === 'isosceles') {
            Triangle.drawIsoscelesTriangle(g, derivedValues)
        }
        if (derivedValues.type === 'scalene') {
            Triangle.drawScaleneTriangle(g, derivedValues)
        }

        if (derivedValues.opacity < 1) {
            g.attr({ "opacity": derivedValues.opacity })
        }

        if (derivedValues.rotation > 0) {
            Triangle.applyRotation(g, derivedValues.rotation)
        }

        if (derivedValues.centerXpos !== 0 || derivedValues.centerYpos !== 0) {
            Triangle.applyTranslation(g, derivedValues.centerXpos, derivedValues.centerYpos)
        }

        if (derivedValues.type === 'equilateral') {
            return ['length two sides for isosceles for Triangle',
                'length third side for isosceles for Triangle',
                'length side a for scalene Triangle',
                'length side c for scalene Triangle',
                'angle B for scalene Triangle degrees']
        }
        if (derivedValues.type === 'isosceles') {
            return ['length side equilateral for Triangle',
                'length side a for scalene Triangle',
                'length side c for scalene Triangle',
                'angle B for scalene Triangle degrees']
        }
        if (derivedValues.type === 'scalene') {
            return ['length two sides for isosceles for Triangle',
                'length third side for isosceles for Triangle',
                'length side equilateral for Triangle']
        }
    },

    drawEquilateralTriangle: (g, derivedValues) => {
        let sideLength = derivedValues.sideLength
        let r = sideLength / Math.sqrt(3)

        // need this here so the matrix, when doing translation, does go "off center."
        // making the circle stroke and fill "none" seems to stop click and drag. Somehow.
        g.circle(0, 0, r).attr({ stroke: "none", strokeWidth: 1, fill: "none" })

        let angle = 30
        let pointA_x = Math.cos(Utility.degreesToRadians(angle)) * r
        let pointA_y = Math.sin(Utility.degreesToRadians(angle)) * r

        angle += 360 / 3
        let pointB_x = Math.cos(Utility.degreesToRadians(angle)) * r
        let pointB_y = Math.sin(Utility.degreesToRadians(angle)) * r

        angle += 360 / 3
        let pointC_x = Math.cos(Utility.degreesToRadians(angle)) * r
        let pointC_y = Math.sin(Utility.degreesToRadians(angle)) * r

        pointA_x = Utility.roundFloat(pointA_x,2)
        pointA_y = Utility.roundFloat(pointA_y,2)

        pointB_x = Utility.roundFloat(pointB_x,2)
        pointB_y = Utility.roundFloat(pointB_y,2)

        pointC_x = Utility.roundFloat(pointC_x,2)
        pointC_y = Utility.roundFloat(pointC_y,2)

        let d = `M ${pointA_x} ${pointA_y}
        ${pointB_x} ${pointB_y}
        ${pointC_x} ${pointC_y} z`
        g.path(d).attr(
            {
                stroke: derivedValues.stroke,
                strokeWidth: derivedValues.strokeWidth,
                fill: derivedValues.fill
            })
    },

    drawIsoscelesTriangle: (g, derivedValues) => {
        let twoSidesLength = derivedValues.twoSidesLength // a b
        let thirdSideLength = derivedValues.thirdSideLength // c

        let a = thirdSideLength
        let b = twoSidesLength
        let c = twoSidesLength

        let cosa = (Math.pow(b, 2) + Math.pow(c, 2) - Math.pow(a, 2)) / (2 * b * c)
        if (cosa < -1) {
            // Math.acos only operates in range of -1,1
            cosa = -1
        }
        let acosa = Math.acos(cosa)
        let degrees = Utility.radiansToDegrees(acosa)
        let remainingAngles = (180 - degrees) / 2

        let x1 = 0
        let y1 = 0
        let x3 = x1 + (b * Math.cos(Utility.degreesToRadians(remainingAngles)))
        let y3 = y1 + (b * Math.sin(Utility.degreesToRadians(remainingAngles)))
        y3 *= -1 // have it point upwards rather than down.

        let x2 = a
        let y2 = 0
        x1 -= a / 2
        x2 -= a / 2
        x3 -= a / 2
        let h = y3
        y1 -= h / 2
        y2 -= h / 2
        y3 -= h / 2
        x1 = Utility.roundFloat(x1,2)
        y1 = Utility.roundFloat(y1,2)
        x2 = Utility.roundFloat(x2,2)
        y2 = Utility.roundFloat(y2,2)
        x3 = Utility.roundFloat(x3,2)
        y3 = Utility.roundFloat(y3,2)
        let d = `M ${x1} ${y1} L
                   ${x2} ${y2}
                   ${x3} ${y3} z`

        g.path(d).attr(
            {
                stroke: derivedValues.stroke,
                strokeWidth: derivedValues.strokeWidth,
                fill: derivedValues.fill
            })
    },

    drawScaleneTriangle: (g, derivedValues) => {
        let a = derivedValues.aLength
        let c = derivedValues.cLength
        let angleB = derivedValues.angleB

        let x1 = 0
        let y1 = 0
        let x2 = a
        let y2 = 0
        let x3 = (c * Math.cos(Utility.degreesToRadians(angleB)))
        let y3 = -1 * (c * Math.sin(Utility.degreesToRadians(angleB)))

        // center formula ((x1+x2+x3)/3, (y1+y2+y3)/3)
        let computedXcenter = (x1 + x2 + x3) / 3
        let computedYcenter = (y1 + y2 + y3) / 3
        let shiftX = computedXcenter
        let shiftY = computedYcenter
        x1 -= shiftX
        y1 -= shiftY
        x2 -= shiftX
        y2 -= shiftY
        x3 -= shiftX
        y3 -= shiftY
        x1 = Utility.roundFloat(x1,2)
        y1 = Utility.roundFloat(y1,2)
        x2 = Utility.roundFloat(x2,2)
        y2 = Utility.roundFloat(y2,2)
        x3 = Utility.roundFloat(x3,2)
        y3 = Utility.roundFloat(y3,2)

        let d = `M ${x1} ${y1} L
                   ${x2} ${y2}
                   ${x3} ${y3} z`
        g.rect(-100, -100, 200, 200).attr({ stroke: "none", fill: "none" })
        g.path(d).attr(
            {
                stroke: derivedValues.stroke,
                strokeWidth: derivedValues.strokeWidth,
                fill: derivedValues.fill
            })
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
}

export default Triangle
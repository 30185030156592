import React, { useState, useEffect, useContext } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility";
import './LayerRenameForm.scss'
const LayerRenameForm = ({ layer, closeMessage, closeOptions }) => {
    const { state, actions } = useContext(StoreContext)
    const signalExistingLayerNames = useSignal([])
    const [renameName, setRenameName] = useState('')
    const [layerRenamed, setLayerRenamed] = useState(false)

    useEffect(() => {
        signalExistingLayerNames.value = state.layers.map(function (ly) { return ly.layerName });
        setRenameName(layer.layerName)
    }, [])

    const textChange = evt => {
        setRenameName(evt.target.value)
    }

    const onRenameLayerSubmit = evt => {
        evt.preventDefault()
        evt.stopPropagation();
        if (renameName.trim().length > 1 && !signalExistingLayerNames.value.includes(renameName.trim().value)) {
            // rename the layer
            renameLayer()
        }
    }

    const onCancelRenameLayer = evt => {
        evt.preventDefault()
        evt.stopPropagation();
        closeMessage()
    }

    const renameLayer = () => {
        let layers = [...state.layers]
        for(let i=0; i<layers.length; i++) {
            if( layers[i].layerKey === layer.layerKey ) {
                layers[i].layerName = renameName.trim()
            }
        }
        setLayerRenamed(true)
        actions.layers(layers)
    }

    useEffect(() => {
        if (layerRenamed) {
            closeMessage()
            closeOptions()
        }

    }, [state.layers])

    return (
        <div className="layer-rename-form">
            <form id={`form_rename_${layer.layerKey}`} onSubmit={onRenameLayerSubmit}>
                <div>Name must be unique and greater than 1 character</div>
                <div>renamed layer name: <input className={signalExistingLayerNames.value.includes(renameName.trim().value) ? 'warning' : ''}
                    type="text" onChange={textChange} value={renameName} /></div>
                <div>

                    <button type="submit"
                        className={renameName.length <= 1 || signalExistingLayerNames.value.includes(renameName) ? 'standard-button disabled' : 'standard-button blue'}
                        disabled={renameName.length <= 1 || signalExistingLayerNames.value.includes(renameName)}
                    ><div>submit</div></button>
                    <button className="standard-button yellow" onClick={onCancelRenameLayer} ><div>cancel</div></button>

                </div>
            </form>
        </div>
    )




}
export default LayerRenameForm
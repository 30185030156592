import React, { useState, useEffect, useRef, useContext } from 'react'
import stringComparison from 'string-comparison'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import JSZip from 'jszip'
import Utility from "../../objects/Utility"
import CloseX from "../CloseX/CloseX"
import './LoadCounterControl.scss'
const LoadCounterControl = ({ loadCounter, longName }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state, actions } = useContext(StoreContext)
    const [savedCounters, setSavedCounters] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const loadCounterDialogRef = useRef(null)
    const [paper, setPaper] = useState(null)
    const [loadErrorLineItems, setLoadErrorLineItems] = useState([])

    useEffect(() => {
        let _paper = Snap('#extractorSvg')
        setPaper(_paper)

        loadCounterDialogRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        }, { signal });

        return () => {
            controller.abort();
        };
    }, [])

    useEffect(() => {
        if (state.overlayClick) {
            actions.counterAreaMenuState('')
            setDialogOpen(false)
        }
    }, [state.overlayClick])

    const closeSelf = () => {
        actions.counterAreaMenuState('')
        setDialogOpen(false)
    }

    useEffect(() => {
        createSavedCountersList()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const createSavedCountersList = () => {
        if (Utility.emptyCheck(state.savedCounters) === false) {
            let values = Object.values(state.savedCounters)
            setSavedCounters(values) // for local use
        }
    }

    const close = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        createSavedCountersList()
    }, [state.savedCounters])

    const toggleDialog = () => {
        setDialogOpen(!dialogOpen)
    }

    useEffect(() => {
        if (dialogOpen) {
            actions.counterAreaMenuState('load counter')
            actions.overlay(true)
        }
        else {
            if (state.counterAreaMenuState === 'load counter') {
                actions.counterAreaMenuState('')
                close()
            }
        }
    }, [dialogOpen])

    useEffect(() => {
        if (state.counterAreaMenuState !== 'load counter' && dialogOpen) {
            toggleDialog()
        }
        if (state.counterAreaMenuState === '') {
            actions.overlay(false)
        }
    }, [state.counterAreaMenuState])

    const deactivateLayers = () => {
        let layers = [...state.layers]
        layers.forEach(ly => ly.layerKey > 1 ? ly.layerActive = 0 : null)
    }

    const deactivateActiveLayerValues = () => {
        let newAlv = {}
        for (const [key, value] of Object.entries(state.activeLayerValues)) {
            if (key.startsWith('1_')) {
                newAlv[key] = value
            }
        }
        if (!newAlv || Utility.emptyCheck(newAlv)) {
            console.warn('attempted to reset activeLayerValues with no data,', newAlv)
        }
        else {
            if (JSON.stringify(newAlv) !== JSON.stringify(state.activeLayerValues)) {
                actions.activeLayerValuesReset(newAlv)
            }
        }
    }

    const insertMissingSvg = svgObj => {
        if (svgObj) {
            actions.svgsAdd(svgObj)

            let layers = [...state.layers]
            let parentCustomSvgKey = layers.find(sly => sly.layerName === 'custom svgs' ? sly.layerKey : null).layerKey
            let customSvgLayers = layers.filter(sly => sly.layerKey === parentCustomSvgKey || sly.parentLayerKey === parentCustomSvgKey)
            customSvgLayers.forEach(cvl => {
                // add svgKey to layer inputs
                let input = cvl.inputs.find(li => li.named === 'svgKey')
                if (input) {
                    if (input.list.includes(svgObj.svgKey) === false) {
                        input.list.push(svgObj.svgKey)
                        // we need to change ref of inputs so that 
                        // layerMenuItem knows it was updated.
                        cvl.inputs = JSON.parse(JSON.stringify(cvl.inputs))
                    }
                }
            })
            actions.layers(layers)
        }
    }

    const addDuplicateLayer = (stateLayers, dupeLayer) => {
        let updatedStateLayers = [...stateLayers]
        let duplicateLayer = { ...dupeLayer }
        let parentLayer = updatedStateLayers.find(sl => sl.layerKey === duplicateLayer.parentLayerKey)
        if (parentLayer) {
            duplicateLayer.layerOrder = parentLayer.layerOrder + 0.5
            let maxLayerKey = Math.max(...updatedStateLayers.map(ly => parseInt(ly.layerKey)), 0)
            duplicateLayer.layerKey = maxLayerKey + 1
            duplicateLayer.layerHidden = 0
            duplicateLayer.layerActive = 1
            // fix up orderings, since we're jamming in a new layer inbetween two (unless its at the end)
            updatedStateLayers.push(duplicateLayer)
            updatedStateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            updatedStateLayers.forEach((ly, index) => {
                ly.layerOrder = index
            })
        }
        return { updatedStateLayers: updatedStateLayers, newLayerKey: duplicateLayer.layerKey }
    }

    const generateNoneConflictingLayerName = (baseName, existingLayerNames) => {
        let testName = baseName
        let i = 2
        if (existingLayerNames.includes(testName)) {
            for (i; i < 100; i++) {
                if (!existingLayerNames.includes(testName + i)) {
                    return testName + 1
                }
            }
        }
        if (i > 100) {
            return testName + Utility.randomString(5)
        }
        return testName
    }

    const setupCounter = counterState => {
        let sideActive = 'frontPackage'
        let activeLayerValues = JSON.parse(counterState.counterSideActive['front'])
        if (!counterState[sideActive].customImageSvgsData) {  // if from sheet the svgs are protected from deletion in the app.
            counterState[sideActive].customImageSvgsData = []
        }
        if (!counterState[sideActive].customSvgsData) {  // if from sheet the svgs are protected from deletion in the app.
            counterState[sideActive].customSvgsData = []
        }
        if (!counterState[sideActive].fontsData) {  // if from sheet the svgs are protected from deletion in the app.
            counterState[sideActive].fontsData = []
        }

        let incomingSvgs = []

        // add string field to retain number data that seems to get screwed up sometimes after unzip.
        if (counterState[sideActive].customImageSvgsData.length > 0 ||
            counterState[sideActive].customSvgsData.length > 0) {
            counterState[sideActive].customImageSvgsData.forEach(isvg => {
                isvg.combined = isvg.svgKey + ':' + isvg.svgName + ':' + 'svg'
                isvg.type = 'image'
                incomingSvgs.push(isvg)
            })
            counterState[sideActive].customSvgsData.forEach(isvg => {
                isvg.combined = isvg.svgKey + ':' + isvg.svgName + ':' + 'svg'
                isvg.type = 'svg'
                incomingSvgs.push(isvg)
            })
        }

        // create any dupe layers that dont exist in state but do in the incoming data
        let updatedStateLayers = [...state.layers]

        //  find if theres any svgs that need to be added, and if any svgKey changes need to be made.
        let changeIncomingSvgKeysFromTo = []
        if (incomingSvgs.length > 0) {
            changeIncomingSvgKeysFromTo = installAnyNeededSvgs(incomingSvgs)
        }
        // update incoming activeLayerValues to reflect any svgKey changes
        counterState[sideActive].layers.forEach(ly => {
            if (ly.layerActive) {
                if (Utility.isCustomLayer(ly.layerKey, state.layers) || Utility.isCustomLayer(ly.parentLayerKey, state.layers)) {
                    let svgInputKey = ly.layerActiveRequiredInputKey
                    let currentSvgKey = activeLayerValues[ly.layerKey + '_' + svgInputKey]
                    let foundChange = changeIncomingSvgKeysFromTo.find(ch => ch.fromSvgKey === currentSvgKey)
                    if (foundChange) {
                        activeLayerValues[ly.layerKey + '_' + svgInputKey] = foundChange.toSvgKey
                        // apply same change to the rear activeLayerValues, if the key exists.
                        if (counterState.counterSideActive) {
                            if (counterState.counterSideActive.rear) {
                                let rearAlv = JSON.parse(counterState.counterSideActive.rear)
                                if (rearAlv[ly.layerKey + '_' + svgInputKey]) {
                                    rearAlv[ly.layerKey + '_' + svgInputKey] = foundChange.toSvgKey
                                    counterState.counterSideActive.rear = JSON.stringify(rearAlv)
                                }
                            }
                        }
                    }
                    else {
                        // change not found. But it may still not be pointing at the right one
                        // since a previous load counter may had already installed the svg
                        // so - it won't be installed and so the activeLayerValues wont get updated.
                    }
                }
            }
        })


        if (changeIncomingSvgKeysFromTo.length > 0) {
            updatedStateLayers = updateStateLayersListWithNewSvgKeys(changeIncomingSvgKeysFromTo)
        }

        let { updatedIncomingLayers, newStateLayers, layerKeyChanges } = createNewStateLayersWithDupes(counterState['frontPackage'].layers, updatedStateLayers)
        // bring in any order changes for the layers
        counterState['frontPackage'].layers.forEach(layer => {
            let found = newStateLayers.find(slr => slr.layerKey === layer.layerKey)
            if (found) {
                found.layerOrder = layer.layerOrder
            }
        })

        let newActiveLayerValues = { activeLayerValues }
        if (layerKeyChanges.length > 0) {
            newActiveLayerValues = updateActiveLayerValues(layerKeyChanges, newActiveLayerValues)
        }
        // activate all state layers that exists in the incoming layers.
        let activeLayerKeys = Utility.activeLayerKeys(newActiveLayerValues)
        newStateLayers.forEach(newLayer => {
            if (activeLayerKeys.includes(newLayer.layerKey)) {
                newLayer.layerActive = 1
                newLayer.layerHidden = 0
            }
        })
        newStateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
        newStateLayers.forEach((ly, index) => {
            ly.layerOrder = index
        })
        newStateLayers.forEach(ly => {
            if (ly.layerActive) {
                if (Utility.isCustomLayer(ly.layerKey, state.layers)) {
                    let svgInputKey = ly.layerActiveRequiredInputKey
                    if (svgInputKey) {
                        let expectingSvgKey = newActiveLayerValues[ly.layerKey + '_' + svgInputKey]
                        let doesSvgExist = state.svgs.find(ss => ss.svgKey === expectingSvgKey)
                        if (!doesSvgExist) {// see if it was newly added
                            doesSvgExist = changeIncomingSvgKeysFromTo.find(cik => cik.toSvgKey === expectingSvgKey)
                        }
                        if (!doesSvgExist) {
                            //newStateLayers = newStateLayers.filter( nsl => nsl.layerKey !== ly.layerKey )
                            newStateLayers.forEach(nsl => {
                                if (nsl.layerKey === ly.layerKey) {
                                    nsl.layerActive = 0
                                    let input = nsl.inputs.find(li => li.named === 'svgKey')
                                    if (input) {
                                        if (input.list && Array.isArray(input.list) && input.list.length === 0) {
                                            nsl.layerHidden = 1
                                        }
                                    }
                                }
                            })
                            newActiveLayerValues = Utility.removeLayerFromActiveLayerValues(ly.layerKey, newActiveLayerValues)
                        }
                    }
                }
            }
        })
        actions.layers(newStateLayers)
        if (JSON.stringify(newActiveLayerValues) !== JSON.stringify(state.activeLayerValues)) {
            actions.activeLayerValuesReset(newActiveLayerValues)
        }
        closeSelf()
    }

    const findDupeLayersInfo = (stateLayers, layers) => {
        let dupeLayersInfo = []
        let mainLayersInfo = []
        stateLayers.forEach(lr => {
            if (lr.parentLayerKey === -1) {
                mainLayersInfo.push({ type: lr.layerName, key: lr.layerKey })
            }
        })
        layers.forEach(ly => {
            if (ly.parentLayerKey > -1) {
                let typeInfo = mainLayersInfo.find(mli => mli.key === ly.parentLayerKey)
                if (typeInfo) {
                    let type = typeInfo.type
                    dupeLayersInfo.push({ type, topLayerKey: ly.parentLayerKey, layer: ly })
                }
            }
        })

        return dupeLayersInfo
    }

    useEffect(() => {
        // if (state.loadCounter) {
        //     deactivateLayers()
        //     deactivateActiveLayerValues()
        //     actions.loadCounterProcessing(state.loadCounter)
        // }
    }, [state.loadCounter])

    const loadFromApp = evt => {
        let hash = evt.target.value
        if (hash) {
            hash = Number(hash)
            let counterObj = savedCounters.find(sc => sc.hash === hash)
            if (counterObj) {
                let counterState = handleCounterApp(counterObj)
                actions.counterLoadFromApp(counterState)
            }
        }
    }

    useEffect(() => {
        if (state.counterLoadFromApp) {
            setDialogOpen(false)
        }
    }, [state.counterLoadFromApp])

    useEffect(() => {
        if (state.counterLoadFromFile) {
            setDialogOpen(false)
        }
    }, [state.counterLoadFromFile])

    useEffect(() => {
        if (state.loadCounterProcessing) {
            setupCounter(state.loadCounterProcessing)
            actions.loadCounterProcessing(null)
        }
    }, [state.loadCounterProcessing])

    // when loading from file, we may have duplicated layers, fonts, and custom images and svgs that aren't present in the
    // current environment.
    const loadFromFile = evt => {
        if (evt.target.files && evt.target.files.length > 0) {
            let file = evt.target.files[0]
            if (file) {
                JSZip.loadAsync(file).then((zip) => {
                    Object.keys(zip.files).forEach((filename) => {
                        zip.files[filename].async('string').then(async (fileData) => {
                            let data = null
                            try {
                                data = JSON.parse(fileData)
                            }
                            catch (e) {
                                console.warn('error loading file')
                                return
                            }
                            if (!data) {
                                actions.addErrorMessages([{
                                    comment: "File load problem:",
                                    lineItems: ['Not able to extract data']
                                }])
                                return
                            }
                            if (data.slots) {
                                actions.addErrorMessages([{
                                    comment: "File load problem:",
                                    lineItems: ['This file is a sheet file, not a counter file.']
                                }])
                                return
                            }
                            else {
                                if (!data.app || !data.counterState || !data.type) {
                                    actions.addErrorMessages([{
                                        comment: "File load problem:",
                                        lineItems: ['This file does not seem to be a counter file.']
                                    }])
                                    return
                                }
                                else {
                                    if (data.type !== 'counter') {
                                        actions.addErrorMessages([{
                                            comment: "File load problem:",
                                            lineItems: ['This file is not a counter file.']
                                        }])
                                        return
                                    }
                                }
                            }

                            if (!data.version) {
                                // old version won't have svg data, nor the svgKeys.
                                // I have to dig through the activeLayerValues to see what svgKeys are being referenced.
                                // These svg keys probably wont be the same in the new app, unless by some luck - like in case the
                                // user only used a few svgs, in the old version, and installed the same svgs in the new version.
                                // But, its probably not going to work. I guess if the incoming svgKey matches and existing svgKey
                                // i'll just leave it. Otherwise, remove the ref to the svg from the incoming data.
                                let counterState = handleOldVersionCounterFile(data.counterState)
                                counterState.version = 2
                                actions.counterLoadFromFile(counterState)
                            }
                            if (data.version === 3) {
                                data.counterState.version = 3
                                let counterState = handleNewVersionCounterFile(data.counterState)
                                counterState.version = 3
                                actions.counterLoadFromFile(counterState)
                            }
                        })
                    })
                    document.getElementById("counterFile").value = "";
                })
            }
        }
    }

    const createDuplicateLayer = (layers, dupeThisLayer) => {
        let newLayer = null
        let updatedStateLayers = JSON.parse(JSON.stringify(layers))
        let parentLayer = updatedStateLayers.find(sl => sl.layerKey === dupeThisLayer.parentLayerKey)
        if (parentLayer) {
            newLayer = { ...parentLayer }
            newLayer.parentLayerKey = newLayer.layerKey
            newLayer.layerOrder = newLayer.layerOrder + 0.5
            let maxLayerKey = Math.max(...updatedStateLayers.map(ly => parseInt(ly.layerKey)), 0)
            newLayer.layerKey = maxLayerKey + 1
            newLayer.layerHidden = 0
            newLayer.layerActive = 1
            // check name to avoid dupe names
            let incNum = 2
            let dupeName = dupeThisLayer.layerName
            if( !dupeName && dupeThisLayer.layer ) {
                dupeName = dupeThisLayer.layer.layerName
            }
            while (updatedStateLayers.find(nl => nl.layerName === dupeName)) {
                dupeName = newLayer.layerName + incNum
                incNum++
                if (incNum > 100) {
                    break
                }
            }
            newLayer.layerName = dupeName
            // fix up orderings, since we're jamming in a new layer inbetween two (unless its at the end)
            updatedStateLayers.push(newLayer)
            updatedStateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            updatedStateLayers.forEach((ly, index) => {
                ly.layerOrder = index
            })

        }
        return { updatedStateLayers: updatedStateLayers, newLayerKey: newLayer.layerKey }
    }

    const processAlvReplacements = (fromTos, alv) => {
        let newAlv = JSON.parse(JSON.stringify(alv))
        fromTos.forEach(fromTo => {
            let from = fromTo.from
            let to = fromTo.to
            for (const [key, value] of Object.entries(alv)) {
                if (key.startsWith(from + '_')) {
                    let inputKey = key.split('_')[1]
                    newAlv[to + 'new_' + inputKey] = value
                    delete newAlv[key]
                }
            }
        })
        for (const [key, value] of Object.entries(newAlv)) {
            if (key.includes('new')) {
                let fixedKey = key.replace('new', '')
                newAlv[fixedKey] = value
                delete newAlv[key]
            }
        }

        return newAlv
    }

    const processSvgKeyAlvReplacements = (fromTos, alv, layers) => {
        let newAlv = JSON.parse(JSON.stringify(alv))

        // get custom image and svg layers to get the required keys
        let customImageSvgLayers = Utility.allLayersOfType(layers, 'custom images')
        let customSvgLayers = Utility.allLayersOfType(layers, 'custom svgs')
        let allCustomSvgLayers = [...customImageSvgLayers, ...customSvgLayers]
        allCustomSvgLayers.forEach(cly => {
            let requiredKey = cly.layerActiveRequiredInputKey
            fromTos.forEach(fromTo => {
                let from = fromTo.from
                let to = fromTo.to
                if (newAlv[cly.layerKey + '_' + requiredKey] === from) {
                    newAlv[cly.layerKey + 'NEW_' + requiredKey] = to
                }
            })
        })

        for( const[key,val] of Object.entries(newAlv) ) {
            if( key.includes('NEW') ) {
                newAlv[ key.replace('NEW','')] = val
                delete newAlv[key]
            }
        }

        return newAlv
    }

    const handleNewVersionCounterFile = data => {
        let fontsData = []
        if (data) {
            //////////////////////////////////////////////////////////
            // check fontsData
            //////////////////////////////////////////////////////////
            if (data.fontsData && data.fontsData.length > 0) {
                let addFonts = []
                data.fontsData.forEach(fd => {
                    if (!state.fonts.find(sf => sf.fontFamily === fd.fontFamily)) {
                        fontsData.push(fd)
                    }
                })
            }

            let stateLayersCopy = [...state.layers]
            //////////////////////////////////////////////////////////
            // duplicate layers
            //////////////////////////////////////////////////////////

            let stateLayersByType = []
            let incomingLayersByType = []
            stateLayersCopy.forEach(sl => {
                let layerType = ''
                if (sl.parentLayerKey === -1) {
                    layerType = sl.layerName
                }
                else {
                    layerType = Utility.originalLayerName(stateLayersCopy, sl)
                }
                stateLayersByType.push({ layerType, layerKey: sl.layerKey, parentLayerKey: sl.parentLayerKey })
            })
            stateLayersByType = stateLayersByType.sort((a, b) => a.layerKey - b.layerKey)

            data.layers.forEach(sl => {
                let layerType = ''
                if (sl.parentLayerKey === -1) {
                    layerType = sl.layerName
                }
                else {
                    layerType = Utility.originalLayerName(stateLayersCopy, sl)
                }
                incomingLayersByType.push({ layerType, layerKey: sl.layerKey, parentLayerKey: sl.parentLayerKey, layerName: sl.layerName, layer: sl })
            })

            let removeMatched = []
            incomingLayersByType.forEach(ilbt => {
                if (stateLayersByType.find(slbt => slbt.layerKey === ilbt.layerKey && slbt.parentLayerKey === ilbt.parentLayerKey)) {
                    removeMatched.push(ilbt.layerKey)
                }
            })
            // state layers that are not assigned yet
            stateLayersByType = stateLayersByType.filter(slbt => removeMatched.includes(slbt.layerKey) === false)
            // incoming layers that havent been matched up yet
            incomingLayersByType = incomingLayersByType.filter(ilbt => removeMatched.includes(ilbt.layerKey) === false)
            // now if we have anything left in incomingLayersByType, see if they match up with whats left in state.
            let changeIncomingLayerKeysFromTo = []
            incomingLayersByType.forEach(ilbt => {
                let foundPlace = stateLayersByType.find(slbt => slbt.layerType === ilbt.layerType)
                if (foundPlace) {
                    changeIncomingLayerKeysFromTo.push({ from: ilbt.layerKey, to: foundPlace.layerKey })
                    stateLayersByType = stateLayersByType.filter(slbt => slbt.layerKey !== foundPlace.layerKey)
                }
            })
            changeIncomingLayerKeysFromTo.forEach(fromTo => {
                incomingLayersByType = incomingLayersByType.filter(ilbt => ilbt.layerKey !== fromTo.from)
            })
            // make any needed dupe(s)
            let newLayers = [...state.layers]
            incomingLayersByType.forEach(ly => {
                let dupeLayer = createDuplicateLayer(newLayers, ly)
                if (ly.layerKey !== dupeLayer.newLayerKey) {
                    changeIncomingLayerKeysFromTo.push({ from: ly.layerKey, to: dupeLayer.newLayerKey })
                }
                newLayers = dupeLayer.updatedStateLayers
            })
            if (changeIncomingLayerKeysFromTo.length > 0) {
                let frontActiveLayerValues = JSON.parse(data.counterSideActive.front)
                frontActiveLayerValues = processAlvReplacements(changeIncomingLayerKeysFromTo, frontActiveLayerValues)
                data.counterSideActive.front = JSON.stringify(frontActiveLayerValues)
                let rearActiveLayerValues = JSON.parse(data.counterSideActive.rear)
                rearActiveLayerValues = processAlvReplacements(changeIncomingLayerKeysFromTo, rearActiveLayerValues)
                data.counterSideActive.rear = JSON.stringify(rearActiveLayerValues)
            }

            ///////////////////////////////////////////////////stateNotCustomLayers
            // incoming custom images/svgs
            ///////////////////////////////////////////////////
            let incomingImageSvgsData = data.customImageSvgsData
            let incomingSvgSvgsData = data.customSvgsData
            let changeCustomSvgKeysFromTo = []
            let installSvgs = []
            let stateSvgsToSearch = state.svgs.filter(ss => ss.svgKey > 2267)

            let customImagesLayer = newLayers.find(ly => ly.layerName === 'custom images')
            let customImagesSvgInput = customImagesLayer.inputs.find(li => li.named === 'svgKey')
            let imagesList = customImagesSvgInput.list

            let customSvgsLayer = state.layers.find(sl => sl.layerName === 'custom svgs')
            let customSvgsInput = customSvgsLayer.inputs.find(li => li.named === 'svgKey')
            let svgsList = customSvgsInput.list

            stateSvgsToSearch.forEach(svg => {
                if (svg.uniquePrepend && svg.uniquePrepend.length > 0) {
                    svg.svgCodeStripped = svg.svgCode.replaceAll(svg.uniquePrepend, '')
                }
                else {
                    svg.svgCodeStripped = svg.svgCode
                }

                if (imagesList.includes(svg.svgKey)) {
                    svg.svgType = 'image'
                }
                if (svgsList.includes(svg.svgKey)) {
                    svg.svgType = 'svg'
                }
            })
            incomingImageSvgsData.forEach(svg => {
                svg.svgType = 'image'
            })
            incomingSvgSvgsData.forEach(svg => {
                svg.svgType = 'svg'
            })
            let incomingSvgsData = [...incomingImageSvgsData, ...incomingSvgSvgsData]
            incomingSvgsData.forEach(svg => {
                if (svg.uniquePrepend && svg.uniquePrepend.length > 0) {
                    svg.svgCodeStripped = svg.svgCode.replaceAll(svg.uniquePrepend, '')
                }
                else {
                    svg.svgCodeStripped = svg.svgCode
                }

                if (imagesList.includes(svg.svgKey)) {
                    svg.svgType = 'image'
                }
                if (svgsList.includes(svg.svgKey)) {
                    svg.svgType = 'svg'
                }
            })

            incomingSvgsData.forEach(iisvg => {
                if (!stateSvgsToSearch.find(ss => ss.svgKey === iisvg.svgKey && ss.svgCodeStripped === iisvg.svgCodeStripped && ss.svgType === iisvg.svgType)) {
                    let found = stateSvgsToSearch.find(ss => ss.svgCodeStripped === iisvg.svgCodeStripped && ss.svgType === iisvg.svgType)
                    if (found) {
                        changeCustomSvgKeysFromTo.push({ from: iisvg.svgKey, to: found.svgKey, type: iisvg.svgType })
                    }
                    else {
                        stateSvgsToSearch.forEach(ssvg => {
                            let rawQ = stringComparison.cosine.similarity(ssvg.svgCodeStripped, iisvg.svgCodeStripped)
                            let matchQuotient = Utility.roundFloat(rawQ,5)
                            if (matchQuotient === 1 && ssvg.svgType === iisvg.svgType) { // if the svgCodes almosvgKeyChangesst perfectly match, consider it a match.
                                found = ssvg
                                changeCustomSvgKeysFromTo.push({ from: iisvg.svgKey, to: found.svgKey, type: iisvg.svgType })
                            }
                        })
                        if (!found) {
                            installSvgs.push({ svg: iisvg, type: iisvg.svgType })
                        }
                    }
                }
            })

            let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
            let newSvgs = []
            installSvgs.forEach(installSvg => {
                let svg = installSvg.svg
                let type = installSvg.type
                // get next svgKey
                if (useKey < 3000) {
                    useKey = 3000
                }
                else {
                    useKey++
                }
                let fromKey = svg.svgKey
                let toKey = useKey
                svg.svgKey = toKey
                newSvgs.push(svg)
                changeCustomSvgKeysFromTo.push({ from: fromKey, to: toKey, type })
            })

            let newPointersForLayerKeys = []
            if (changeCustomSvgKeysFromTo.length > 0) {
                let frontActiveLayerValues = JSON.parse(data.counterSideActive.front)
                frontActiveLayerValues = processSvgKeyAlvReplacements(changeCustomSvgKeysFromTo, frontActiveLayerValues, newLayers)
                data.counterSideActive.front = JSON.stringify(frontActiveLayerValues)

                let rearActiveLayerValues = JSON.parse(data.counterSideActive.rear)
                rearActiveLayerValues = processSvgKeyAlvReplacements(changeCustomSvgKeysFromTo, rearActiveLayerValues, newLayers)
                data.counterSideActive.rear = JSON.stringify(rearActiveLayerValues)

                // update layer svg input lists
                let customImagesLayers = []
                let customSvgsLayers = []
                newLayers.forEach(ly => {
                    let layerType = Utility.originalLayerName(newLayers, ly)
                    if (layerType === 'custom images') {
                        customImagesLayers.push(ly)
                    }
                    if (layerType === 'custom svgs') {
                        customSvgsLayers.push(ly)
                    }
                })

                let customImagesLayer = newLayers.find(ly => ly.layerName === 'custom images')
                let customImagesSvgInput = customImagesLayer.inputs.find(li => li.named === 'svgKey')
                let imagesList = customImagesSvgInput.list

                let customSvgsLayer = newLayers.find(ly => ly.layerName === 'custom svgs')
                let customSvgsSvgInput = customSvgsLayer.inputs.find(li => li.named === 'svgKey')
                let svgsList = customSvgsSvgInput.list

                changeCustomSvgKeysFromTo.forEach(fromTo => {
                    let to = fromTo.to
                    let type = fromTo.type
                    if (type === 'image') {
                        if (imagesList.includes(to) === false) {
                            imagesList.push(to)
                        }
                    }
                    if (type === 'svg') {
                        if (svgsList.includes(to) === false) {
                            svgsList.push(to)
                        }
                    }
                })

                customImagesLayers.forEach(cil => {
                    let cilSvgInput = cil.inputs.find(li => li.named === 'svgKey')
                    cilSvgInput.list = [...imagesList]
                    if (newPointersForLayerKeys.includes(cil.layerKey) === false) {
                        newPointersForLayerKeys.push(cil.layerKey)
                    }
                })
                customSvgsLayers.forEach(csl => {
                    let cslSvgInput = csl.inputs.find(li => li.named === 'svgKey')
                    cslSvgInput.list = [...svgsList]
                    if (newPointersForLayerKeys.includes(csl.layerKey) === false) {
                        newPointersForLayerKeys.push(csl.layerKey)
                    }
                })
            }

            for (let i = 0; i < newLayers.length; i++) {
                if (newPointersForLayerKeys.includes(newLayers[i].layerKey)) {
                    newLayers[i] = JSON.parse(JSON.stringify(newLayers[i]))
                }
            }

            let alv = JSON.parse(data.counterSideActive.front)
            let layerKeysActive = Utility.layerKeysActive(alv)
            newLayers.forEach(iLayer => {
                if (layerKeysActive.includes(iLayer.layerKey)) {
                    iLayer.layerActive = 1
                    iLayer.layerHidden = 0
                }
            })
            alv = JSON.parse(data.counterSideActive.rear)
            layerKeysActive = Utility.layerKeysActive(alv)
            newLayers.forEach(iLayer => {
                if (layerKeysActive.includes(iLayer.layerKey)) {
                    iLayer.layerHidden = 0
                }
            })
            state.fonts.forEach( ft => {
               if( ! fontsData.find( fd => fd.fontFamily === ft.fontFamily ) ) {
                   fontsData.push(ft)
               }
            })
            let resultData = {}
            resultData.fonts = fontsData
            resultData.layers = newLayers
            resultData.svgs = newSvgs
            resultData.counterSideActive = data.counterSideActive

            return resultData
        }
    }

    const handleCounterApp = data => {
        let newLayers = [...state.layers]
        data.layers.forEach( ily => {
            if( newLayers.find( ly => ly.layerKey === ily.layerKey ) === false ) {
                newLayers.push(ily)
            } 
        })
        let resultData = {}
        resultData.fonts = [state.fonts]
        resultData.layers = newLayers
        resultData.svgs = []
        resultData.counterSideActive = data.counterSideActive

        return resultData
    }

    const handleOldVersionCounterFile = _counterState => {
        let incomingActiveLayerValues = _counterState.activeLayerValues
        let incomingLayers = _counterState.layers

        // remove spacers from activeLayerValues
        for (const [key] of Object.entries(incomingActiveLayerValues)) {
            if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                delete incomingActiveLayerValues[key]
            }
        }

        // handle dupe layers
        let stateLayersByType = []
        state.layers.forEach(layer => {
            let originalName = Utility.originalLayerName2(state.layers, layer)
            let collection = stateLayersByType.find(slbt => slbt.originalName === originalName)
            if (!collection) {
                stateLayersByType.push({ originalName, layers: [layer], matched: 0 })
            }
            else {
                collection.layers.push(layer)
            }
        })

        const findTypeMatch = layerType => {
            let matchResult = null
            let foundEntry = stateLayersByType.find(slt => slt.originalName === layerType)
            if (foundEntry) {
                if (foundEntry.layers.length > 0) {
                    let foundLayer = foundEntry.layers[0]
                    foundEntry.matched++
                    matchResult = { ...foundLayer }
                    foundEntry.layers.shift()
                }
                else {
                    matchResult = null
                }
            }
            else {
                console.error('what happened, didnt find:', layerType)
            }

            return matchResult
        }

        let layerKeyChanges = []
        let layerDupesNeeded = []
        incomingLayers.forEach(ily => {
            if (ily.layerActive === 1) {
                let incomingLayerType = Utility.originalLayerName2(state.layers, ily)
                let byTypeMatch = findTypeMatch(incomingLayerType)
                if (byTypeMatch) {
                    if (ily.layerKey !== byTypeMatch.layerKey) {
                        layerKeyChanges.push({ from: ily.layerKey, to: byTypeMatch.layerKey })
                    }
                }
                else {
                    layerDupesNeeded.push(ily)
                }
            }
        })
        let stateLayers = [...state.layers]
        layerDupesNeeded.forEach(dupeThisLayer => {
            let dupeResult = createDuplicateLayer(stateLayers, dupeThisLayer)
            stateLayers = dupeResult.updatedStateLayers
            let newLayerKey = dupeResult.newLayerKey
            if (dupeThisLayer.layerKey !== newLayerKey) {
                layerKeyChanges.push({ from: dupeThisLayer.layerKey, to: newLayerKey })
            }
        })

        layerKeyChanges.forEach(fromTo => {
            let from = fromTo.from
            let to = fromTo.to
            let copyActiveLayerValues = { ...incomingActiveLayerValues }
            for (const [key, value] of Object.entries(copyActiveLayerValues)) {
                if (key.startsWith(from + '_')) {
                    let inputKey = key.split('_')[1]
                    incomingActiveLayerValues[to + 'new_' + inputKey] = value
                    delete incomingActiveLayerValues[key]
                }
            }
        })

        for (const [key, value] of Object.entries(incomingActiveLayerValues)) {
            if (key.includes('new')) {
                let fixedKey = key.replace('new', '')
                incomingActiveLayerValues[fixedKey] = value
                delete incomingActiveLayerValues[key]
            }
        }

        // remove unused layers
        incomingLayers = incomingLayers.filter(il => il.layerActive === 1)
        // remove spacers from layers input.list 
        incomingLayers.forEach(ly => {
            ly.inputs = ly.inputs.filter(ly => ly.inputKey !== 5 && ly.inputKey !== 59 && ly.inputKey !== 72)
            ly.layerInputKeys = ly.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
        })
        // seems that layerInputKeys isn't used for anything. I may remove it from the db "layer" table.
        // the layer.input.list array is whats used to setup the menus.


        let layersWithFonts = selectLayersWithFonts(state.layers, incomingLayers)

        let fonts = findFontsUsed(layersWithFonts, incomingActiveLayerValues)
        let missingFonts = findMissingFonts(fonts, layersWithFonts, incomingActiveLayerValues)

        let _loadErrorLineItems = []
        if (missingFonts.length > 0) {
            _loadErrorLineItems.push('missing fonts: ' + missingFonts.join(', '))
        }

        // find any incoming svg/image layers that may be referencing svgs that dont exist. If they point
        // to a custom svg that doesnt exist, lets remove it (and tell the user via error messages)
        let removeFromActiveLayerValues = []
        let incomingCustomSvgLayers = findCustomLayers(incomingLayers)
        incomingCustomSvgLayers.forEach(csvgLayer => {
            let requiredKey = csvgLayer.layer.layerActiveRequiredInputKey
            let svgKey = incomingActiveLayerValues[csvgLayer.layer.layerKey + '_' + requiredKey]
            removeFromActiveLayerValues.push(csvgLayer.layer.layerKey)
            incomingLayers = incomingLayers.filter(il => il.layerKey !== csvgLayer.layer.layerKey)

            if (csvgLayer.type === 'svg') {
                _loadErrorLineItems.push('missing svg with id: ' + svgKey + ' for ' + csvgLayer.layer.layerName)
            }
            else {
                _loadErrorLineItems.push('missing image with id: ' + svgKey + ' for ' + csvgLayer.layer.layerName)
            }

        })
        // find the svg key each custom layer is referencing.
        removeFromActiveLayerValues.forEach(lk => {
            for (const [key, value] of Object.entries(incomingActiveLayerValues)) {
                if (key.startsWith(lk + '_')) {
                    delete incomingActiveLayerValues[key]
                }
            }
        })

        let activeLayerKeys = Utility.activeLayerKeys(incomingActiveLayerValues)
        stateLayers.forEach(iLayer => {
            if (activeLayerKeys.includes(iLayer.layerKey)) {
                iLayer.layerActive = 1
                iLayer.layerHidden = 0
            }
        })

        if (_loadErrorLineItems.length > 0) {
            setLoadErrorLineItems(_loadErrorLineItems)
        }

        let counterSideActive = {
            active: "front",
            front: JSON.stringify(incomingActiveLayerValues),
            rear: JSON.stringify(incomingActiveLayerValues)
        }
        let counterState = {
            counterSideActive,
            customImageSvgsData: [],
            customSvgsData: [],
            fontsData: [...state.fonts],
            layers: stateLayers
        }

        return counterState
    }

    useEffect(() => {

        if (loadErrorLineItems.length > 0) {
            actions.addErrorMessages([{
                comment: "There are missing items in your counter file:",
                lineItems: loadErrorLineItems
            }])
            setLoadErrorLineItems([])
        }
    }, [state.activeLayerValues])

    const findCustomLayers = (incomingLayers) => {
        let results = []
        incomingLayers.forEach(il => {
            let isCustomImageLayer = Utility.isCustomImagesLayer(il.layerKey, state.layers)
            let isCustomSvgLayer = Utility.isCustomSvgsLayer(il.layerKey, state.layers)
            if (isCustomImageLayer) {
                results.push({ layer: il, type: 'image' })
            }
            if (isCustomSvgLayer) {
                results.push({ layer: il, type: 'svg' })
            }
        })

        return results
    }

    const zapoutIds = idStr => {
        let zappedStr = idStr

        let idsArray = []
        function findIds(str) {
            str = str.replace(/\s\s+/g, ' ');
            str = str.replace(/id =/ig, 'id=');
            str = str.replace(/id= \"/ig, 'id=\"'); // eslint-disable-line no-useless-escape
            const strPattern = "id\=\"\\S+\""; // eslint-disable-line no-useless-escape
            const pattern = new RegExp(strPattern, 'g');
            let matches;
            while ((matches = pattern.exec(str)) !== null) {
                idsArray.push(matches[0])
            }
        }
        findIds(idStr)
        idsArray = idsArray.map(id => id.replaceAll('"', ''))
        idsArray = idsArray.map(hr => hr.replace('id=', ''))
        idsArray = idsArray.map(hr => hr.replace('>', ''))
        let uniqueIds = [...new Set(idsArray)]
        uniqueIds.forEach(id => {
            zappedStr = zappedStr.replaceAll('id="' + id + '"', 'id=""')
        })


        let hrefsArray = []
        function findHrefs(str) {
            str = str.replace(/href =/ig, 'href=');
            str = str.replace(/href= \"/ig, 'href=\"'); // eslint-disable-line no-useless-escape
            const strPattern = "href\=\"\#\\S+"; // eslint-disable-line no-useless-escape
            const pattern = new RegExp(strPattern, 'g');
            let matches;
            while ((matches = pattern.exec(str)) !== null) {
                hrefsArray.push(matches[0])
            }
        }
        findHrefs(zappedStr)
        hrefsArray = hrefsArray.map(id => id.replaceAll('"', ''))
        hrefsArray = hrefsArray.map(hr => hr.replace('id=', ''))
        hrefsArray = hrefsArray.map(hr => hr.replace('>', ''))
        let uniqueHrefs = [...new Set(idsArray)]
        uniqueHrefs.forEach(href => {
            zappedStr = zappedStr.replaceAll('href="' + href + '"', 'href=""')
        })

        let widths = zappedStr.match(/width=".*?"/g)
        let heights = zappedStr.match(/height=".*?"/g)

        return zappedStr
    }

    const digoutSvg = (layerSvgInfo) => {
        let parsed = Snap.parse(layerSvgInfo.svg)
        let elementAdded = paper.append(parsed)
        let svgString = layerSvgInfo.svg

        let termStartPos = svgString.indexOf('layerGroup_')
        let quoteEnd = svgString.indexOf('"', termStartPos)
        let quoteStart = svgString.lastIndexOf('"', termStartPos)
        let targetGroupId = svgString.substring(quoteStart + 1, quoteEnd)
        let foundSvgNode = paper.select('#' + targetGroupId)
        let finalSvg = ''

        if (foundSvgNode) {
            let svgString = foundSvgNode.toString()
            if (svgString) {
                let svgTagStart = svgString.indexOf('<svg')
                let svgTagEnd = svgString.lastIndexOf('</svg>')
                if (svgTagStart > -1 && svgTagEnd > 0) {
                    finalSvg = svgString.substring(svgTagStart, svgTagEnd + 6)
                }
            }
        }

        return finalSvg.trim()
    }

    const findSvgsInfo = (layersWithSvgs, activeLayerValues) => {
        let svgs = []
        if (layersWithSvgs.length > 0) {
            layersWithSvgs.forEach(ly => {
                let svgKeyValue = activeLayerValues[ly.layerKey + '_' + ly.layerActiveRequiredInputKey]
                if (svgKeyValue && svgs.includes(svgKeyValue) === false) {
                    svgs.push({ layerKey: ly.layerKey, svgKey: svgKeyValue, svg: ly.svg, type: ly.svgType, uniquePrepend: ly.svgIdPrepend })
                }
            })
        }

        return svgs
    }

    const findFontsUsed = (layersWithFonts, activeLayerValues) => {
        let fonts = []
        if (layersWithFonts.length > 0) {
            layersWithFonts.forEach(lwf => {
                lwf.fontInputKeys.forEach(fik => {
                    let fontFamily = activeLayerValues[lwf.layer.layerKey + '_' + fik]
                    if (fontFamily && fonts.includes(fontFamily) === false) {
                        fonts.push(fontFamily)
                    }
                })
            })
        }

        return fonts
    }

    const findMissingFonts = (fonts, layersWithFonts, activeLayerValues) => {
        let fontsMissing = []
        if (fonts.length > 0) {
            fonts.forEach(font => {
                let found = state.fonts.find(sf => sf.fontFamily === font)
                if (!found) {
                    fontsMissing.push(font)
                }
            })
        }

        return fontsMissing
    }

    const findMissingSvgs = (svgs, layersWithSvgs, activeLayerValues) => {
        let svgsMissing = []
        if (svgs.length > 0) {
            svgs.forEach(svg => {
                let found = state.svgs.find(sf => sf.fontFamily === svg)
                if (!found) {
                    svgsMissing.push(svg)
                }
            })
        }

        return svgsMissing
    }

    const selectLayersWithFonts = (stateLayers, incomingLayers) => {
        let topLayerKeysWithFontInput = []
        let incomingLayersWithFontInput = []
        for (let i = 0; i < stateLayers.length; i++) {
            let layer = stateLayers[i]
            for (let n = 0; n < layer.inputs.length; n++) {
                let input = layer.inputs[n]
                if (input.type === 'font') {
                    let found = topLayerKeysWithFontInput.find(tlk => tlk.layerKey === layer.layerKey)
                    if (found) {
                        found.fontInputKeys.push(input.inputKey)
                    }
                    else {
                        topLayerKeysWithFontInput.push({ layerKey: layer.layerKey, fontInputKeys: [input.inputKey] })
                    }
                }
            }
        }
        incomingLayers.forEach(icl => {
            let found = topLayerKeysWithFontInput.find(tlkf => tlkf.layerKey === icl.layerKey || tlkf.layerKey === icl.parentLayerKey)
            if (found) {
                incomingLayersWithFontInput.push({ layer: icl, fontInputKeys: found.fontInputKeys })
            }
        })

        return incomingLayersWithFontInput
    }

    const selectLayersWithCustomSvgs = (stateLayers, incomingLayers) => {
        let layersWithCustomSvgs = []
        let topCustomImagesLayerKey = -1
        let topCustomSvgsLayerKey = -1
        let topCustomImagesLayer = stateLayers.find(ly => ly.layerName === 'custom images')
        if (topCustomImagesLayer) {
            topCustomImagesLayerKey = topCustomImagesLayer.layerKey
        }
        let topCustomSvgsLayer = stateLayers.find(ly => ly.layerName === 'custom svgs')
        if (topCustomSvgsLayer) {
            topCustomSvgsLayerKey = topCustomSvgsLayer.layerKey
        }
        if (topCustomImagesLayerKey && topCustomSvgsLayerKey) {
            incomingLayers.forEach(ily => {
                if (ily.layerKey === topCustomImagesLayerKey || ily.layerKey === topCustomSvgsLayerKey) {
                    if (ily.layerKey === topCustomImagesLayerKey) {
                        ily.svgType = 'image'
                    }
                    else {
                        ily.svgType = 'svg'
                    }
                    layersWithCustomSvgs.push(ily)
                }
                else {
                    if (ily.parentLayerKey === topCustomImagesLayerKey || ily.parentLayerKey === topCustomSvgsLayerKey) {
                        if (ily.parentLayerKey === topCustomImagesLayerKey) {
                            ily.svgType = 'image'
                        }
                        else {
                            ily.svgType = 'svg'
                        }
                        layersWithCustomSvgs.push(ily)
                    }
                }
            })
        }

        return layersWithCustomSvgs
    }

    const extractFontsUsed = (svgs) => {
        let fonts = []
        svgs.forEach(svg => {
            let checkForFontsText = svg.replaceAll('&quot;', '"')
            checkForFontsText = checkForFontsText.replaceAll('font-family: ', 'font-family:')
            let matches = checkForFontsText.matchAll(/font-family:\".*?"/g);
            if (matches) {
                for (const ffont of matches) {
                    let matchedItem = ffont[0]
                    let splitted = matchedItem.split(':')
                    if (splitted && splitted.length === 2) {
                        let fontName = splitted[1].replaceAll('"', '')
                        if (fontName && fonts.includes(fontName) === false) {
                            fonts.push(fontName)
                        }
                    }
                }
            }
        })

        return fonts
    }

    const updateActiveLayerValues = (layerKeyChanges, newActiveLayerValues) => {
        let updatedNewActiveLayerValues = { ...newActiveLayerValues }
        layerKeyChanges.forEach(lkc => {
            let fromLayerKey = lkc.fromLayerKey
            let toLayerKey = lkc.toLayerKey
            for (const [key, value] of Object.entries(newActiveLayerValues)) {
                if (key.startsWith(fromLayerKey + '_')) {
                    let newKey = key.replace(fromLayerKey + '_', toLayerKey + '_')
                    delete updatedNewActiveLayerValues[key]
                    updatedNewActiveLayerValues[newKey] = value
                }
            }
        })

        return updatedNewActiveLayerValues
    }

    const createNewStateLayersWithDupes = (incomingLayers, updatedStateLayers) => {
        let updatedIncomingLayers = [...incomingLayers]
        let newStateLayers = [...updatedStateLayers]
        let layerKeyChanges = [] // for updating activeLayerValues
        // preparation for adding new layers
        let maxLayerKey = Math.max(...newStateLayers.map(ly => parseInt(ly.layerKey)), 0)
        // if its coming from the sheet, the fonts and custom svgs and images are already in state. We just need to worry about
        // duplicated layers at this point, and updating activeLayerValues if layer keys were changed or added.
        //actions.activeLayerValuesReset(counterState.activeLayerValues)

        // deactivate all state layers, except for base counter
        newStateLayers.forEach(ly => {
            if (ly.layerKey > 0) {
                ly.layerActive = 0
            }
        })

        // we are only concerned about incoming duped layers.
        let incomingDupeLayers = incomingLayers.filter(cl => cl.parentLayerKey > -1)

        // now we have a list of duped layers that are incoming.
        // see if there are direct matches in current state layers
        let removeDupeLayerKeys = []
        incomingDupeLayers.forEach(idl => {
            let foundMatchingDupeStateLayer = newStateLayers.find(usl => usl.layerKey === idl.layerKey && usl.parentLayerKey === idl.parentLayerKey)
            if (foundMatchingDupeStateLayer) {
                removeDupeLayerKeys.push(idl.layerKey)
            }
        })

        incomingDupeLayers = incomingDupeLayers.filter(idl => removeDupeLayerKeys.includes(idl.layerKey) === false)

        if (incomingDupeLayers.length > 0) {
            incomingDupeLayers.forEach(incomingDupeLayer => {
                let foundMatchingDupeStateLayers = newStateLayers.filter(usl => usl.parentLayerKey === incomingDupeLayer.parentLayerKey)
                if (foundMatchingDupeStateLayers) { // 0, 1, or more can match.
                    // need to find if this matching dupe layer can be used as a substitute.
                    for (let i = 0; i < foundMatchingDupeStateLayers.length; i++) {
                        let possibleMatch = foundMatchingDupeStateLayers[i]
                        let foundUsedByAnotherIncomingLayer = incomingDupeLayers.find(idl => idl.layerKey === possibleMatch.layerKey)
                        if (foundUsedByAnotherIncomingLayer) {
                            continue;
                        }
                        if (!foundUsedByAnotherIncomingLayer) {
                            layerKeyChanges.push({ fromLayerKey: incomingDupeLayer.layerKey, toLayerKey: possibleMatch.layerKey })
                            // dont need to worry about this incoming dupe layer anymore.
                            removeDupeLayerKeys.push(incomingDupeLayer.layerKey)
                            break;
                        }
                    }
                }
            })
            incomingDupeLayers = incomingDupeLayers.filter(idl => removeDupeLayerKeys.includes(idl.layerKey) === false)
        }

        if (incomingDupeLayers.length > 0) {
            // if we still have incoming dupe layers that dont directly match state layers, or that couldnt be reassigned a duped state layer, 
            // we need to create new layer(s).

            incomingDupeLayers.forEach(idl => {
                // get the parent layer we will make a dupe of
                let stateLayerToDupe = newStateLayers.find(nl => nl.layerKey === idl.parentLayerKey)
                stateLayerToDupe = JSON.parse(JSON.stringify(stateLayerToDupe))
                maxLayerKey++
                stateLayerToDupe.parentLayerKey = stateLayerToDupe.layerKey
                stateLayerToDupe.layerKey = maxLayerKey

                let duplicatedName = newStateLayers.find(nsl => nsl.layerName === idl.layerName)
                if (duplicatedName) {
                    let i = 2
                    for (i; i < 100; i++) {
                        let tryName = idl.layerName + i
                        duplicatedName = newStateLayers.find(nsl => nsl.layerName === tryName)
                        if (!duplicatedName) {
                            stateLayerToDupe.layerName = tryName
                            break
                        }
                    }
                    if (i > 99) {
                        stateLayerToDupe.layerName = idl.layerName + '_' + Utility.randomString(5)
                    }
                }

                else {
                    stateLayerToDupe.layerName = idl.layerName
                }

                let oldIncomingDupeLayerKey = idl.layerKey
                layerKeyChanges.push({ fromLayerKey: oldIncomingDupeLayerKey, toLayerKey: maxLayerKey })
                newStateLayers.push(stateLayerToDupe)
            })
        }

        layerKeyChanges.forEach(lkc => {
            let found = updatedIncomingLayers.find(uil => uil.layerKey === lkc.fromLayerKey)
            if (found) {
                found.layerKey = lkc.toLayerKey
            }
        })

        return { updatedIncomingLayers, newStateLayers, layerKeyChanges }
    }


    const filterOldCounterData = data => {

    }

    const installAnyNeededSvgs = (checkSvgs) => {
        if (!checkSvgs) {
            return
        }
        // this will hold any svgs that need to be installed.
        let installSvgs = []
        let changeIncomingSvgKeysFromTo = []

        // this creates a list of currently existing custom svgs.
        let listOfCustomStateSvgsCleanedCode = state.svgs.filter(svg => svg.svgKey >= 1000)
        listOfCustomStateSvgsCleanedCode = JSON.parse(JSON.stringify(listOfCustomStateSvgsCleanedCode))
        // strip the svgs of any prepend strings so we can do a svgCode comparison later
        listOfCustomStateSvgsCleanedCode.forEach(css => {
            if (css.uniquePrepend) {
                css.svgCode = css.svgCode.replaceAll(css.uniquePrepend, '')
            }
        })

        // put the incoming svgs into one array so we can process them together.

        if (checkSvgs.length > 0) {

            // useKey will be the start point from adding new svgKeys
            let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
            for (let i = 0; i < checkSvgs.length; i++) {
                let incomingSvg = checkSvgs[i]
                let splittedCombined = incomingSvg.combined.split(':')
                let svgKeyFromCombined = Number(splittedCombined[0])
                let svgTypeFromCombined = splittedCombined[2]

                if (svgKeyFromCombined !== incomingSvg.svgKey) {
                    incomingSvg.svgKey = svgKeyFromCombined
                }
                if (svgTypeFromCombined !== incomingSvg.type) {
                    incomingSvg.svgType = svgTypeFromCombined
                }

                let matchCode = incomingSvg.svgCode
                if (incomingSvg.uniquePrepend) {
                    matchCode = incomingSvg.svgCode.replaceAll(incomingSvg.uniquePrepend, '')
                }

                // see if theres a direct match. If so, nothing needs to be done.
                let foundSvg = listOfCustomStateSvgsCleanedCode.find(ssvg => ssvg.svgKey === incomingSvg.svgKey && ssvg.svgCode === matchCode)
                if (!foundSvg) {
                    // see if theres a code match
                    foundSvg = listOfCustomStateSvgsCleanedCode.find(ssvg => ssvg.svgCode === matchCode)
                    if (foundSvg) {
                        changeIncomingSvgKeysFromTo.push({ fromSvgKey: incomingSvg.svgKey, toSvgKey: foundSvg.svgKey, type: incomingSvg.type, new: false, nameIncoming: incomingSvg.svgName, nameExisting: foundSvg.svgName })
                    }
                    else {
                        // insert incoming svg to state as new svg
                        if (useKey < 2000) {
                            useKey = 2000
                        }
                        else {
                            useKey++
                        }
                        changeIncomingSvgKeysFromTo.push({ fromSvgKey: incomingSvg.svgKey, toSvgKey: useKey, type: incomingSvg.type, new: true, nameIncoming: incomingSvg.svgName, nameExisting: null })
                        incomingSvg.svgKey = useKey
                        installSvgs.push(incomingSvg)
                    }
                }
            }

            if (installSvgs.length > 0) {
                // delete the temporarily added keys
                installSvgs.forEach(isvg => {
                    delete isvg.combined
                    delete isvg.type
                })
                let newStateSvgs = [...state.svgs, ...installSvgs]
                actions.svgs(newStateSvgs)
            }
        }

        return changeIncomingSvgKeysFromTo
    }

    const updateStateLayersListWithNewSvgKeys = svgKeyUpdates => {
        // update the state layers of custom svg/image svg types to include the new svg keys
        let stateLayers = JSON.parse(JSON.stringify(state.layers))
        if (!svgKeyUpdates) {
            return stateLayers
        }
        let customImageSvgLayers = Utility.allLayersOfType(stateLayers, 'custom images')
        let customSvgLayers = Utility.allLayersOfType(stateLayers, 'custom svgs')
        let allCustomSvgLayers = [...customImageSvgLayers, ...customSvgLayers]
        svgKeyUpdates.forEach(svgSet => {
            let type = svgSet.type
            let newSvgKey = svgSet.toSvgKey
            if (type === 'image') {
                customImageSvgLayers.forEach(cil => {
                    let input = cil.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                            if (input.list.includes(newSvgKey) === false) {
                                input.list.push(newSvgKey)
                                cil.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                                cil = { ...cil }
                            }
                        }
                    }
                })

            }
            if (type === 'svg') {
                customSvgLayers.forEach(csl => {
                    let input = csl.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                            if (input.list.includes(newSvgKey) === false) {
                                input.list.push(newSvgKey)
                                csl.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                                csl = { ...csl }
                            }
                        }
                    }
                })

            }
        })

        return stateLayers
    }




    useEffect(() => {
        // if (state.counterLoadFromSheet) {
        //     setupCounter(state.counterLoadFromSheet)
        //     actions.counterLoadFromSheet(null)
        // }
    }, [state.counterLoadFromSheet])

    useEffect(() => {
        // if (state.counterLoadFromApp) {
        //     actions.loadCounterProcessing(state.counterLoadFromApp)
        //     actions.counterLoadFromApp(null)
        // }
    }, [state.counterLoadFromApp])

    useEffect(() => {
        // if (state.counterLoadFromFile) {
        //     actions.loadCounterProcessing(state.counterLoadFromFile)
        //     actions.counterLoadFromFile(null)
        // }
    }, [state.counterLoadFromFile])

    return (
        <div className="load-counter-control">
            <button className="standard-button blue" onClick={toggleDialog}><div>Load{longName ? ' Counter' : ''}</div></button>
            <div ref={loadCounterDialogRef} id="dialog" className={dialogOpen ? 'dialog' : 'display-none'}>
                <div className="title">
                    <div></div>
                    <div>Load Counter</div>
                    <CloseX close={toggleDialog} />
                </div>
                <div className="select-input">

                    <div className="load-section">
                        <div className="load-section-title">LOAD FROM APP</div>
                        <div className="load-input-inline">
                            <div>load counter:</div>
                            <select id="savedCounter" value="" onChange={loadFromApp}>
                                <option key="none" value="">choose counter</option>
                                {
                                    savedCounters.map((sc, index) => <option key={index} value={sc.hash}>{sc.name}</option>)
                                }
                            </select>
                        </div>
                    </div>

                    <div className="load-section load-from-file">
                        <div className="load-section-title">LOAD FROM FILE</div>
                        <input type="file" id="counterFile" accept=".scz" onChange={loadFromFile} />
                    </div>
                    <svg id="extractorSvg" />
                </div>
            </div>
        </div>




    )

}
export default LoadCounterControl
/*
<svg id="vm4sgjl_czk87bzto_svg2483" viewBox="0 0 469 497.6" version="1.0">
  <g id="vm4sgjl_czk87bzto_layer8" transform="translate(-86.225 -49.534)">
    <g id="vm4sgjl_czk87bzto_g7275" clip-path="url(#vm4sgjl_czk87bzto_clipPath7279)">





<svg id="p3cc6t_tiger" viewBox="0 0 469 497.59" version="1.0" > 
<defs id="p3cc6t_defs2486" > <linearGradient id="p3cc6t_linearGradient7303" > <stop id="p3cc6t_stop7305" stop-color="#808080" offset="0" /> 
<g id="p3cc6t_layer8" transform="translate(-86.225 -49.534)" >
 <g id="p3cc6t_g7275" clip-path="url(#p3cc6t_clipPath7279)" >
  <rect id="p3cc6t_rect7271" style="color:#000000" height="138.59" width="128.69" y="43.243" x="121.27" fill="#c1782b" /> <path id="p3cc6t_path7354" d="m190.57 126.33c-10.253-20.86-15.556-28.284-14.849-45.255 0.7071-16.971 8.1317-29.345-5.6569-25.809-13.789 3.5355-35.709 31.466-30.406 45.962 5.3033 14.496 25.809 39.952 34.295 46.669 8.4853 6.7175 24.042 6.7175 16.617-21.567z" fill="url(#p3cc6t_radialGradient7362)" /> <path id="p3cc6t_path7364" d="m218.14 96.629c0.00001 9.8995-3.8891 20.506-9.5459 14.849-5.6568-5.6569-18.031-22.274-19.092-29.698-1.0607-7.4246-4.2426-17.678-10.96-17.324-6.7175 0.35355-22.792 10.997-26.516 25.456-3.6365 14.117 1.7678 20.153 5.3033 27.931 3.5355 7.7782 27.931 32.173 31.113 37.83 3.182 5.6569 5.6568 21.92-3.182 24.749-8.8388 2.8284-21.92-7.0711-27.577-18.031-5.6568-10.96-25.456-62.225-25.102-76.014 0.35355-13.789 15.91-33.234 29.345-39.598s31.113-11.314 40.659 0.35355c14.226 10.489 24.009 44.81 15.556 49.497z" /> </g > <g id="p3cc6t_g7287" clip-path="url(#p3cc6t_clipPath7291)" > <rect id="p3cc6t_rect7285" style="color:#000000" height="183.85" width="141.42" y="32.636" x="377.6" fill="#c1782b" /> <path id="p3cc6t_path7300" style="color:#000000" fill="url(#p3cc6t_radialGradient7352)" d="m432.02 155.45c5.732 5.139 12.953-16.574 16.088-29.452 6.0923-25.027-2.6806-41.486 8.9471-54.592 10.813-12.187 23.736-6.4851 34.941 5.9174 5.4923 6.0794 6.5024 14.777 4.6848 22.609-2.71 11.677-8.7254 22.091-13.182 33.057-2.9515 7.2626-3.3031 10.381-1.9111 17.968 4.1534 22.638-13.233 30.449-31.403 24.964l-18.165-20.471z" /> <path id="p3cc6t_path7313" d="m404 119c22.5-13 30.5-29 37-38.5s14-21.5 24.5-19 26 12.5 28.5 20-1.5 26-2.5 29.5-9.5 7.5-12 16 4 25 3.5 30.5-9.5 32-13 37-1 17.5 12.5 10.5 26-63 32.5-89 23-53.5-7-65.5-46.5-18.5-66-7-59 61.5-38 75.5z" /> </g > </g > <g id="p3cc6t_layer10" transform="translate(-86.225 -49.534)" > <path id="p3cc6t_path7065" style="color:#000000" 
*/
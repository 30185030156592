import React, { useEffect, useContext, useState } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import './CounterFrontRearControl.scss'
import { StoreContext } from "../../context/StoreContext";
const CounterFrontRearControl = ({ flipCounter, copyFrom, hide }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state } = useContext(StoreContext)
    const [currentSide, setCurrentSide] = useState(state.counterSideActive.active)
    const [disabled, setDisabled] = useState(false)

    const onChangeCounter = evt => {
        if (currentSide !== evt.target.value) {
            manageCurrentSide(evt.target.value)
        }
    }

    const onClickDivFront = () => {
        if (currentSide === 'rear') {
            manageCurrentSide('front')
        }
    }

    const onClickDivRear = () => {
        if (currentSide === 'front') {
            manageCurrentSide('rear')
        }
    }

    const throttle = (func, wait) => {
        let waiting = false;
        return function () {
            if (waiting) {
                return;
            }

            waiting = true;
            setTimeout(() => {
                func.apply(this, arguments);
                waiting = false;
            }, wait);
        };
    }

    const passValue = throttle((val, fn) => {
        fn(val)
    }, 20);

    const manageCurrentSide = side => {
        if (currentSide !== side) {
            passValue(side, setCurrentSide)
        }
    }

    useEffect(() => {
        setDisabled(false)
    }, [state.activeLayerValues])

    useEffect(() => {
        if( currentSide !== state.counterSideActive.active ) {
            flipCounter(currentSide)
            setDisabled(true)
        }
    }, [currentSide])

    const clickCopyFrom = evt => {
        if( evt.target.id === 'copy_from_front' ) {
            setDisabled(true)
            copyFrom('front')
        }
        if( evt.target.id === 'copy_from_rear' ) {
            setDisabled(true)
            copyFrom('rear')
        }
    }

    return (
        <div className={hide ? 'display-none' : 'counter-front-rear-control'}>
            <div className="option" onClick={onClickDivFront} >
                <input type="radio"
                    id="counterFront"
                    name="counterFront"
                    value='front'
                    checked={currentSide === 'front'}
                    onChange={onChangeCounter}
                    onClick={onChangeCounter}
                />
                <label htmlFor="counterFront" className={currentSide === 'front' ? 'hilite' : ''}>front</label>
            </div>
            <div className="option" onClick={onClickDivRear}>
                <input type="radio"
                    id="counterRear"
                    name="counterRear"
                    value='rear'
                    checked={currentSide === 'rear'}
                    onChange={onChangeCounter}
                    onClick={onChangeCounter}
                />
                <label htmlFor="counterRear" className={currentSide === 'rear' ? 'hilite' : ''}>rear</label>
            </div>
            <div className={currentSide === 'rear' ? '' : 'display-none'}>
                <button id="copy_from_front" className={disabled ? 'standard-button disabled' : 'standard-button blue'} onClick={disabled ? null : clickCopyFrom}>copy from front</button>
            </div>
            <div className={currentSide === 'front' ? '' : 'display-none'}>
                <button id="copy_from_rear" className={disabled ? 'standard-button disabled' : 'standard-button blue'} onClick={disabled ? null : clickCopyFrom}>copy from rear</button>
            </div>
        </div>
    )
}

export default CounterFrontRearControl
import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const Text = {

    draw: (g, settings) => {
        if (!settings || settings.length === 0) {
            return
        }
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'text': derivedValues.text = setting.value
                    break
                case 'font': derivedValues.fontFamily = setting.value
                    break
                case 'font style': derivedValues.fontStyle = setting.value
                    break
                case 'font size': derivedValues.fontSize = Number(setting.value)
                    break
                case 'font weight': derivedValues.fontWeight = setting.value
                    break
                case 'stroke color': derivedValues.strokeColor = setting.value
                    break
                case 'stroke width': derivedValues.strokeWidth = setting.value
                    break
                case 'scale width': derivedValues.width = setting.value
                    break
                case 'scale height': derivedValues.height = setting.value
                    break
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        if (!derivedValues.width) {
                            derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        }
                        if (!derivedValues.height) {
                            derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                        }
                    }
                    break
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'line color': derivedValues.textColor = setting.value
                    break
                case 'fill color': derivedValues.fillColor = setting.value
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                case 'letter gap': derivedValues.letterGap = parseInt(setting.value)
                    break
                case 'spacer': break
                default: console.warn('we seem to have missed one: ', setting.name)
            }
        })
        derivedValues.strokeWidth = (derivedValues.fontSize) * (derivedValues.strokeWidth / 100) / 15
        if (derivedValues.strokeColor === '') {
            derivedValues.strokeColor = 'transparent'
        }
        if (derivedValues.fillColor === '') {
            derivedValues.fillColor = 'transparent'
        }
        if (derivedValues.fontWeight === 'thin') {
            derivedValues.fontWeight = 100
        }
        if (derivedValues.fontWeight === 'normal') {
            derivedValues.fontWeight = 500
        }
        if (derivedValues.fontWeight === 'bold') {
            derivedValues.fontWeight = 900
        }

        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }

        let scaleWidth = derivedValues.width
        let scaleHeight = derivedValues.height
        let xpos = derivedValues.xpos
        let ypos = derivedValues.ypos
        let rotation = derivedValues.rotation
        let opacity = derivedValues.opacity
        Text.drawText(g, derivedValues)

        g.transform(new Snap.matrix().scale(scaleWidth / 100, scaleHeight / 100))

        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }

        if (rotation > 0) {
            Text.applyRotation(g, rotation)
        }
        if (xpos !== 0 || ypos !== 0) {
            Text.applyTranslation(g, xpos, ypos)
        }
    },

    drawText: (g, derivedValues) => {
        let t = g.text(0, 0, derivedValues.text ? derivedValues.text : '').attr(
            {
                fontFamily: "'" + derivedValues.fontFamily + "'",
                fontStyle: derivedValues.fontStyle,
                textAnchor: "middle",
                dominantBaseline: "central",
                fontWeight: derivedValues.fontWeight,
                fill: derivedValues.fillColor,
                stroke: derivedValues.strokeColor,
                strokeWidth: derivedValues.strokeWidth,
                fontSize: derivedValues.fontSize,
                letterSpacing: derivedValues.letterGap,
                opacity: derivedValues.opacity
            })
        t.node.setAttributeNS("http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
}

export default Text
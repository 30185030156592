import React, { useEffect, useState } from 'react'
import './ThreeDeeViewDraggers.scss'
const ThreeDeeViewDraggers = ({ threeDeeActive, reportThreeDeeValues }) => {

    const [explodeDistance, setExplodeDistance] = useState(0)
    const [viewAngle, setViewAngle] = useState(0)
    const [viewRotation, setViewRotation] = useState(0)

    const throttle = (func, wait) => {
        let waiting = false;
        return function () {
            if (waiting) {
                return;
            }
            waiting = true;
            setTimeout(() => {
                func.apply(this, arguments);
                waiting = false;
            }, wait);
        };
    }

    const passValue = throttle((num, fn) => {
        fn(num)
    }, 20);

    const changeExplodeDistance = evt => {
        passValue(evt.target.value, setExplodeDistance)
    }

    const changeViewAngle = evt => {
        passValue(evt.target.value, setViewAngle)
    }

    const changeViewRotation = evt => {
        passValue(evt.target.value, setViewRotation)
    }

    useEffect(() => {
        if( !threeDeeActive ) {
            setExplodeDistance(0)
            setViewAngle(0)
            setViewRotation(0)
        }
    }, [threeDeeActive])

    useEffect(() => {
        reportThreeDeeValues({ explodeDistance, viewAngle: (101 - viewAngle), viewRotation })
    }, [explodeDistance, viewAngle, viewRotation]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        threeDeeActive ?
            <div className="three-dee-view-draggers">
                <div className="explode-control">
                    <div className="label"><span>explode distance</span></div>
                    <div className="slider">
                        <input type="range"
                            min="0" max="100"
                            step="1"
                            value={explodeDistance}
                            onChange={changeExplodeDistance}
                        />
                    </div>
                </div>
                <div className="rotate-control">
                    <div className="rotate-position">
                        <div className="label"><span>rotate</span></div>
                        <div className="slider">
                            <input type="range"
                                min="-89" max="89"
                                step="1"
                                value={viewRotation}
                                onChange={changeViewRotation}
                            />
                        </div>
                    </div>
                </div>
                <div className="angle-control">
                    <div className="label"><span>angle</span></div>
                    <div className="slider">
                        <input type="range"
                            min="1" max="100"
                            step="1"
                            value={viewAngle}
                            onChange={changeViewAngle}
                        />
                    </div>
                </div>
            </div>
            : ''
    )

}
export default ThreeDeeViewDraggers
import React, { useState, useEffect, useContext } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './MoveToSheetControl.scss'

const MoveToSheetControl = ({ longName }) => {
    const { actions, state } = useContext(StoreContext)
    const [packaged, setPackaged] = useState(null)
    const [originalSide, setOriginalSide] = useState('')
    const [moving, setMoving] = useState(false)
    const [returnToOriginal, setReturnToOriginal] = useState(false)

    const move = () => {
        setOriginalSide(state.counterSideActive.active)
        setMoving(true)
    }

    useEffect(() => {
        if (moving) {
            let _packaged = {
                package: null,
                frontSvg: '',
                rearSvg: ''
            }
            // we need to check if both sides are exactly the same, meaning the same alv. If so - we need to just
            // construct both packagedSides here and move on.

            let packagedCounterState = packageCounterState()
            let temp = JSON.parse(JSON.stringify(packagedCounterState))
            //console.log('packagedCounterState:', JSON.parse(JSON.stringify(temp)))
            _packaged.package = temp
            // we got the package. What we need now is the combinedSvgs for front and rear.
            // get combined svg for the current side now, since its right there.
            let combinedSvg = combineSvgsOnCounter()
            //console.log('created combinedSvg')
            placeCloneSVG(combinedSvg)
            _packaged[originalSide === 'front' ? 'frontSvg' : 'rearSvg'] = combinedSvg

            // check to see if front and rear are the same. If so, we can short-circuit this whole process.
            if (state.counterSideActive.front === state.counterSideActive.rear) {
                _packaged[originalSide === 'front' ? 'rearSvg' : 'frontSvg'] = combinedSvg
                //console.log('both sides are the same, wrapping up package.')
                setPackaged(_packaged)
                setMoving(false)
            }
            else {
                // // flip to other side
                //console.log('flipping to other side.')
                let flipToSide = originalSide === 'front' ? 'rear' : 'front'
                let newAlv = state.counterSideActive[flipToSide]
                actions.counterSideActive({ ...state.counterSideActive, active: flipToSide })
                actions.activeLayerValuesReset(JSON.parse(newAlv))
                setPackaged(_packaged)
                setMoving(false)
            }
        }
    }, [moving])

    const placeCloneSVG = (svg) => {
        var xmlns = "http://www.w3.org/2000/svg";
        // var boxWidth = 800;
        // var boxHeight = 800;
        svg = svg.replace('width="100%" height="100%"', 'width="83.3%" height="83.3%"')
        var svgElem = document.createElementNS(xmlns, "svg");
        svgElem.setAttributeNS(null, "viewBox", "-20, -20, 240, 240");
        // svgElem.setAttributeNS(null, "width", "33350px");
        //  svgElem.setAttributeNS(null, "height", "53330px");
        svgElem.setAttributeNS(null, "id", "svgCopy")
        svgElem.setAttributeNS(null, "class", "draw-layer svg-copy")
        var svgContainer = document.getElementById("drawingArea");
        //var list = document.getElementsByClassName("draw-layer");
        //svgContainer.insertBefore(svgElem, list[0]);
        svgContainer.append(svgElem)
        let _paper = Snap('#svgCopy');
        let parsed = Snap.parse(svg)
        _paper.append(parsed)
        svgElem.style.position = "absolute";
        svgElem.style.top = "0px";
        svgElem.style.left = "0px";
        svgElem.style.opacity = 1;
        svgElem.style.zIndex = 1000;
    }

    useEffect(() => {
        if (packaged) {
            if (packaged.package && packaged.frontSvg && packaged.rearSvg) {
                //console.log('both sides are set, calling actions.moveCounterToSheet with:', packaged)
                actions.moveCounterToSheet(JSON.parse(JSON.stringify(packaged)))
                setPackaged(null)

                //console.log('calling actions.counterSideActive to go back to original.')
                actions.counterSideActive({ ...state.counterSideActive, active: originalSide })
                setReturnToOriginal(true)
            }
            else {
                ;////console.log('not done yet, missing one side or the other:', packaged)
            }
        }
        else {
            let ele = document.getElementById('svgCopy')
            if (ele) {
                //console.log('removing clone')
                ele.remove()
            }
        }
    }, [packaged])

    useEffect(() => {
        if( returnToOriginal ) {
            let resetAlv = ''
            if (originalSide === 'front') {
                resetAlv = state.counterSideActive.front
                //console.log('putting back to original side, front is:', resetAlv)
            }
            if (originalSide === 'rear') {
                resetAlv = state.counterSideActive.rear
            }
            //console.log('calling activeLayerValues to put it back to', JSON.parse(resetAlv))
            actions.activeLayerValuesReset(JSON.parse(resetAlv))
        }
        setReturnToOriginal(false)
    }, [returnToOriginal])

    useEffect(() => {
        if (packaged) {
            if (packaged.frontSvg === '' || packaged.rearSvg === '') {
                //console.log('missing one side or the other.')
                let combinedSvg = combineSvgsOnCounter()
                //console.log('got combinedSvg:', combinedSvg.substring(0, 200))
                let _packaged = { ...packaged }
                if (_packaged.frontSvg === '') {
                    _packaged.frontSvg = combinedSvg
                }
                if (_packaged.rearSvg === '') {
                    _packaged.rearSvg = combinedSvg
                }
                //console.log('inside useEffect state.counterRedrawn, calling setPackaged:', _packaged)
                setPackaged(_packaged)
            }
        }

    }, [state.counterRedrawn]) // problem - if both sides are the same, no counterRedraw occurs.


    useEffect(() => {

    }, [state.counterSideActive])

    const detectFonts = activeLayers => {
        let fontFamilies = []
        let layers = [...state.layers]

        activeLayers.forEach(ly => {
            let originalLayerName = Utility.originalLayerNameLayerKey(layers, ly.layerKey)
            if (originalLayerName === 'Google material symbols') {
                if (fontFamilies.includes('Material Icons') === false) {
                    fontFamilies.push('Material Icons')
                }
            }
            else {
                ly.inputs.forEach(ip => {
                    if (ip.named === 'font') {
                        if (state.activeLayerValues[ly.layerKey + '_' + ip.inputKey]) {
                            let fontFamily = state.activeLayerValues[ly.layerKey + '_' + ip.inputKey]
                            if (fontFamily) {
                                if (fontFamilies.includes(fontFamily) === false) {
                                    fontFamilies.push(fontFamily)
                                }
                            }
                        }
                    }
                })
            }
        })

        return fontFamilies
    }

    const combineSvgsOnCounter = () => {
        let utime = new Date().getTime()
        let builtUpSvg = '<svg id="combined_' + utime + '" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="20 20 200 200">'
        builtUpSvg += '<g transform="scale(1.2)">'
        // get the active layers 
        let layersOrder = state.layers.map(ly => ly.layerActive ? { layerKey: ly.layerKey, layerOrder: ly.layerOrder } : null)
        layersOrder = layersOrder.filter(lo => lo)
        layersOrder = layersOrder.sort((a, b) => a.layerOrder - b.layerOrder)
        // drawLayer_ svgLayer
        layersOrder.forEach(alk => {
            let id = 'drawLayer_' + alk.layerKey
            let ele = document.getElementById(id)
            let svg = ele.innerHTML + "\n\n"
            if (Utility.isCustomSvgsLayer(alk.layerKey, state.layers)) {
                // custom svgs may use def ids for various svg elements. We need to change these so they dont clash with other
                // svgs in the browser at the same time.
                let allIds = ele.querySelectorAll('*[id]')
                let prepend = ''
                // find the prepend used
                for (let i = 0; i < allIds.length; i++) {
                    let aid = allIds[i]
                    if (aid.id.startsWith('p') && aid.id.indexOf('_') < aid.id.length - 1) {
                        prepend = aid.id.substring(0, aid.id.indexOf('_') + 1)
                        if (prepend.length > 5 && prepend.length < 7) {
                            break;
                        }
                    }
                }
                if (prepend) {
                    let newPrepend = 'p' + Utility.randomString(5) + '_'
                    svg = svg.replaceAll(prepend, newPrepend)
                }
            }

            builtUpSvg += svg
        })

        builtUpSvg += '</g></svg>'

        return builtUpSvg
    }

    const packageCounterState = () => {
        let frontAlv = state.counterSideActive.front
        let rearAlv = state.counterSideActive.rear
        if (!frontAlv || !rearAlv) {
            console.error('invalid alv values from counterSideActive')
            return
        }
        frontAlv = JSON.parse(frontAlv)
        rearAlv = JSON.parse(rearAlv)

        //let activeLayers = _layers.sort((a, b) => a.layerOrder - b.layerOrder).filter(sl => sl.layerActive === 1)
        let layersUsed = Utility.activeLayersBothSides(frontAlv, rearAlv, state.layers)
        let layerCustomImages = state.layers.find(ly => ly.layerName === 'custom images')
        let found = layerCustomImages.inputs.find(inp => inp.named === 'svgKey')
        let customImageSvgsList = found.list
        let layerCustomSvgs = state.layers.find(ly => ly.layerName === 'custom svgs')
        found = layerCustomSvgs.inputs.find(inp => inp.named === 'svgKey')
        let customSvgsList = found.list
        let customImageSvgKeys = []
        let customSvgKeys = []

        // state.layers is guaranteed to have the layers the counterSideActive references, since the code doesnt allow removal of layers
        // that are currently used on the counter.
        // So we look for any custom (svg or image) items used in the front and rear of the counter, then we get that svg and
        // include it in the custom image/svg arrays for safe storage for if the user sometime in the future loads the counter,
        // after those custom svgs may had been deleted, or the user restored the default database. We dont need to know if the
        // custom svg/image is used on front or back, since it doesnt matter. It would just need to be reinstalled for either side to use.
        layersUsed.forEach(ly => {
            if (Utility.isCustomLayer(ly.layerKey, state.layers)) {
                if (ly.layerActiveRequiredInputKey) {
                    let frontValue = frontAlv[ly.layerKey + '_' + ly.layerActiveRequiredInputKey]
                    let rearValue = rearAlv[ly.layerKey + '_' + ly.layerActiveRequiredInputKey]
                    if (frontValue) {
                        if (customImageSvgsList.includes(frontValue) === false) {
                            customImageSvgKeys.push(frontValue)
                        }
                    }
                    if (rearValue) {
                        if (customSvgsList.includes(rearValue) === false) {
                            customSvgKeys.push(rearValue)
                        }
                    }
                }
            }
        })

        let fontsDetected = detectFontsBothSides(frontAlv, rearAlv)

        return {
            counterSideActive: state.counterSideActive,
            layers: layersUsed,
            customImageSvgKeys,
            customSvgKeys,
            fonts: fontsDetected
        }

    }


    const detectFontsBothSides = (frontAlv, rearAlv) => {
        let fontFamilies = []
        let frontActiveLayerKeys = Utility.activeLayerKeys(frontAlv)
        frontActiveLayerKeys.forEach(lk => {
            let originalLayerName = Utility.originalLayerNameLayerKey(state.layers, lk)
            if (originalLayerName === 'Google material symbols') {
                if (fontFamilies.includes('Material Icons') === false) {
                    fontFamilies.push('Material Icons')
                }
            }
            else {
                let layer = state.layers.find(sl => sl.layerKey === lk)
                if (layer) {
                    layer.inputs.forEach(ip => {
                        if (ip.named === 'font') {
                            if (frontAlv[layer.layerKey + '_' + ip.inputKey]) {
                                let fontFamily = frontAlv[layer.layerKey + '_' + ip.inputKey]
                                if (fontFamily) {
                                    if (fontFamilies.includes(fontFamily) === false) {
                                        fontFamilies.push(fontFamily)
                                    }
                                }

                            }
                        }
                    })
                }
            }
        })
        let rearActiveLayerKeys = Utility.activeLayerKeys(rearAlv)
        rearActiveLayerKeys.forEach(lk => {
            let originalLayerName = Utility.originalLayerNameLayerKey(state.layers, lk)
            if (originalLayerName === 'Google material symbols') {
                if (fontFamilies.includes('Material Icons') === false) {
                    fontFamilies.push('Material Icons')
                }
            }
            else {
                let layer = state.layers.find(sl => sl.layerKey === lk)
                if (layer) {
                    layer.inputs.forEach(ip => {
                        if (ip.named === 'font') {
                            if (rearAlv[layer.layerKey + '_' + ip.inputKey]) {
                                let fontFamily = rearAlv[layer.layerKey + '_' + ip.inputKey]
                                if (fontFamily) {
                                    if (fontFamilies.includes(fontFamily) === false) {
                                        fontFamilies.push(fontFamily)
                                    }
                                }

                            }
                        }
                    })
                }
            }
        })

        return fontFamilies
    }

    return (
        <div className="move-to-sheet-control">
            <button className="standard-button blue" onClick={move}><div>Move{longName ? ' to Sheet' : ''}</div></button>
        </div>
    )

}
export default MoveToSheetControl
import Utility from './Utility';
let API = null;
let ApiConnector = {

    call: async (method, resource, params, token) => {
        if (!method || !resource) {
           // return;
        }

        token = token ? token : '';

        if (!API) {
            let config = Utility.getConfig();
            let environment = config.environment;
            //console.log('environment:',environment)
            if (environment === "development") {
                API = "http://localhost:4999/";
            }
            else {
                API = "https://snapcounter.app/wargalleyservice/";
            }
        }
        let uri = API + resource;
        // stick on params as querystring if there are any
        if (method.toLowerCase() === 'get' && params && Utility.isObjectAndNotEmpty(params)) {
            var queryString = Object.keys(params).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');
            if (uri.includes('?')) {
                uri += '&' + queryString
            }
            else {
                uri += '?' + queryString
            }
        }
        uri = uri.replaceAll('//','/')
        uri = uri.replace('https:/', 'https://')

        return new Promise((resolve, reject) => {
            if (method.toLowerCase() === 'post') {
                try {
                    fetch(uri, {
                        method: method,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(params)
                    }).then(response => {

                        response.json().then(data => {
                            if (data.hasOwnProperty('1error')) {
                                reject(data)
                            }
                            else {
                                resolve(data)
                            }
                        })
                    },
                        error => {
                            console.warn('1error: ', error)
                            reject(error)
                        })
                        .catch(error => console.warn('caught error: ', error))




                }
                catch (error) {
                    console.warn('2' + error)
                }

            }

            if (method.toLowerCase() === 'get' && token !== '') {
                fetch(uri, {
                    method: method,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                }).then(response => {

                    if (response.status !== 200) {
                        // try again 
                        
                            response = fetch(uri, {
                                method: method,
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': token,
                                },
                            }).then(response => {
                                if (response.status !== 200) {
                                    reject('api did not accept request, status: ' + response.status + ' statusText: ' + response.statusText)
                                }
                                else {
                                    response.json().then(data => resolve(data))
                                }
                            })
                       
                    }
                    else {
                        response.json().then(data => resolve(data))
                    }
                }, error => {
                    // try again 
                    
                        fetch(uri, {
                            method: method,
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': token,
                            },
                        }).then(response => {
                            if (response.status !== 200) {
                                reject('api did not accept request, status: ' + response.status + ' statusText: ' + response.statusText)
                            }
                            else {
                                response.json().then(data => resolve(data))
                            }
                        })
                    
                }).catch(error => console.warn('caught error: ', error))
            }

            if (method.toLowerCase() === 'get' && token === '') {

                fetch(uri).then(response => {

                    if (response.status !== 200) {
                        // try again 
                       
                            response = fetch(uri).then(response => {
                                if (response.status !== 200) {
                                    reject('api did not accept request, status: ' + response.status + ' statusText: ' + response.statusText)
                                }
                                else {
                                    response.json().then(data => resolve(data))
                                }
                            })
                       
                    }
                    else {
                        response.json().then(data => resolve(data))
                    }
                }, error => {
                    // try again 
                    
                        fetch(uri).then(response => {
                            if (response.status !== 200) {
                                reject('api did not accept request, status: ' + response.status + ' statusText: ' + response.statusText)
                            }
                            else {
                                response.json().then(data => resolve(data))
                            }
                        })
                   
                }).catch(error => console.warn('caught error: ', error))

            }
        })
    }

}

export default ApiConnector;
const initialState = {
    lastSheetDisplayWidth: -1,
    storageUsage: { usage: -1, quota: -1 },
    counterRedrawn: null,
    counterSideActive: {active: 'front', front: '{}', rear: '{}'},
    sheetSideActive: 'rear',
    threeDeeViewActive: false,
    counterLoadFromSheet: null,
    counterLoadFromApp: null,
    counterLoadFromFile: null,
    loadSheet: { sheetSettings: null, slots: null },
    windowResize: { width: -1, height: -1 },
    counterAreaMenuState: '',
    duplicatedLayerValuesSetup: null,
    topMenuView: null,
    scrollTop: 0,
    scrollLeft: 0,
    layersOpen: [],
    layoutInline: false,
    restoreCounterState: false,
    browser: '',
    os: '',
    help: false,
    popupInfoActive: false,
    restoreDatabase: false,
    importDatabase: null,
    processImportDatabase: null,
    restoreWorkspace: false,
    sheetPaper: null,
    loadCounter: null,
    loadCounterProcessing: null,
    blur: null,
    focus: null,
    layers: [],
    svgs: [],
    sheetSettings: {},
    dexie: null,
    fonts: [],
    mouseDown: [],
    mouseOutCounterArea: true,
    mouseUp: [],
    mouseClick: [],
    mouseDblClick: [],
    returnKey: [],
    keyUp: [],
    windowWidthHeight: { width: 0, height: 0 },
    counterDrawWidth: 0,
    errorMessages: [],
    colorPicker: { x: -1, y: -1, hexColor: null },
    overlay: false,
    overlayClick: null,
    dataDisplay: null,
    installView: '',

    savedCounters: {},
    savedSheets: {},

    activeLayerValues: {},
    activeLayerValuesFront: {},
    activeLayerValuesRear: {},
    inputs: [],


    draggingLayer: -1,
    duplicateLayers: [],
    moveCounterToSheet: null,
    cloneCounterToSlot: null,

    slots: [],
    counterClear: false,
    sheetSave: null,
    sheetSaved: null,
    activateLayer: { layerKey: null, active: false },
};
const types = {
    LAST_SHEET_DISPLAY_WIDTH: "LAST_SHEET_DISPLAY_WIDTH",
    STORAGE_USAGE: "STORAGE_USAGE",
    COUNTER_REDRAWN: "COUNTER_REDRAWN",
    COUNTER_SIDE_ACTIVE: "COUNTER_SIDE_ACTIVE",
    SHEET_SIDE_ACTIVE: "SHEET_SIDE_ACTIVE",
    THREE_DEE_VIEW_ACTIVE: "THREE_DEE_VIEW_ACTIVE",
    COUNTER_LOAD_FROM_SHEET: "COUNTER_LOAD_FROM_SHEET",
    COUNTER_LOAD_FROM_APP: "COUNTER_LOAD_FROM_APP",
    COUNTER_LOAD_FROM_FILE: "COUNTER_LOAD_FROM_FILE",
    LOAD_SHEET: "LOAD_SHEET",
    WINDOW_RESIZE: "WINDOW_RESIZE",
    COUNTER_AREA_MENU_STATE: "COUNTER_AREA_MENU_STATE",
    LAYERS_NO_REACT: "LAYERS_NO_REACT",
    LAYERS_NO_REACT_RESET: "LAYERS_NO_REACT_RESET",
    DUPLICATED_LAYER_VALUES_SETUP: "DUPLICATED_LAYER_VALUES_SETUP",
    LAYERS_REORDERED: "LAYERS_REORDERED",
    TOP_MENU_VIEW: "TOP_MENU_VIEW",
    SCROLL_TOP: "SCROLL_TOP",
    LAYERS_OPEN: "LAYERS_OPEN",
    LAYOUT_INLINE: "LAYOUT_INLINE",
    RESTORE_COUNTER_STATE: "RESTORE_COUNTER_STATE",
    BROWSER: "BROWSER",
    OS: "OS",
    HELP: "HELP",
    POPUP_INFO_ACTIVE: "POPUP_INFO_ACTIVE",
    RESTORE_DATABASE: "RESTORE_DATABASE",
    IMPORT_DATABASE: "IMPORT DATABASE",
    PROCESS_IMPORT_DATABASE: "PROCESS_IMPORT_DATABASE",
    RESTORE_WORKSPACE: "RESTORE_WORKSPACE",
    SHEET_PAPER: "SHEET_PAPER",
    PAUSE_STATE_EFFECTS: "PAUSE_STATE_EFFECTS",
    LOAD_COUNTER: "LOAD_COUNTER",
    LOAD_COUNTER_PROCESSING: "LOAD_COUNTER_PROCESSING",
    BLUR: "BLUR",
    FOCUS: "FOCUS",
    MOUSE_DOWN: "MOUSE_DOWN",
    MOUSE_OUT_COUNTER_AREA: "MOUSE_OUT_COUNTER_AREA",
    MOUSE_UP: "MOUSE_UP",
    MOUSE_CLICK: "MOUSE_CLICK",
    MOUSE_DOUBLE_CLICK: "MOUSE_DOUBLE_CLICK",
    RETURN_KEY: "RETURN_KEY",
    KEY_UP: "KEY_UP",
    WINDOW_WIDTH_HEIGHT: "WINDOW_WIDTH_HEIGHT",
    COUNTER_DRAW_WIDTH: "COUNTER_DRAW_WIDTH",
    ERROR_MESSAGES: "ERROR_MESSAGES",
    CLEAR_ERROR_MESSAGES: "CLEAR_ERROR_MESSAGES",
    COUNTER_MENU_LAYER_OPTION: "COUNTER_MENU_LAYER_OPTION",
    COLOR_PICKER: "COLOR_PICKER",
    OVERLAY: "OVERLAY",
    OVERLAY_CLICK: "OVERLAY_CLICK",
    DATA_DISPLAY: "DATA_DISPLAY",
    INSTALL_VIEW: "INSTALL_VIEW",
    LAYERS: "LAYERS",
    ADD_LAYER: "ADD_LAYER",
    LAYERS_ORDER: "LAYERS_ORDER",
    SAVED_COUNTERS: "SAVED_COUNTERS",
    SAVED_COUNTERS_REMOVE: "SAVED_COUNTERS_REMOVE",
    SAVED_SHEETS: "SAVED_SHEETS",
    SAVED_SHEETS_REMOVE: "SAVED_SHEETS_REMOVE",
    LAYER_UPDATE: "LAYER_UPDATE",
    INPUT_VALUES: "INPUT_VALUES",
    INPUT_VALUES_REMOVE: "INPUT_VALUES_REMOVE",
    ACTIVE_LAYERS: "ACTIVE_LAYERS",
    ACTIVE_LAYER_VALUES: "ACTIVE_LAYER_VALUES",
    ACTIVE_LAYER_VALUES_FRONT: "ACTIVE_LAYER_VALUES_FRONT",
    ACTIVE_LAYER_VALUES_REAR: "ACTIVE_LAYER_VALUES_REAR",
    ACTIVE_LAYER_VALUES_REMOVE: "ACTIVE_LAYER_VALUES_REMOVE",
    ACTIVE_LAYER_VALUES_RESET: "ACTIVE_LAYER_VALUES_RESET",
    INPUT_TYPES: "INPUT_TYPES",
    CHANGED_INPUT_VALUES: "CHANGED_INPUT_VALUES",
    CURRENT_INPUT_VALUES: "CURRENT_INPUT_VALUES",
    INPUTS: "INPUTS",
    SVGS: "SVGS",
    SVGS_ADD: "SVGS_ADD",
    SVGS_REMOVE: "SVGS_REMOVE",
    FONTS: "FONTS",
    DEXIE: "DEXIE",
    DRAGGING_LAYER: "DRAGGING_LAYER",
    DUPLICATE_LAYERS: "DUPLICATE_LAYERS",
    MOVE_COUNTER_TO_SHEET: "MOVE_COUNTER_TO_SHEET",
    CLONE_COUNTER_TO_SLOT: "CLONE_COUNTER_TO_SLOT",
    SHEET_SETTINGS: "SHEET_SETTINGS",
    SLOTS: "SLOTS",
    COUNTER_CLEAR: "COUNTER_CLEAR",
    SHEET_SAVE: "SHEET_SAVE",
    SHEET_SAVED: "SHEET_SAVED",
    LOAD_LAYERS_TO_ACTIVE: "LOAD_LAYERS_TO_ACTIVE",
    ACTIVATE_LAYER: "ACTIVATE_LAYER",
    USER_LOAD_SLOTS_SHEET: "USER_LOAD_SLOTS_SHEET",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LAST_SHEET_DISPLAY_WIDTH:
            return {
                ...state,
                lastSheetDisplayWidth: action.payload
            }
        case types.STORAGE_USAGE:
            return {
                ...state,
                storageUsage: action.payload
            }
        case types.COUNTER_REDRAWN:
            return {
                ...state,
                counterRedrawn: action.payload
            }
        case types.COUNTER_SIDE_ACTIVE:
            return {
                ...state,
                counterSideActive: action.payload
            }
        case types.SHEET_SIDE_ACTIVE:
            return {
                ...state,
                sheetSideActive: action.payload
            }
        case types.THREE_DEE_VIEW_ACTIVE:
            return {
                ...state,
                threeDeeViewActive: action.payload
            }
        case types.COUNTER_LOAD_FROM_SHEET:
            return {
                ...state,
                counterLoadFromSheet: action.payload
            }
        case types.COUNTER_LOAD_FROM_APP:
            return {
                ...state,
                counterLoadFromApp: action.payload
            }
        case types.COUNTER_LOAD_FROM_FILE:
            return {
                ...state,
                counterLoadFromFile: action.payload
            }
        case types.LOAD_SHEET:
            return {
                ...state,
                loadSheet: action.payload
            }
        case types.WINDOW_RESIZE:
            return {
                ...state,
                windowResize: action.payload
            }
        case types.COUNTER_AREA_MENU_STATE:
            return {
                ...state,
                counterAreaMenuState: action.payload
            }
        case types.LAYERS:
            return {
                ...state,
                layers: action.payload
            }
        case types.DUPLICATED_LAYER_VALUES_SETUP:
            return {
                ...state,
                duplicatedLayerValuesSetup: action.payload
            };
        case types.TOP_MENU_VIEW:
            return {
                ...state,
                topMenuView: action.payload
            };
        case types.SCROLL_TOP:
            return {
                ...state,
                scrollTop: action.payload
            };
        case types.SCROLL_LEFT:
            return {
                ...state,
                scrollLeft: action.payload
            };
        case types.LAYERS_OPEN:
            return {
                ...state,
                layersOpen: action.payload
            };
        case types.LAYOUT_INLINE:
            return {
                ...state,
                layoutInline: action.payload
            };
        case types.RESTORE_COUNTER_STATE:
            return {
                ...state,
                restoreCounterState: action.payload
            };
        case types.OS:
            return {
                ...state,
                os: action.payload
            };
        case types.BROWSER:
            return {
                ...state,
                browser: action.payload
            };
        case types.HELP:
            return {
                ...state,
                help: action.payload
            };
        case types.POPUP_INFO_ACTIVE:
            return {
                ...state,
                popupInfoActive: action.payload
            };
        case types.RESTORE_DATABASE:
            return {
                ...state,
                restoreDatabase: action.payload,
                layers: [],
                layersOpen: [],
                svgs: [],
                sheetSettings: null,
                slots: [],
                activeLayerValues: {},
                savedCounters: {},
                savedSheets: {},
                fonts: []
            };
        case types.IMPORT_DATABASE:
            return {
                ...state,
                importDatabase: action.payload
                // layers: action.payload.layers,
                // layersOpen: action.payload.layersOpen,
                // svgs: action.payload.svgs,
                // sheetSettings: action.payload.sheetSettings,
                // slots: action.payload.slots,
                // activeLayerValues: action.payload.activeLayerValues,
                // savedCounters: action.payload.savedCounters,
                // savedSheets: action.payload.savedSheets,
                // fonts: action.payload.fonts
            };
        case types.PROCESS_IMPORT_DATABASE:
            return {
                ...state,
                importDatabase: null,
                layers: action.payload.layers,
                layersOpen: action.payload.layersOpen,
                svgs: action.payload.svgs,
                sheetSettings: action.payload.sheetSettings,
                slots: action.payload.slots,
                activeLayerValues: action.payload.activeLayerValues,
                savedCounters: action.payload.savedCounters,
                savedSheets: action.payload.savedSheets,
                fonts: action.payload.fonts
            };
        case types.RESTORE_WORKSPACE:
            return {
                ...state,
                restoreWorkspace: action.payload
            };
        case types.DISABLE_INPUT:
            return {
                ...state,
                disableInput: action.payload
            };
        case types.SHEET_PAPER:
            return {
                ...state,
                sheetPaper: action.payload
            };
        case types.LOAD_COUNTER:
            return {
                ...state,
                loadCounter: action.payload
            };
        case types.LOAD_COUNTER_PROCESSING:
            return {
                ...state,
                loadCounterProcessing: action.payload
            };
        case types.BLUR:
            return {
                ...state,
                blur: action.payload
            };
        case types.FOCUS:
            return {
                ...state,
                focus: action.payload
            };
        case types.MOUSE_DOWN:
            return {
                ...state,
                mouseDown: action.payload
            };
        case types.MOUSE_OUT_COUNTER_AREA:
            return {
                ...state,
                mouseOutCounterArea: action.payload
            };
        case types.MOUSE_UP:
            return {
                ...state,
                mouseUp: action.payload
            };
        case types.MOUSE_CLICK:
            return {
                ...state,
                mouseClick: action.payload
            };
        case types.MOUSE_DOUBLE_CLICK:
            return {
                ...state,
                mouseDblClick: action.payload
            };
        case types.RETURN_KEY:
            return {
                ...state,
                returnKey: action.payload
            };
        case types.KEY_UP:
            return {
                ...state,
                keyUp: action.payload
            };
        case types.WINDOW_WIDTH_HEIGHT:
            return {
                ...state,
                windowWidthHeight: action.payload
            };
        case types.COUNTER_DRAW_WIDTH:
            return {
                ...state,
                counterDrawWidth: action.payload
            };
        case types.ERROR_MESSAGES:
            if (action.payload === '') {
                action.payload = []
            }
            if (typeof action.payload === 'string') {
                action.payload = [action.payload]
            }
            if (action.payload === null) {
                return {
                    ...state,
                    errorMessages: []
                };
            }
            let test = [...action.payload]
            return {
                ...state,
                errorMessages: [...state.errorMessages, ...action.payload]
            };
        case types.CLEAR_ERROR_MESSAGES:
            return {
                ...state,
                errorMessages: []
            };
        case types.COLOR_PICKER:
            return {
                ...state,
                colorPicker: action.payload
            };
        case types.OVERLAY:
            return {
                ...state,
                overlay: action.payload
            };
        case types.OVERLAY_CLICK:
            return {
                ...state,
                overlayClick: action.payload
            };
        case types.DATA_DISPLAY:
            return {
                ...state,
                dataDisplay: action.payload
            };
        case types.INSTALL_VIEW:
            return {
                ...state,
                installView: action.payload
            };
        case types.ADD_LAYER:
            return {
                ...state,
                layers: [...state.layers, action.payload]
            }
        case types.LAYERS_ORDER:
            return {
                ...state,
                layersOrder: [...action.payload]
            }
        case types.LAYER_UPDATE:
            let layers = [...state.layers]
            let _layers = layers.map(ly => {
                if (ly.layerKey === action.payload.layerKey) {
                    return action.payload
                }
                return ly
            })
            return {
                ...state,
                layers: [..._layers]
            }
        case types.SAVED_COUNTERS:
            return {
                ...state,
                savedCounters: { ...state.savedCounters, ...action.payload }
            }
        case types.SAVED_COUNTERS_REMOVE:
            let savedCountersClone = { ...state.savedCounters }
            delete savedCountersClone[action.payload]
            return {
                ...state,
                savedCounters: { ...savedCountersClone }
            }
        case types.SAVED_SHEETS:
            return {
                ...state,
                savedSheets: { ...state.savedSheets, ...action.payload }
            }
        case types.SAVED_SHEETS_REMOVE:
            let savedSheetsClone = { ...state.savedSheets }
            delete savedSheetsClone[action.payload]
            return {
                ...state,
                savedSheets: { ...savedSheetsClone }
            }
        case types.ACTIVE_LAYER_VALUES:
            return {
                ...state,
                activeLayerValues: { ...state.activeLayerValues, ...action.payload }
            }
        case types.ACTIVE_LAYER_VALUES_FRONT:
            return {
                ...state,
                activeLayerValuesFront: action.payload
            }
        case types.ACTIVE_LAYER_VALUES_REAR:
            return {
                ...state,
                activeLayerValuesRear: action.payload
            }
        case types.ACTIVE_LAYER_VALUES_REMOVE:
            let removeLayerKeys = []
            if (Array.isArray(action.payload)) {
                removeLayerKeys = action.payload
            }
            else {
                removeLayerKeys.push(action.payload)
            }

            // make sure they're numbers, not stringsconst release
            removeLayerKeys = removeLayerKeys.map(layerKey => Number(layerKey))

            let reducedActiveLayerValues = JSON.parse(JSON.stringify(state.activeLayerValues))

            // cant remove layer 1, the base layer
            removeLayerKeys = removeLayerKeys.filter(layerKey => layerKey !== 1)

            if (removeLayerKeys.length === 0) { // if none of the keys were actually in activeLayerValues
                return {
                    ...state,
                    activeLayerValues: reducedActiveLayerValues
                }
            }

            let combined_keys = Object.keys(state.activeLayerValues)

            removeLayerKeys.forEach(removeLayerKey => {
                let deleteList = combined_keys.filter(ck => {
                    return ck.startsWith(removeLayerKey + '_')
                })
                deleteList.forEach(removeKey => delete reducedActiveLayerValues[removeKey])
            })
            return {
                ...state,
                activeLayerValues: reducedActiveLayerValues
            }
        case types.ACTIVE_LAYER_VALUES_RESET:
            return {
                ...state,
                activeLayerValues: action.payload
            }
        case types.SVGS:
            return {
                ...state,
                svgs: action.payload
            }
        case types.SVGS_ADD:
            return {
                ...state,
                svgs: [...state.svgs, action.payload]
            }
        case types.SVGS_REMOVE:
            let _svgs = state.svgs.filter(svg => svg.svgKey !== action.payload.svgKey)
            return {
                ...state,
                svgs: _svgs
            }
        case types.FONTS:
            return {
                ...state,
                fonts: action.payload
            }
        case types.DEXIE:
            return {
                ...state,
                dexie: action.payload
            }
        case types.DRAGGING_LAYER:
            return {
                ...state,
                draggingLayer: action.payload
            }
        case types.DUPLICATE_LAYERS:
            return {
                ...state,
                duplicateLayers: action.payload
            }
        case types.MOVE_COUNTER_TO_SHEET:
            return {
                ...state,
                moveCounterToSheet: action.payload
            }
        case types.CLONE_COUNTER_TO_SLOT:
            return {
                ...state,
                cloneCounterToSlot: action.payload
            }
        case types.SHEET_SETTINGS:
            return {
                ...state,
                sheetSettings: { ...state.sheetSettings, ...action.payload }
            }
        case types.SLOTS:
            return {
                ...state,
                slots: action.payload
            }
        case types.COUNTER_CLEAR:
            return {
                ...state,
                counterClear: action.payload
            }
        case types.SHEET_SAVE:
            return {
                ...state,
                sheetSave: action.payload
            }
        case types.SHEET_SAVED:
            return {
                ...state,
                sheetSaved: action.payload
            }
        case types.ACTIVATE_LAYER:
            return {
                ...state,
                activateLayer: action.payload
            }
        default:
            throw new Error("Unexpected action")
    }
};
export { initialState, types, reducer }
import React, { useState, useEffect, useContext } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import LayerRenameForm from "../LayerRenameForm/LayerRenameForm"
import LayerDuplicateForm from "../LayerDuplicateForm/LayerDuplicateForm"
import './LayerOptions.scss'
const LayerOptions = ({ layer, closeOptions }) => {
    const { state, actions } = useContext(StoreContext)
    const signalRenameLayerOpen = useSignal(false)
    const signalDuplicateLayerOpen = useSignal(false)
    const signalOrderLayerOpen = useSignal(false)
    const [cannotDelete, setCannotDelete] = useState(false)

    const checkIfDeletable = () => {
        let ralv = state.counterSideActive.active === 'front' ? state.counterSideActive.rear : state.counterSideActive.front
        if (typeof ralv === 'string') {
            ralv = JSON.parse(ralv)
        }
        for (const [key] of Object.entries(ralv)) {
            if (key.startsWith(layer.layerKey + '_')) {
                return false
            }
        }

        return true
    }
    const renameLayerOpenChange = () => {
        signalRenameLayerOpen.value = !signalRenameLayerOpen.value
    }
    const duplicateLayerOpenChange = () => {
        signalDuplicateLayerOpen.value = !signalDuplicateLayerOpen.value
    }
    const orderLayerOpenChange = () => {
        signalOrderLayerOpen.value = !signalOrderLayerOpen.value
    }
    const deleteLayer = () => {
        // delete any activeLayerValues for this layer, if its active.
        if (checkIfDeletable()) {
            if (Utility.layerIsActive(layer.layerKey, state.activeLayerValues) && layer.layerKey !== 1) {
                actions.activeLayerValuesRemove(layer.layerKey)
            }

            // // delete layer
            let _layers = [...state.layers]

            _layers = _layers.filter(ly => ly.layerKey !== layer.layerKey)
            actions.layers(_layers)
        }
        else {
            setCannotDelete(true)
        }
    }

    return (
        <div className="layer-options">
            <div className="options">
                <div className={signalDuplicateLayerOpen.value || signalRenameLayerOpen.v ? 'option opened' : 'option'}>

                    <div className={layer.parentLayerKey === -1 ? 'display-none' : 'option-clicker'}><div onClick={renameLayerOpenChange}>rename <span>{layer.layerName}</span></div></div>
                    {signalRenameLayerOpen.value ?
                        <LayerRenameForm layer={layer} closeMessage={renameLayerOpenChange} closeOptions={closeOptions} />
                        : null}

                    <div className="option-clicker"><div onClick={duplicateLayerOpenChange}>duplicate <span>{layer.layerName}</span></div></div>
                    {signalDuplicateLayerOpen.value ?
                        <LayerDuplicateForm layer={layer} closeMessage={duplicateLayerOpenChange} closeOptions={closeOptions} />
                        : null}

                    <div className={layer.parentLayerKey === -1 ? 'display-none' : 'option-clicker'}>
                        <div onClick={deleteLayer}>delete <span>{layer.layerName}</span></div>
                    </div>

                </div>

                {/* {layer.parentLayerKey > -1 ?
                    <div className="option">
                        {cannotDelete ?
                            <div className="option-clicker disabled">
                                <div onClick={deleteLayer}>cannot delete <span>{layer.layerName}</span></div>
                            </div>
                            :
                            <div className="option-clicker">
                                <div onClick={deleteLayer}>delete <span>{layer.layerName}</span></div>
                            </div>
                        }
                    </div>
                    : null} */}
            </div>
        </div>
    )




}

export default LayerOptions
import React, { useContext, useState, useEffect } from 'react'
import { StoreContext } from "../../context/StoreContext"
import './MenuItems.scss'

const MenuItems = () => {
    const { state, actions } = useContext(StoreContext)
    const menus = ['App', 'SVGs', 'Images', 'Fonts', 'Saved Counters', 'Saved Sheets']
    const menuClick = evt => {
        const topMenuSelected = evt.currentTarget.id
        let activeTopMenu = state.topMenuView
        if (topMenuSelected) {
            if (activeTopMenu === topMenuSelected) {
                actions.topMenuView(null)
            }
            else {
                // we may need the width the of the sheet display, and opening the menu makes it go away.
                let sheetDisplayEle = document.getElementById('sheetDisplayContainer')
                if (sheetDisplayEle) {
                    const sheetBbox = sheetDisplayEle.getBoundingClientRect()
                    actions.lastSheetDisplayWidth(sheetBbox.width)
                }
                actions.topMenuView(topMenuSelected)
            }
        }
        else {
            actions.topMenuView(null)
        }
    }

    return <div className="menu-items">
        {menus.map((menu, index) => <div className={state.topMenuView === menu.toLowerCase() ? 'active' : ''} key={index} id={menu.toLowerCase()} onClick={menuClick}>{menu}</div>
        )}
    </div>
}
export default MenuItems;
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import LayerMenuItem from '../LayerMenuItem/LayerMenuItem'
import { StoreContext } from "../../context/StoreContext"
import './LayersMenu.scss'
const LayersMenu = ({ dragSubscribe }) => {
    const { state, actions } = useContext(StoreContext)
    const signalLayerEditing = useSignal(null)
    const layerItemRefs = useRef([]);
    const signalDragOver = useSignal(null)
    const signalBeingDraggedId = useSignal(null)
    const signalDropBboxes = useSignal([])
    const signalDraggingMenuLayer = useSignal(false)
    const signalOrderedLayersVersion = useSignal([]) // might need to put this back to useState format. The signal doesnt seem to be triggering in the html template.
    const [optionsOpenLayerKey, setOptionsOpenLayerKey] = useState(false)
    const [changedLayerOrders, setChangedLayerOrders] = useState(false)

    const clickedOnLayerMenuItem = layerKey => {
        signalLayerEditing.value = layerKey
    }

    const registerRef = ref => {
        if (ref) {
            let id = ref.id
            if (id) {
                if (layerItemRefs.current.indexOf(ref) === -1) {
                    layerItemRefs.current.push(ref)
                    connectDragger(ref)
                    return layerItemRefs.current[layerItemRefs.current.length]
                }
            }
        }
    }

    const setupDropBboxes = () => {
        let bbox = null
        let ele = null
        let _dropBboxes = JSON.parse(JSON.stringify(signalDropBboxes.value))
        layerItemRefs.current.forEach(ref => {
            if (ref.id) {
                ele = document.getElementById(ref.id)
                if (ele) {
                    bbox = ele.getBoundingClientRect()
                    if (bbox) {
                        let drop = _dropBboxes.find(bp => bp.id === ref.id)
                        if (drop) {
                            drop.bbox = bbox
                        }
                        else {
                            _dropBboxes.push({ id: ref.id, bbox: bbox })
                        }
                    }
                }
            }
        })
        signalDropBboxes.value = _dropBboxes
    }

    // useEffect(() => {
    //     if (refsRegisterComplete) {
    //         connectDraggers()
    //     }
    // }, [refsRegisterComplete])

    const rectOverRect = (dragbox, dropbox) => {
        return (pointInsideBox({ x: dragbox.x, y: dragbox.y }, dropbox) ||
            pointInsideBox({ x: dragbox.x + dragbox.width, y: dragbox.y }, dropbox) ||
            pointInsideBox({ x: dragbox.x + dragbox.width, y: dragbox.y + dragbox.height }, dropbox) ||
            pointInsideBox({ x: dragbox.x, y: dragbox.y + dragbox.height }, dropbox)

            // ||

            // pointInsideBox({ x: dragbox.x, y: dragbox.y + (dragbox.height / 2) }, dropbox) ||
            // pointInsideBox({ x: dragbox.x + (dragbox.width / 2), y: dragbox.y }, dropbox) ||
            // pointInsideBox({ x: dragbox.x + dragbox.width, y: dragbox.y + (dragbox.height / 2) }, dropbox) ||
            // pointInsideBox({ x: dragbox.x + (dragbox.width / 2), y: dragbox.y + dragbox.height }, dropbox)
        )
    }

    const pointInsideBox = (p, box) => {
        return (p.x >= box.x && p.x <= (box.x + box.width) &&
            p.y >= box.y && p.y <= (box.y + box.height))
    }

    const checkHoverOverDropArea = dragbox => {
        let i = 0
        let dropbox = null

        for (i; i < signalDropBboxes.value.length; i++) {
            dropbox = signalDropBboxes.value[i]
            if (dropbox && dropbox.id && dropbox.bbox) {
                if (rectOverRect(dragbox, dropbox.bbox)) {
                    return dropbox.id
                }
            }
        }

        return null
    }

    const changeOrder = (fromLayerId, toLayerId) => {
        if (fromLayerId && toLayerId) {
            let droppedLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === toLayerId)
            let draggedLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === fromLayerId)
            if (droppedLayer && draggedLayer) {
                draggedLayer.layerOrder = droppedLayer.layerOrder + 0.5
            }

            //sort and assign proper order numbers
            let newLayersOrder = signalOrderedLayersVersion.value.sort((a, b) => a.layerOrder - b.layerOrder)
            newLayersOrder.forEach((ly, index) => {
                ly.layerOrder = index
            })
            setChangedLayerOrders(true)
            signalOrderedLayersVersion.value = [...newLayersOrder]
            assignZindexes()
            actions.layers([...newLayersOrder])
        }
    }

    useEffect(() => {
        let _stateLayers = [...state.layers]
        if (_stateLayers.length > 0) {
            _stateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            signalOrderedLayersVersion.value = _stateLayers
        }
        if( changedLayerOrders ) {
            setChangedLayerOrders(false)
            setTimeout(() => {
                actions.activeLayerValuesReset({...state.activeLayerValues})
            }, 100)
        }
    }, [state.layers])

    const assignZindexes = () => {
        signalOrderedLayersVersion.v.forEach(so => {
            let layerKey = so.layerKey
            let layerOrder = so.layerOrder
            let layerId = 'drawLayer_' + layerKey
            let divEle = document.getElementById(layerId)
            if (divEle) {
                divEle.style.zIndex = layerOrder
            }
        })
    }

    const connectDragger = ref => {
        let startX = -1
        let startY = -1
        let grabOffsetX = -1
        let grabOffsetY = -1
        let dragme = ref
        let dragmeBbox = null
        let lastDropId = -1
        let lastY = -1

        dragme.addEventListener("dragstart", (event) => {
            signalDraggingMenuLayer.value = true
            lastY = -1
            let id = dragme.id.replace('layer_menu_', '')
            id = Number(id)
            startX = -1
            startY = -1
            grabOffsetX = -1
            grabOffsetY = -1
            let dragmeEle = document.getElementById(dragme.id)
            dragmeBbox = dragmeEle.getBoundingClientRect()
            if (dragmeBbox && id) {
                signalBeingDraggedId.value = Number(id)
                startX = event.pageX
                startY = event.pageY
                grabOffsetX = startX - dragmeBbox.x
                grabOffsetY = startY - dragmeBbox.y
            }

            setupDropBboxes()

        });

        dragme.addEventListener("drag", (event) => {
            // drag grab point will be some point in the dragger div
            let x = event.pageX
            let y = event.pageY
            if (x === 0 && y === 0) {
                return
            }

            // wait till they slow down, otherwise it triggers "drop here" boxes too fast.
            if (lastY !== y) {
                if (Math.abs(lastY - y) > 20) {
                    lastY = y
                    return
                }
            }
            lastY = y
            let checkBox = {
                x: x - grabOffsetX,
                y: y - grabOffsetY,
                width: dragmeBbox.width,
                height: dragmeBbox.height
            }
            let dropId = checkHoverOverDropArea(checkBox)
            if (dropId === null) {
                return
            }
            if (dropId === lastDropId) {
                return
            }
            lastDropId = dropId

            if (dropId) {
                let overId = dropId.replace('layer_menu_', '')
                overId = Number(overId)
                if (overId) {
                    // we dont want drop to activate if it would result in no change inorder (ie the layer directly above the dragged layer).
                    let draggingLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === signalBeingDraggedId.value)
                    let overLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === overId)
                    if (draggingLayer.layerOrder - overLayer.layerOrder === 1) {
                        // the minus one is because of the algorithym that adds 0.5 for preparation for ordering. A little counter-intuitive.
                        signalDragOver.value = null
                    }
                    else {
                        signalDragOver.value = overId
                    }
                }
            }
            else {
                signalDragOver.value = null
            }
        });

        dragme.addEventListener("dragend", (event) => {
            signalDraggingMenuLayer.value = false
            if (signalBeingDraggedId.value && signalDragOver.value) {
                changeOrder(signalBeingDraggedId.value, signalDragOver.value)
            }
            signalBeingDraggedId.value = null
            signalDragOver.value = null
            setupDropBboxes()
        });
    }

    const optionsOpen = openLayerKey => {
       setOptionsOpenLayerKey(openLayerKey)
    }

    return (
        <div className="layers-menu" style={{ pointerEvents: state.overlay ? "none" : "" }}>
            {signalOrderedLayersVersion.value.filter(lyf => lyf.layerHidden === 0)
                .map(ly => <div
                    className={
                        signalBeingDraggedId.value === ly.layerKey ? 'layer-dragging' : (signalDragOver.value === ly.layerKey ? 'drop-active' : '')
                    }
                    key={ly.layerKey} >
                    <LayerMenuItem layer={ly}
                        registerRef={registerRef}
                        editingLayer={signalLayerEditing.value}
                        dragSubscribe={dragSubscribe}
                        clickRegister={clickedOnLayerMenuItem}
                        draggingMenuLayer={signalDraggingMenuLayer.value}
                        optionsOpen={optionsOpen}
                        activeOptionsOpen={optionsOpenLayerKey}
                    />

                </div>)}
        </div>
    )
}
export default LayersMenu
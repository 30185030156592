import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import CloseX from "../CloseX/CloseX"
import './ErrorMessages.scss'

const ErrorMessages = () => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state, actions } = useContext(StoreContext)
    const [position, setPosition] = useState([-1111, -1111])
    const [zIndex, setZIndex] = useState(0)
    const [title] = useState('System notification')
    const errorMessagesRef = useRef(null)
    const dragRef = useRef(null)
    const [active, setActive] = useState(false)

    useEffect(() => {
        errorMessagesRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        }, { signal });
    }, [])

    useEffect(() => {
        if (state.errorMessages) {
            actions.overlay(true)
            setZIndex(9999)
            positionBox()
            setupDrag()
        }
    }, [state.errorMessages])

    const setupDrag = () => {
        function dragElement(elmnt) {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            elmnt.onmousedown = dragMouseDown;
            elmnt.onmouseup = closeDragElement;

            function dragMouseDown(e) {
                let dragger = e.target.getAttribute('data-dragger')
                if (!dragger) {
                    return
                }
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            }

            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }

        dragElement(errorMessagesRef.current)
    }

    const positionBox = () => {
        let x = window.scrollX //state.scrollLeft
        let y = window.scrollY //state.scrollTop
        let bbox = errorMessagesRef.current.getBoundingClientRect()
        let width = bbox.width
        let windowWidth = window.innerWidth
        let middleOfWindow = windowWidth / 2
        let distance = middleOfWindow - (width / 2)
        x += distance
        setPosition([x, y])
        errorMessagesRef.current.className = "error-messages fade-in";
    }

    useEffect(() => {
        if (state.overlay && state.errorMessages.length > 0) {
            setActive(true)
        }
        if (state.overlay === false && state.errorMessages.length > 0 && active) {
            setActive(false)
            actions.clearErrorMessages()
        }
    }, [state.overlay])

    const close = () => {
        actions.clearErrorMessages()
        actions.overlay(false)
        setActive(false)
    }

    return (
        <div ref={errorMessagesRef} id="errorMessages" className="error-messages" style={{ left: position[0] + 'px', top: position[1] + 'px', zIndex: zIndex }}>
            <div className="container">
                <div className="top">
                    <div>{title}</div>
                    <div ref={dragRef} data-dragger="drag" />
                    <div className="close"><CloseX close={close} /></div>
                </div>

                <div className="content-area">
                    {state.errorMessages.map((message, index) => (<div key={index}>
                        <div className="message-comment">{message.comment}</div>
                        {message.lineItems.map((item, index2) => <div key={index2}>{item}</div>)}
                    </div>))}
                </div>

            </div>
        </div>
    )
}

export default ErrorMessages
import React, { useState, useEffect, useContext } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './InstallCustomImage.scss'

const InstallCustomImage = ({ close, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const signalPaperTarget = useSignal(null)
    const [imgNameWarning, setImgNameWarning] = useState(false)
    const [submitErrorMessage, setSubmitErrorMessage] = useState('')
    const [imgLoadedResult, setImgLoadedResult] = useState(null)
    const [imgLoadError, setImgLoadError] = useState('')
    const [svgSuccessfullyInstalled, setSvgSuccessfullyInstalled] = useState(false)
    const signalDragEnter = useSignal(false)
    const [addingSvg, setAddingSvg] = useState(null)
    const signalOrigImgName = useSignal('')
    const signalImgName = useSignal('')
    const signalSvgId = useSignal('')
    const signalPrependText = useSignal('')
    const signalFinalSvgForInstalling = useSignal('')

    useEffect(() => {
        setupDragDropArea()
        return () => {
            if (signalPaperTarget.value) {
                signalPaperTarget.value.clear()
            }
        };
    }, [])

    const deploySvg = async (svgString, id) => {
        if (signalPaperTarget.value) {
            signalPaperTarget.value.clear()
        }
        else {
            signalPaperTarget.value = Snap("#svg_target");
        }

        if (signalPaperTarget.value === null) {
            setImgLoadError('Could not connect to the drop container!')
            return
        }

        let parsed = Snap.parse(svgString)
        if (parsed) {
            signalPaperTarget.value.append(parsed)
            setImgLoadedResult(true)
            var svgSource = signalPaperTarget.value.toString()
            svgSource = svgSource.replace(' id="svg_target" ', ' id="' + id + '" ')
            signalFinalSvgForInstalling.value = svgSource
        }
        else {
            setImgLoadError('Could not read the image data.')
        }
    }

    const constructSvgWithImgData = (imageBase64) => {
        let svgString = `<image width="100%" height="100%" xlink:href="${imageBase64}" />`
        let id = signalImgName.value
        id = Utility.safeSvgId(id)
        signalSvgId.value = id
        deploySvg(svgString, id)
    }

    const fileNameCleanup = name => {
        let fileName = name.trim()
        fileName = fileName.replace(/\s+/g, '_') // remove spaces, add in underscores
        fileName = fileName.replace(/.jpg/ig, '')
        fileName = fileName.replace(/.jpeg/ig, '')
        fileName = fileName.replace(/.png/ig, '')
        fileName = fileName.replace(/.avif/ig, '')
        fileName = fileName.replace(/.webp/ig, '')
        fileName = fileName.replace(/\s+/g, '_') // remove spaces, add in underscores

        return fileName
    }

    const loadFromFileOnChange = evt => {
        onTryAgain()
        var file = evt.target.files[0]
        processImageLoad(file)
    }

    const loadFromDragDrop = file => {
        onTryAgain()
        processImageLoad(file)
    }

    const processImageLoad = file => {
        if (!file) {
            setImgLoadError('Image load error.')
            return
        }
        setImgLoadError('')
        signalOrigImgName.value = file.name
        let imageBase64 = ''
        if (file.type !== 'image/jpeg' &&
            file.type !== 'image/png' &&
            file.type !== 'image/webp' &&
            file.type !== 'image/avif') {
            setImgLoadError('Only jpeg, png, avif and webp images can be installed here.')
            return
        }

        let fileName = fileNameCleanup(file.name)
        fileName = fileName.length > 28 ? fileName.substring(0, 28) : fileName
        // since we may be removing characters, gotta check to make sure the id isn't too ridiculously short.
        if (fileName.length < 2) {
            fileName += Utility.randomString(3)
        }

        if (filenameCheck(fileName) === false) {
            setImgLoadError('Image load error.')
            return
        }

        signalImgName.value = fileName
        
        var reader = new FileReader(); // Creating reader instance from FileReader() API
        reader.addEventListener("load", function () { // Setting up base64 URL on image
            imageBase64 = reader.result;
            constructSvgWithImgData(imageBase64)
        }, false);
        reader.addEventListener("error", evt => {
            setImgLoadError('Image load error.')
            console.error("Image load error:", evt)
        })
        reader.readAsDataURL(file); // Converting file into data URL
    }

    const filenameCheck = filename => {
        if (!filename) {
            return false
        }
        if (filename) {
            if (Utility.checkNameIsAvailable(state.svgs, filename)) {
                setImgNameWarning(false)
                setSubmitErrorMessage('')
            }
            else {
                setImgNameWarning(true)
                setSubmitErrorMessage('name is already taken')
            }
        }
        else {
            setImgNameWarning(false)
            setSubmitErrorMessage('')
        }
        return true
    }

    useEffect(() => {
        if (addingSvg) {
            actions.svgsAdd(addingSvg)
        }
    }, [addingSvg])

    useEffect(() => {
        if (state.svgs && addingSvg) {
            let lastSvg = state.svgs[state.svgs.length - 1]
            let newKey = lastSvg.svgKey
            if( newKey === addingSvg.svgKey ) {
                setSvgSuccessfullyInstalled(true)

                // set layer to visible if it is hidden
                let layers = [...state.layers]
    
                let parentCustomSvgKey = layers.find(sly => sly.layerName === 'custom images' ? sly.layerKey : null).layerKey
                let customSvgLayers = layers.filter(sly => sly.layerKey === parentCustomSvgKey || sly.parentLayerKey === parentCustomSvgKey)
               
               
                customSvgLayers.forEach(cvl => {
                    cvl.layerHidden = 0
                    // add svgKey to layer inputs
                    cvl.inputs = JSON.parse(JSON.stringify(cvl.inputs))
                    let input = cvl.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (input.list.includes(newKey) === false) {
                            input.list.push(newKey)
                            // we need to change ref of inputs so that 
                            // layerMenuItem knows it was updated.
                            cvl.inputs = JSON.parse(JSON.stringify(cvl.inputs))
                        }
                    }
                })
                
                updateSvgCount()
                actions.layers(layers)
            }
            else {
                setImgLoadError('Image was not correctly installed!')
            }
            setAddingSvg(null)
        }
    }, [state.svgs])

    const updateSvgCount = () => {
        const customSvgsLayer = state.layers.find(csl => csl.layerName === 'custom images')

        if (customSvgsLayer) {
            let input = customSvgsLayer.inputs.find(csl => csl.named === 'svgKey')
            let _svgs = state.svgs.filter(svg => input.list.includes(svg.svgKey))

            if (_svgs.length === 1) {
                optionalInfo('There is 1 image')
            }
            if (_svgs.length > 1) {
                optionalInfo('There are ' + _svgs.length + ' images')
            }
        }
    }


    const onTryAgain = () => {
        if (signalPaperTarget.value) {
            signalPaperTarget.value.clear()
        }
        signalFinalSvgForInstalling.value = ''
        signalImgName.value = ''
        signalOrigImgName.value = ''
        setImgLoadedResult(null)
        setSvgSuccessfullyInstalled(false)
        setImgNameWarning(false)
        setSubmitErrorMessage('')
        setImgLoadError('')
    }

    const handleDragEnter = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        if (signalDragEnter.value === false) {
            signalDragEnter.value = true
        }
    }
    const handleDragLeave = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        signalDragEnter.value = false
    }

    useEffect(() => {
        signalDragEnter.value = false
    }, [state.mouseUp])

    const handleDragOver = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        if (signalDragEnter.value === false) {
            signalDragEnter.value = true
        }
    }
    const handleDrop = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        loadFromDragDrop(evt.dataTransfer.files[0])
        signalDragEnter.value = false
    }
    const setupDragDropArea = () => {
        let dropArea = document.getElementById('dropArea')
        dropArea.addEventListener('dragenter', handleDragEnter, false)
        dropArea.addEventListener('dragleave', handleDragLeave, false)
        dropArea.addEventListener('dragover', handleDragOver, false)
        dropArea.addEventListener('drop', handleDrop, false)
    }

    const onChangeImgName = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        signalImgName.value = evt.target.value
        setSubmitErrorMessage('')
        setImgNameWarning(false)
    }

    // const onKeyDownName = evt => {
    //     if( evt.key === 'Enter' || evt.keyCode === 13 ) {
    //         signalImgName.value = evt.target.value
    //         setSubmitErrorMessage('')
    //         setImgNameWarning(false)
    //     }
    // }

    const installSvg = evt => {
        evt.preventDefault()
        if (!signalFinalSvgForInstalling.value) {
            return
        }
        if (!signalImgName.value) {
            return
        }

        let checkedName = signalImgName.value.trim().replace( /\s\s+/g, ' ')

        if (!Utility.checkNameIsAvailable(state.svgs, checkedName)) {
            setImgNameWarning(true)
            setSubmitErrorMessage('name is already taken')
            return
        }

        if (!Utility.checkNameIsAvailable(state.svgs, checkedName)) {
            setImgNameWarning(true)
            setSubmitErrorMessage('name is already taken')
            return
        }

        let regexResult = checkedName.match(/[-_)( a-zA-Z0-9]+$/)
        if (regexResult === null) {
            setImgNameWarning(true)
            setSubmitErrorMessage('name ' + checkedName + ' has invalid characters. Only alpha-numeric, spaces, and - _ ) (')
            return
        }
        if( regexResult && regexResult.index > 0 ) {
            setImgNameWarning(true)
            setSubmitErrorMessage('name ' + checkedName + ' has has invalid characters. Only alpha-numeric, spaces, and - _ ) (')
            return
        }
        

        if (checkedName.length < 2) {
            setImgNameWarning(true)
            setSubmitErrorMessage('please supply a name of at least 2 characters.')
            return
        }

        setImgNameWarning(false)
        setSubmitErrorMessage('')
        
        let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey;
        useKey = Number(useKey)
        if (useKey < 2000) {
            useKey = 2000
        }
        else {
            useKey = useKey + 1
        }

        let svgItem = {
            svgKey: useKey,
            svgCode: signalFinalSvgForInstalling.value,
            svgName: checkedName
        }

        setAddingSvg(svgItem)
    }

    return (
        <div className="install-custom-img">
            <div className={imgLoadedResult === null ? 'svg-container' : 'svg-container svg-loaded'}>

                <div className="svg-target">
                    <svg id="svg_target" width="100%" height="100%"></svg>
                </div>

                <div className={signalDragEnter.value ? 'drop-text drag-enter' : 'drop-text'}>
                    <div className={imgLoadedResult ? 'display-none' : ''}>
                        <span>Drop</span>
                        <span>IMAGE</span>
                        <span>here</span>
                    </div>
                </div>

                <div id="dropArea" />

            </div>
            {svgSuccessfullyInstalled ?
                <div className="success">
                    <div className="success-text">image file <span>{signalImgName.value}</span> has been successfully installed into the Custom Images menu.</div>
                    <div className="success-buttons">
                        <button className="standard-button green" onClick={onTryAgain}>Add another</button>
                        <button className="standard-button red" onClick={close}>Exit</button>
                    </div>
                </div>
                :
                <div className="form-area">
                    <div className="info">
                        <p>
                            You can install images into the app through this form.
                        </p>
                        <p>
                            After you successfully install an image, it will be selectable
                            in the "Custom Images" menu option.
                        </p>
                    </div>

                    {imgLoadedResult === null ?
                       <div className="form">
                            <div className="choose">Choose a jpg, png, avif, or webp image file from your device -</div>
                            <div><input type="file" id="installImgFile" accept=".jpg,.png,.avif,.webp"
                                onChange={loadFromFileOnChange} /></div>
                            <div className="close">
                                <button className="standard-button red" onClick={close}>Cancel</button>
                            </div>
                            {imgLoadError ? <div className="load-error">{imgLoadError}</div> : null}
                      </div>
                        :
                    <div className="form">
                            <div className="line-1">loaded : <span>{signalOrigImgName.value}</span>
                            { signalImgName.value ? 
                                <div className="new-name">
                                    new name : <span>{signalImgName.value.trim()}</span>
                                </div> : '' }
                            </div>
                            <div className="line-2">
                                <div>name:</div>
                                <input id="nameField" spellCheck="false" type="text" className={imgNameWarning ? 'warning' : ''} value={signalImgName.value} onChange={onChangeImgName} />
                                <button className={imgNameWarning || signalImgName.value.length === 0 ? 'standard-button disabled' :
                                                   'standard-button blue'} 
                                                   onClick={imgNameWarning ? null : installSvg}>Install</button>
                            </div>
                            <div className="line-3">{submitErrorMessage}</div>
                            <div className="line-4">
                                <button className="standard-button green" onClick={onTryAgain}>Try again</button>
                                <button className="standard-button red" onClick={close}>Exit</button>
                            </div>
                      </div>
                    }
                </div>
            }
        </div>
    );
}
export default InstallCustomImage

import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const Ww2Vehicles = {

    draw: (g, settings, svgs) => {
        let scale = 0
        let xpos = 0
        let ypos = 0
        let opacity = ''
        let rotation = 0
        let svgKey = ''
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'scale': derivedValues.scale = Utility.roundFloat(setting.value, 2)
                    break
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'line color': derivedValues.lineColor = setting.value
                    break
                case 'line thickness': derivedValues.lineThickness = Utility.roundFloat(setting.value / 10, 2)
                    break
                case 'fill color': derivedValues.fillColor = setting.value
                    break
                case 'svgKey': derivedValues.svgKey = parseInt(setting.value)
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                case 'flip horizontal': derivedValues.flipHorizontal = setting.value
                    break
                case 'spacer': break
                default: console.warn('we seem to have missed one: ', setting.name)
            }
        })
        scale = derivedValues.scale
        xpos = derivedValues.xpos
        ypos = derivedValues.ypos
        rotation = derivedValues.rotation
        svgKey = derivedValues.svgKey

        let fillColorObj = derivedValues.fillColor
        let fillInfo = ''
        if (fillColorObj.fillType === 'solid') {
            fillInfo = fillColorObj.fillColor
        }
        else { // gradient
            let type = derivedValues.fillColor.gradientDirection === 'radial' ? 'r' : 'l'
            let pxStart = derivedValues.fillColor.xyPositionStart.split(',')[0]
            let pyStart = derivedValues.fillColor.xyPositionStart.split(',')[1]
            let pxEnd = derivedValues.fillColor.xyPositionEnd.split(',')[0]
            let pyEnd = derivedValues.fillColor.xyPositionEnd.split(',')[1]
            let color1 = derivedValues.fillColor.colorStart
            let color2 = derivedValues.fillColor.colorMid
            let color3 = derivedValues.fillColor.colorEnd
            let defg = `${type}(${pxStart}, ${pyStart}, ${pxEnd}, ${pyEnd})${color1}-${color2}-${color3}`
            let parent = g.parent()
            var cg = parent.gradient(defg);
            fillInfo = cg
        }
        let svgObj = svgs.find(svg => svg.svgKey === svgKey)
        if (!svgObj) {
            console.warn('could not find custom svgs svgKey : ', svgKey)
            return
        }
        let svgCode = svgObj.svgCode
        opacity = derivedValues.opacity
        let tadjusts = [0, 0]
        if (svgCode) {
            tadjusts = Ww2Vehicles.drawSvg(g, svgCode)

            let paths = g.selectAll('path')
            paths.forEach(p => {
                p.attr({
                    fill: fillInfo,
                    strokeWidth: derivedValues.lineThickness,
                    stroke: derivedValues.lineColor
                })
            })
        }

        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }

        Ww2Vehicles.applyScale(g, derivedValues.flipHorizontal === 'flip' ? -1 * scale : scale, scale)
        Ww2Vehicles.applyRotation(g, rotation)
        Ww2Vehicles.applyTranslation(g, xpos + tadjusts[0], ypos + tadjusts[1])
    },

    drawSvg: (g, svgString) => {
        // gotta deal with ids
        let parsed = Snap.parse(svgString)
        g.append(parsed)
        let foundIds = Utility.distillIdsFromSnapElement(g)
        let uniquePrepend = 'w' + Utility.randomString(6)
        uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
        uniquePrepend += '_'
        svgString = Utility.replaceIdsInSvgString(foundIds, svgString, uniquePrepend)
        g.clear()
        parsed = Snap.parse(svgString)
        g.append(parsed)
        let measureG = g.getBBox()
        // I forget what the 20 is for.
        let adjustX = 20 + ((200 - measureG.width) / 2) - measureG.x
        let adjustY = 20 + ((200 - measureG.height) / 2) - measureG.y
        adjustX = Utility.roundFloat(adjustX, 2)
        adjustY = Utility.roundFloat(adjustY, 2)
        return [adjustX, adjustY]
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyScale: (g, scaleWidth, scaleHeight) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.scale(Utility.roundFloat(scaleWidth / 100, 3), Utility.roundFloat(scaleHeight / 100, 3), Utility.roundFloat(measureG.cx, 3), Utility.roundFloat(measureG.cy, 3)); //scale object
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
}

export default Ww2Vehicles
import React from 'react'
import './LayerMenuOpenerEdit.scss'

const LayerMenuOpenerEdit = ({ openerState }) => {
    return (
        <div className={openerState ? 'layer-menu-opener-edit' : 'layer-menu-opener-edit closed'}>
           edit
        </div>
    )
}
export default LayerMenuOpenerEdit
import React from 'react';
//old, does not seem to work. import { useErrorBoundary } from "use-error-boundary"
import { ErrorBoundary } from "react-error-boundary";
//import logo from './logo.svg';
import './App.scss';
import Home from './functions/Home/Home'

function App() {
  //const controller = new AbortController();
  //const { signal } = controller;

  return (

    <>
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <Home />
        </ErrorBoundary>
    </>

  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './SheetControls.scss'

const SheetControls = ({ sheetParameters, changeSheetParameters, changeCounterSizeMM, changeCounterMargins, changePageMargins, changeSheetMenuControlsOpened, changeSheetSide }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state, actions } = useContext(StoreContext)
    const [customCounterSize, setCustomCounterSize] = useState(sheetParameters.counterCustomSize)
    const [counterMargins, setCounterMargins] = useState(sheetParameters.counterMargins)
    const [pageMargins, setPageMargins] = useState(sheetParameters.pageMargins) //useState(sheetParameters.pageMargins)
    const [maximumChecked, setMaximumChecked] = useState(false)
    const [sheetControlsOpen, setSheetControlsOpen] = useState(true)
    const [currentSide, setCurrentSide] = useState('')
    const [startedUp, setStartedUp] = useState(false)
    const [counterSizes, setCounterSizes] = useState([
        { mm: 12.7, inch: '1/2"' },
        { mm: 14.2875, inch: '9/16"' },
        { mm: 15.875, inch: '5/8"' },
        { mm: 17.4625, inch: '11/16"' },
        { mm: 19.05, inch: '3/4"' },
        { mm: 22.225, inch: '7/8"' },
        { mm: 25.4, inch: '1"' }
    ])
    const standardCounterSizes = [
        { mm: 12.7, inch: '1/2"' },
        { mm: 14.2875, inch: '9/16"' },
        { mm: 15.875, inch: '5/8"' },
        { mm: 17.4625, inch: '11/16"' },
        { mm: 19.05, inch: '3/4"' },
        { mm: 22.225, inch: '7/8"' },
        { mm: 25.4, inch: '1"' }
    ]
    // sheetSettings: ['index', 'index', 
    // 'counterSize', 
    // 'counterCustomSize', 
    // 'counterMargins', 
    // 'countersColumnsRows', 
    // 'pageMargins', 
    // 'useCounterCustomSize', 
    // 'guideType', 
    // 'printableArea']

    useEffect(() => {
        changeSheetMenuControlsOpened(sheetControlsOpen)
    }, [sheetControlsOpen])

    useEffect(() => {
        if (sheetParameters.counterCustomSize && sheetParameters.useCounterCustomSize) {
            amendCounterSizeSelectWithCustomSize(sheetParameters.counterCustomSize)
        }

        return () => {
            controller.abort();
        };


    }, [])

    useEffect(() => {
        if (state.loadSheet.sheetSettings) {
            let loadedSheetCols = state.loadSheet.sheetSettings.countersColumnsRows[0]
            let loadedSheetRows = state.loadSheet.sheetSettings.countersColumnsRows[1]
            let disableMaximumAction = false
            if (maximumChecked && state.loadSheet.sheetSettings) {
                // if sheet has been loaded in, and the user has the maximumChecked button active, we need to see
                // if we need to turn that maximumChecked button off - otherwise the cols and rows will be changed.
                if (state.loadSheet.sheetSettings.countersColumnsRows[0] !== sheetParameters.maxCols ||
                    state.loadSheet.sheetSettings.countersColumnsRows[1] !== sheetParameters.maxRows) {
                    setMaximumChecked(false)
                    disableMaximumAction = true
                }
            }

            if (counterMargins[0] !== sheetParameters.counterMargins[0] ||
                counterMargins[1] !== sheetParameters.counterMargins[1] ||
                counterMargins[2] !== sheetParameters.counterMargins[2] ||
                counterMargins[3] !== sheetParameters.counterMargins[3]) {
                setCounterMargins(sheetParameters.counterMargins)
            }
            if (pageMargins[0] !== sheetParameters.pageMargins[0] ||
                pageMargins[1] !== sheetParameters.pageMargins[1] ||
                pageMargins[2] !== sheetParameters.pageMargins[2] ||
                pageMargins[3] !== sheetParameters.pageMargins[3]) {
                setPageMargins(sheetParameters.pageMargins)
            }

            if (maximumChecked && disableMaximumAction === false) {
                if (sheetParameters.cols < sheetParameters.maxCols ||
                    sheetParameters.rows < sheetParameters.maxRows) {
                    changeSheetParameters({ ...sheetParameters, cols: sheetParameters.maxCols, rows: sheetParameters.maxRows })
                }
            }

        }
        else {

            if (maximumChecked) {
                if (sheetParameters.cols < sheetParameters.maxCols ||
                    sheetParameters.rows < sheetParameters.maxRows) {
                    changeSheetParameters({ ...sheetParameters, cols: sheetParameters.maxCols, rows: sheetParameters.maxRows })
                }
            }

        }

        actions.loadSheet({ sheetSettings: null, slots: null })
    }, [sheetParameters])

    // counter col and row changes dont need any special handling. Just tell sheet what changes there are.
    const onChangeCountersAcross = evt => {
        setMaximumChecked(false)
        changeSheetParameters({ ...sheetParameters, cols: Number(evt.target.value) })
    }

    const onChangeCountersDown = evt => {
        setMaximumChecked(false)
        changeSheetParameters({ ...sheetParameters, rows: Number(evt.target.value) })
    }

    const maximumColsRows = () => {
        changeSheetParameters({ ...sheetParameters, cols: sheetParameters.maxCols, rows: sheetParameters.maxRows })
    }

    // changing counter size will alter most all sheet settings, so we need to tell sheet this is a specifically counter size change.
    const onChangeCounterSizeMM = evt => {
        changeCounterSizeMM(Number(evt.target.value), false)
    }

    // custom counter size changes need additional processing, to see its valid or not, before sending it on.
    const onChangeCustomCounterSize = evt => {
        let value = evt.target.value
        setCustomCounterSize(value)
        if (Utility.isNumeric(value)) {
            let inchConversion = toInch(value)
            value = Utility.roundFloat(value, 3)
            if (value >= 6 && value <= 100) {
                let _counterSizes = [...standardCounterSizes]
                let alreadyThere = standardCounterSizes.find(scs => scs.mm === value)
                if (alreadyThere) {
                    changeCounterSizeMM(value, true)
                }
                else {
                    _counterSizes.push({ mm: value, inch: inchConversion + '"' })
                    _counterSizes = _counterSizes.sort((a, b) => a.mm - b.mm)
                    setCounterSizes(_counterSizes)
                    changeCounterSizeMM(value, true)
                }
            }
        }
    }

    const amendCounterSizeSelectWithCustomSize = value => {
        if (Utility.isNumeric(value)) {
            let inchConversion = toInch(value)
            value = Utility.roundFloat(value, 3)
            if (value >= 6 && value <= 100) {
                let _counterSizes = [...standardCounterSizes]
                let alreadyThere = standardCounterSizes.find(scs => scs.mm === value)
                if (!alreadyThere) {
                    _counterSizes.push({ mm: value, inch: inchConversion + '"' })
                    _counterSizes = _counterSizes.sort((a, b) => a.mm - b.mm)
                    setCounterSizes(_counterSizes)
                    changeCounterSizeMM(value, true)
                }
            }
        }
    }

    const toInch = mm => {
        function highestCommonFactor(numerator) {
            for (let factor = numerator; factor > 0; factor--) {
                if ((numerator % factor == 0) && (denominator % factor == 0)) {
                    return factor;
                }
            }
        }
        // Split the decimal
        let inch = mm * 0.0393701
        inch = Utility.roundFloat(inch, 2)
        inch = inch + ''
        var decimalArray = inch.split('.');
        var leftDecimalPart = decimalArray[0];
        let rightDecimalPart = 0
        if (decimalArray.length > 1) {
            rightDecimalPart = decimalArray[1];
            if (rightDecimalPart === '') {
                rightDecimalPart = 0
            }
        }
        // // Save decimal part only for later use
        var decimalOnly = "0." + rightDecimalPart;

        // // Find the decimal multiplier
        var multiplier = "1";

        for (var i = 0; i < rightDecimalPart.length; i++) {
            multiplier += "0";
        }
        // // Create numerator by multiplying the multiplier and decimal part together
        var numerator = Number(multiplier) * Number(decimalOnly);
        if (numerator === 0) {
            return leftDecimalPart
        }
        var denominator = multiplier;
        // // Find the highest common factor for the numerator and denominator
        let factor = highestCommonFactor(numerator);

        // // Simplify the fraction by dividing the numerator and denominator by the factor
        var numerator = Number(numerator) / Number(factor);
        var denominator = Number(denominator) / Number(factor);
        if (denominator > 10) {
            numerator = (numerator / denominator) * 10
            numerator = Utility.roundFloat(numerator, 1)
            denominator = 10
        }
        // // Output as a mixed number fraction (depending on input)
        let mixedNumber = ''
        leftDecimalPart === '0' ?
            mixedNumber = numerator + "/" + denominator
            :
            mixedNumber = leftDecimalPart + " " + numerator + "/" + denominator

        return mixedNumber
        // // Output as a proper fraction or improper fraction (depending on input)
        // var numerator = numerator + (leftDecimalPart * denominator);
        // var fraction = numerator + "/" + denominator;
    }

    const numberWithinRangeTest = (value, lowerBound, upperBound) => {
        if (Utility.isNumeric(value) === false) {
            return false
        }
        if (Number(value) >= lowerBound && Number(value) <= upperBound) {
            return true
        }
        return false
    }

    const onChangeCounterMargin = evt => {

        let value = evt.target.value

        let _margins = [...counterMargins]

        if (evt.target.id.includes('left')) {
            _margins[3] = value
        }
        if (evt.target.id.includes('top')) {
            _margins[0] = value
        }
        if (evt.target.id.includes('right')) {
            _margins[1] = value
        }
        if (evt.target.id.includes('bottom')) {
            _margins[2] = value
        }

        setCounterMargins(_margins)
    }

    const onChangePageMargin = evt => {

        let value = evt.target.value

        let _margins = [...pageMargins]

        if (evt.target.id.includes('left')) {
            _margins[3] = value
        }
        if (evt.target.id.includes('top')) {
            _margins[0] = value
        }
        if (evt.target.id.includes('right')) {
            _margins[1] = value
        }
        if (evt.target.id.includes('bottom')) {
            _margins[2] = value
        }

        setPageMargins(_margins)
    }

    useEffect(() => {
        if (sheetParameters && counterMargins && Array.isArray(counterMargins)) {
            if (counterMargins.every(function (element) {
                return Utility.isNumeric(element) && numberWithinRangeTest(element, 0, 100)
            })) {
                if (JSON.stringify(sheetParameters.counterMargins) !== JSON.stringify(counterMargins)) {
                    changeCounterMargins(counterMargins)
                }
            }
        }
    }, [counterMargins])

    useEffect(() => {
        if (sheetParameters && pageMargins && Array.isArray(pageMargins)) {
            if (pageMargins.every(function (element) {
                return Utility.isNumeric(element) && numberWithinRangeTest(element, 0, 100)
            })) {
                if (JSON.stringify(sheetParameters.pageMargins) !== JSON.stringify(pageMargins)) {
                    changePageMargins(pageMargins)
                }
            }
        }
    }, [pageMargins])

    const onMaximumCheck = evt => {
        setMaximumChecked(evt.target.checked)
    }

    useEffect(() => {
        if (maximumChecked) {
            if (sheetParameters.cols < sheetParameters.maxCols ||
                sheetParameters.rows < sheetParameters.maxRows) {
                changeSheetParameters({ ...sheetParameters, cols: sheetParameters.maxCols, rows: sheetParameters.maxRows })
            }
        }
    }, [maximumChecked])

    const onClickFront = () => {
        setCurrentSide('front')
    }

    const onClickRear = () => {
        setCurrentSide('rear')
    }

    const onChangeSide = evt => {
        setCurrentSide(evt.target.id === 'sheetFront' ? 'front' : 'rear')
    }

    useEffect(() => {
        if (currentSide) {
            changeSheetSide(currentSide)
        }
        else {
            setCurrentSide('front')
        }
    }, [currentSide])

    return (
        sheetParameters && sheetParameters.cols ?
            <div className="sheet-controls">
                <div className={sheetControlsOpen ? 'controls-opener opened' : 'controls-opener closed'}>
                    <div className="title">sheet controls</div>
                    <button className={sheetControlsOpen ? 'standard-button yellow closed' : 'standard-button yellow'} onClick={() => setSheetControlsOpen(true)}>open</button>
                    <button className={sheetControlsOpen ? 'standard-button red' : 'standard-button red closed'} onClick={() => setSheetControlsOpen(false)}>close</button>
                </div>
                <div className={sheetControlsOpen ? 'controls' : 'display-none'}>

                    <div className="counters-layout">
                        <div className={maximumChecked ? 'select-container maximum-checked' : 'select-container'}>

                            <div className="popupable" data-popup="Counters across and down"
                                onClick={actions.popupInfoActive}>counters across: </div>

                            <select onChange={onChangeCountersAcross} value={sheetParameters.cols}>
                                {
                                    [...Array(sheetParameters.maxCols).keys()].map(i => i + 1).map(n => <option key={n} value={n}>{n}</option>)
                                }
                            </select>
                        </div>
                        <div className={maximumChecked ? 'select-container maximum-checked' : 'select-container'}>

                            <div className="popupable" data-popup="Counters across and down"
                                onClick={actions.popupInfoActive}>counters down: </div>

                            <select onChange={onChangeCountersDown} value={sheetParameters.rows}>
                                {
                                    [...Array(sheetParameters.maxRows).keys()].map(i => i + 1).map(n => <option className="option" key={n} value={n}>{n}</option>)
                                }
                            </select>
                        </div>
                        <div className={maximumChecked ? 'maximum-checkbox maximum-checked' : 'maximum-checkbox'}>
                            {/* <button className="standard-button yellow" onClick={maximumColsRows}>maximum to fit</button> */}
                            <input type="checkbox" onChange={onMaximumCheck} checked={maximumChecked} /> maximum that fits
                        </div>
                    </div>

                    <div className="counter-size">
                        <div className="standard-sizes-container">
                            <div className="title">
                                <div className="popupable" data-popup="Counter size"
                                    onClick={actions.popupInfoActive}>counter size: </div>
                                <span className="current-value">{sheetParameters.counterSizeMM}</span>
                            </div>

                            <select onChange={onChangeCounterSizeMM} value={sheetParameters.counterSizeMM}>
                                {
                                    counterSizes.map(size => <option key={size.mm} value={size.mm}>{size.mm}mm, {size.inch}</option>)
                                }
                            </select>
                            {
                                //  counterSizes.map(size => console.log('size.mm:', size.mm)) 
                            }
                        </div>
                        <div className="custom-size">
                            <div>custom:</div>
                            <div className="input-container">
                                <input type="text" className={numberWithinRangeTest(customCounterSize, 6, 100) || !customCounterSize ? '' : 'not-numeric'} value={customCounterSize} onChange={onChangeCustomCounterSize} /><span className="mm">mm</span>
                            </div>
                        </div>
                    </div>


                    <div className="margins-control">
                        <div className="title">
                            <div className="popupable" data-popup="Counter margins"
                                onClick={actions.popupInfoActive}>counter margins: </div>
                            <span>(mm)</span>
                        </div>
                        <div className="margins">

                            <div className="margin">
                                <span>left:</span>
                                <input id="counter-margin-left" type="text"
                                    className={numberWithinRangeTest(counterMargins[3], 0, 100) || counterMargins[3] === '' ? '' : 'not-numeric'}
                                    value={counterMargins[3]}
                                    onChange={onChangeCounterMargin} />
                            </div>
                            <div className="margin">
                                <span>right:</span>
                                <input id="counter-margin-right" type="text"
                                    className={numberWithinRangeTest(counterMargins[1], 0, 100) || counterMargins[1] === '' ? '' : 'not-numeric'}
                                    value={counterMargins[1]}
                                    onChange={onChangeCounterMargin} />
                            </div>
                            <div className="margin">
                                <span>top:</span>
                                <input id="counter-margin-top" type="text"
                                    className={numberWithinRangeTest(counterMargins[0], 0, 100) || counterMargins[0] === '' ? '' : 'not-numeric'}
                                    value={counterMargins[0]}
                                    onChange={onChangeCounterMargin} />
                            </div>
                            <div className="margin">
                                <span>bottom:</span>
                                <input id="counter-margin-bottom" type="text"
                                    className={numberWithinRangeTest(counterMargins[2], 0, 100) || counterMargins[2] === '' ? '' : 'not-numeric'}
                                    value={counterMargins[2]}
                                    onChange={onChangeCounterMargin} />
                            </div>
                        </div>
                    </div>

                    <div className="margins-control">
                        <div className="title">
                            <div className="popupable" data-popup="Page margins"
                                onClick={actions.popupInfoActive}>page margins: </div>
                            <span>(mm)</span>
                        </div>

                        <div className="margins">

                            <div className="margin">
                                <span>left:</span>
                                <input id="page-margin-left" type="text"
                                    className={numberWithinRangeTest(pageMargins[3], 0, 100) || pageMargins[3] === '' ? '' : 'not-numeric'}
                                    value={pageMargins[3]}
                                    onChange={onChangePageMargin} />
                            </div>
                            <div className="margin">
                                <span>right:</span>
                                <input id="page-margin-right" type="text"
                                    className={numberWithinRangeTest(pageMargins[1], 0, 100) || pageMargins[1] === '' ? '' : 'not-numeric'}
                                    value={pageMargins[1]}
                                    onChange={onChangePageMargin} />
                            </div>
                            <div className="margin">
                                <span>top:</span>
                                <input id="page-margin-top" type="text"
                                    className={numberWithinRangeTest(pageMargins[0], 0, 100) || pageMargins[0] === '' ? '' : 'not-numeric'}
                                    value={pageMargins[0]}
                                    onChange={onChangePageMargin} />
                            </div>
                            <div className="margin">
                                <span>bottom:</span>
                                <input id="page-margin-bottom" type="text"
                                    className={numberWithinRangeTest(pageMargins[2], 0, 100) || pageMargins[2] === '' ? '' : 'not-numeric'}
                                    value={pageMargins[2]}
                                    onChange={onChangePageMargin} />
                            </div>
                        </div>

                    </div>









                    <div className="sides-control">
                        <div className="title">
                            <div className="popupable" data-popup="Sheet side"
                                onClick={actions.popupInfoActive}>sheet side: </div>
                        </div>

                        <div className="sides">
                            <div className="side">
                                <div className="option" onClick={onClickFront} >
                                    <input type="radio"
                                        id="sheetFront"
                                        name="sheetFront"
                                        value='front'
                                        checked={currentSide === 'front'}
                                        onChange={onChangeSide}
                                        onClick={onChangeSide}
                                    />
                                    <label htmlFor="sheetFront" className={currentSide === 'front' ? 'hilite' : ''}>front</label>
                                </div>
                                <div className="option" onClick={onClickRear}>
                                    <input type="radio"
                                        id="sheetRear"
                                        name="sheetRear"
                                        value='rear'
                                        checked={currentSide === 'rear'}
                                        onChange={onChangeSide}
                                        onClick={onChangeSide}
                                    />
                                    <label htmlFor="sheetRear" className={currentSide === 'rear' ? 'hilite' : ''}>rear</label>
                                </div>
                            </div>
                        </div>

                    </div>








                </div>

            </div>
            : ''
    );
}
export default SheetControls
import React, { useState, useEffect } from 'react'
import Utility from "../../objects/Utility"
//import Snap from 'snapsvg-cjs'
import './MaterialSymbolButton.scss'
const MaterialSymbolButton = ({ symbol, selected, select, hoveringOn }) => {
    const [hoverOnSymbol, setHoverOnSymbol] = useState(false)

    const handleClick = e => {
        let symbolClickedOn = e.currentTarget.id.replace('material-symbol-', '')
        select(symbolClickedOn)
    }

    const hover = hovering => {
        hoveringOn(hovering ? symbol : '')
    }

    return (
        <div className="material-symbol-button" id={`material-symbol-${symbol}`} onMouseOver={() => hover(true)} onMouseOut={() => hover(false)} onClick={handleClick}>
            <div className={ selected ? 'material-symbol-box selected' : 'material-symbol-box'}>
                <i className="material-icons">{symbol}</i>
            </div>
        </div>
    )
}
export default MaterialSymbolButton
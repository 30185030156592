import React, { useState, useEffect, useContext, useRef } from 'react'
import stringComparison from 'string-comparison'
import Snap from 'snapsvg-cjs'
import { useSignal } from "@preact/signals-react"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './ViewApp.scss'

const ViewApp = ({ mode, close, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const [savedSheets, setSavedSheets] = useState([])
    const [successMessage, setSuccessMessage] = useState({ hash: null, message: { message: '', color: '' } })
    const signalDeleteSheetHash = useSignal(null)
    const signalDeleteSheetTimer = useSignal(null)
    const [startupCheckDone, setStartupCheckDone] = useState(false)
    const [resetDatabaseConfirm, setResetDatabaseConfirm] = useState(false)
    const resetDatabaseTimestamp = useSignal(false)
    const [deletingDatabase, setDeletingDatabase] = useState(false)
    const [resetDatabaseErrorMessage, setResetDatabaseErrorMessage] = useState('')
    const [areYouSureTimer, setAreYouSureTimer] = useState(null)
    const [exportDatabaseFilename, setExportDatabaseFilename] = useState('')
    const [showExportDatabaseForm, setShowExportDatabaseForm] = useState(false)
    const exportDatabaseFilenameRef = useRef(null)
    const [loadWorkspaceFileInvalid, setLoadWorkspaceFileInvalid] = useState('')
    const [loadWorkspaceErrors, setLoadWorkspaceErrors] = useState([])
    const [databaseToRestore, setDatabaseToRestore] = useState(null)
    const [idPrependText, setIdPrependText] = useState('')
    const [svgId, setSvgId] = useState(null)
    const [paperTarget, setPaperTarget] = useState(null)

    useEffect(() => {
        return () => {
            if (areYouSureTimer) {
                clearTimeout(areYouSureTimer)
            }
        };
    }, [])

    useEffect(() => {
        const _paperTarget = Snap("#svg_target");
        setPaperTarget(_paperTarget)
        return () => {
            if (paperTarget) {
                paperTarget.clear()
            }
        };
    }, [])

    const clickResetDatabase = () => {
        if (deletingDatabase) {
            return
        }
        if (resetDatabaseConfirm) {
            let timestamp = Math.floor(Date.now() / 100)
            if (resetDatabaseTimestamp.value) {
                let diff = timestamp - resetDatabaseTimestamp.value
                if (diff > 3) { // 300 ms
                    resetDatabaseTimestamp.value = false
                    setDeletingDatabase(true)
                }
            }
            else {
                setResetDatabaseConfirm(false)
            }
        }
        else {
            setResetDatabaseConfirm(true)
            resetDatabaseTimestamp.value = Math.floor(Date.now() / 100)
            if (areYouSureTimer === null) {
                let sureTimer = setTimeout(() => {
                    resetDatabaseTimestamp.value = null
                    setDeletingDatabase(false)
                    setResetDatabaseConfirm(false)
                    setAreYouSureTimer(null)
                }, 5000)
                setAreYouSureTimer(sureTimer)
            }
        }
    }

    useEffect(() => {
        if (deletingDatabase) {
            // check to see if server is reachable
            fetch('https://snapcounter.app/wargalleyservice/', { mode: 'no-cors' }).then(r => {
                actions.restoreDatabase(true)
            })
                .catch(e => {
                    setResetDatabaseErrorMessage('The server is not reachable currently. Cannot reset database at this time.')
                });

        }
    }, [deletingDatabase])

    const clickExportDatabase = () => {
        setShowExportDatabaseForm(true)
    }

    useEffect(() => {
        if (showExportDatabaseForm && exportDatabaseFilenameRef.current) {
            exportDatabaseFilenameRef.current.focus()
        }
    }, [showExportDatabaseForm])

    const packageWorkspace = async () => {
        // need to get font data from dexie cause state doesnt carry it (I think it may eat up too much memory storing gigs of font data.
        // We just retrieve the font data from the dexie store when we need it for particular fonts.
        let counterSideActive = await state.dexie['counterSideActive'].toArray()
        let fonts = await state.dexie['fonts'].toArray()
        let svgs = await state.dexie['svgs'].toArray()
        let activeLayerValues = await state.dexie['activeLayerValues'].toArray()
        let layers = await state.dexie['layers'].toArray()
        let layersOpen = await state.dexie['layersOpen'].toArray()
        let savedCounters = await state.dexie['savedCounters'].toArray()
        let savedSheets = await state.dexie['savedSheets'].toArray()
        let sheetSettings = await state.dexie['sheetSettings'].toArray()
        let slots = await state.dexie['slots'].toArray()
        return {
            counterSideActive,
            activeLayerValues,
            fonts,
            layers,
            layersOpen,
            savedCounters,
            savedSheets,
            sheetSettings,
            slots,
            svgs
        }
    }

    const saveFile = async (packagedWorkspace) => {
        let fileObj = {
            fileName: exportDatabaseFilename,
            app: 'snapCounter',
            type: 'workspace',
            version: 3,
            dateSaved: Utility.currentDate(),
            workspace: packagedWorkspace
        }

        var zip = new JSZip();
        zip.file(exportDatabaseFilename + ".json", JSON.stringify(fileObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                saveAs(content, exportDatabaseFilename + '.zip');
                setExportDatabaseFilename('')
                setShowExportDatabaseForm(false)
            }).catch(function () {
                // setSaveResult({ state: false, message: 'Could not save file.' })
            })

    }

    const exportDatabase = async () => {
        if (exportDatabaseFilename.length > 1 && exportDatabaseFilename.length < 256) {
            let workspace = await packageWorkspace()
            saveFile(workspace)
        }
    }

    const onChangeExportDatabaseFilename = evt => {
        if (evt.target.value.length < 256) {
            setExportDatabaseFilename(evt.target.value)
        }
    }

    const appendDateToText = () => {
        let datetext = Utility.currentDateAndTime(false)
        let newName = exportDatabaseFilename + datetext
        if (newName.length < 256) {
            setExportDatabaseFilename(newName)
        }
    }


    const onChangeLoadFromFile = evt => {
        let file = evt.target.files[0]
        if (!file) {
            return // happens if the user brings up the file selection dialog then closes it.
        }
        let importHasCorrectFormat = true
        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async('string').then(async (fileData) => {
                    let data = null
                    try {
                        data = JSON.parse(fileData)
                    }
                    catch (e) {
                        setLoadWorkspaceFileInvalid('the file could not be loaded. Is it a zip file?')
                        // setRestoreResult({ state: false, message: 'error loading file' })
                        return
                    }
                    if (data.app === undefined || !data.app.startsWith('snapCounter')) {
                        //setRestoreResult({ state: false, message: 'this file does not appear to be a snapCounter file' })
                        setLoadWorkspaceFileInvalid('this file is not SnapCounter database file.')
                        return
                    }
                    if (!data.type || data.type !== 'workspace') {
                        console.log('this file is not a database database file.')
                        setLoadWorkspaceFileInvalid('this file is not a database workspace file.')
                        return
                    }
                    if (data.app === 'snapCounter' && data.type === 'workspace' && data.version === 3) {
                        console.log('new version database backup...')
                        console.log('data.workspace:', data.workspace)
                        loadDatabaseBackup(data.workspace)
                    }
                    else {
                        if (data.app === 'snapCounter' && data.type === 'workspace') {
                            console.log('old version workspace backup')
                            let fixedUpWorkspace = ver2toVer3workspace(JSON.parse(JSON.stringify(data.workspace)))
                            // xxx1
                            loadDatabaseBackup(fixedUpWorkspace)
                        }
                        else {
                            console.error('unknown file format')
                            setLoadWorkspaceFileInvalid('this file is not a database workspace file.')
                        }
                    }
                })
            })
        })
    }

    const ver2toVer3workspace = workspace => {
        //console.log('v2 workspace to work on:', JSON.parse(JSON.stringify(workspace)))

        /////////////////////////////////////////////////////////////////////////////////////////
        // create the prepend stripped svgCode fields that we will need for comparison tests.
        /////////////////////////////////////////////////////////////////////////////////////////
        let incomingSvgs = JSON.parse(JSON.stringify(workspace.svgs))
        let strippedIncomingSvgs = []
        incomingSvgs.forEach(iSvg => {
            if (iSvg.uniquePrepend) {
                iSvg.svgCodeStripped = iSvg.svgCode.replaceAll(iSvg.uniquePrepend, '')
            }
            else {
                iSvg.svgCodeStripped = iSvg.svgCode
            }
            strippedIncomingSvgs.push(iSvg)
        })

        // strip svgCodes of their prepend ids, so we can do comparisons.
        let stateSvgs = JSON.parse(JSON.stringify(state.svgs))
        let strippedStateSvgs = []
        stateSvgs.forEach(sSvg => {
            if (sSvg.uniquePrepend) {
                sSvg.svgCodeStripped = sSvg.svgCode.replaceAll(sSvg.uniquePrepend, '')
            }
            else {
                sSvg.svgCodeStripped = sSvg.svgCode
            }
            strippedStateSvgs.push(sSvg)
        })

        /////////////////////////////////////////////////////////////////
        // deal with dupes that may be coming in from the incoming svgs
        /////////////////////////////////////////////////////////////////
        let incomingDupeChangesFromTo = []
        // remove any dupes that are in the incoming svgs
        let incomingFixedSvgs = [...strippedIncomingSvgs]
        let svgKeyChanges = []
        let layers = workspace.layers.filter(il => il.layerActive === 1)
        let activeLayerValues = Utility.safeJsonParse(workspace.activeLayerValues)
        for (const [key] of Object.entries(Utility.safeJsonParse(workspace.activeLayerValues))) {
            // remove spacer inputs (not used in SnapCounter version 3)
            if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                delete activeLayerValues[key]
            }
        }
        let slots = workspace.slots
        strippedIncomingSvgs.forEach(sSvg => {
            let found = strippedIncomingSvgs.filter(sSvg2 => sSvg2.svgCodeStripped === sSvg.svgCodeStripped)
            //let found = strippedIncomingSvgs.filter(sSvg2 => Utility.roundFloat(stringComparison.cosine.similarity(panzer4a.svgCodeStripped, panzer4aState.svgCodeStripped),2) )
            if (found.length === 0) {
                ; //c povonsole.log('sSvg.svgName:', sSvg.svgName, ' match not found.')
            }
            if (found.length === 1) {
                ;// console.log(sSvg.svgName, 'svgKey:', sSvg.svgKey, 'ok there is only one, and it matches.')
            }
            if (found.length > 1) {
                let svgType = 'svg'
                if (sSvg.svgCode.includes('svgxlink:href') && sSvg.svgCode.includes('<image')) {
                    svgType = 'image'
                }
                let useThisSvgKeyForFixes = found[0].svgKey
                for (let i = 1; i < found.length; i++) {
                    let deleteSvgKey = found[i].svgKey
                    incomingFixedSvgs = incomingFixedSvgs.filter(ifs => ifs.svgKey !== deleteSvgKey)
                    if (!svgKeyChanges.find(df => df.from === deleteSvgKey)) {
                        svgKeyChanges.push({ from: deleteSvgKey, to: useThisSvgKeyForFixes, svgType })
                    }
                }
            }
        })
        // at this point incomingFixedSvgs have the duplicated svgs (if any) removed.
        // Now we need to fix the incoming alv to change any references to the deleted svg over to the unique svg.
        //console.log('svgKeyChanges:', svgKeyChanges) // and the data we need to know of which svgKeys need to be updated on the incoming alv.
        svgKeyChanges.forEach(svgKeyChange => {
            let fromSvgKey = svgKeyChange.from
            let toSvgKey = svgKeyChange.to
            layers.forEach(ly => {
                let requiredInputKey = ly.layerActiveRequiredInputKey
                if (activeLayerValues[ly.layerKey + '_' + requiredInputKey] &&
                    activeLayerValues[ly.layerKey + '_' + requiredInputKey] === fromSvgKey) {
                    activeLayerValues[ly.layerKey + '_' + requiredInputKey] = toSvgKey
                }
            })
            slots.forEach(sl => {
                if (sl.counterState !== null) {
                    //console.log('fix alv in this slot:', sl.counterState.activeLayerValues)
                    let slotActiveLayerValues = sl.counterState.activeLayerValues
                    let slotLayers = sl.counterState.layers
                    slotLayers = slotLayers.filter(sl => sl.layerActive === 1)
                    slotLayers.forEach(ly => {
                        let requiredInputKey = ly.layerActiveRequiredInputKey
                        if (slotActiveLayerValues[ly.layerKey + '_' + requiredInputKey] &&
                            slotActiveLayerValues[ly.layerKey + '_' + requiredInputKey] === fromSvgKey) {
                            slotActiveLayerValues[ly.layerKey + '_' + requiredInputKey] = toSvgKey
                        }
                    })
                }
            })
        })

        // by now any dupe svgs should have been removed from incomingFixedSvgs and workspace.activeLayerValues should be
        // fixed up to have any values that were referring to a dupe svgs to now referring to the unique svg. The slots
        // should have had the same fix applied. We dont worry about the slot svgs because they will be ignored since
        // the incomingSvgs (which was derived from workspace.customSvgs and workspace.imageSvgs has the svgs needed.

        // ok, now for the standard import process -
        // layers active for those being used in the incoming counter draw area
        let stateLayersByType = []
        state.layers.forEach(layer => {
            let originalName = Utility.originalLayerName(state.layers, layer)
            let collection = stateLayersByType.find(slbt => slbt.originalName === originalName)
            if (!collection) {
                stateLayersByType.push({ originalName, layers: [layer], matched: 0 })
            }
            else {
                collection.layers.push(layer)
            }
        })
        //console.log('statelayersByType:', stateLayersByType)
        let activeIncomingLayers = workspace.layers.filter(ly => ly.layerActive === 1)

        // look for incoming duplicated layers, and match them up or change layerKeys and/or create dupes as needed.
        let stateLayers = JSON.parse(JSON.stringify(state.layers))
        let layerKeyChanges = []

        const findTypeMatch = layerType => {
            let matchResult = null
            let foundEntry = stateLayersByType.find(slt => slt.originalName === layerType)
            if (foundEntry) {
                if (foundEntry.layers.length > 0) {
                    let foundLayer = foundEntry.layers[0]
                    foundEntry.matched++
                    matchResult = { ...foundLayer }
                    foundEntry.layers.shift()
                }
                else {
                    matchResult = null
                }
            }
            else {
                console.error('what happened, didnt find:', layerType)
            }

            return matchResult
        }

        let layerDupesNeeded = []
        activeIncomingLayers.forEach(ily => {
            let incomingLayerType = Utility.originalLayerName(state.layers, ily)
            //console.warn('looking for match on incoming layer:', ily.layerName, 'which is type:', incomingLayerType)
            let byTypeMatch = findTypeMatch(incomingLayerType)
            //console.log('byTypeMatch:', byTypeMatch)
            if (byTypeMatch) {
                if (ily.layerKey !== byTypeMatch.layerKey) {
                    layerKeyChanges.push({ from: ily.layerKey, to: byTypeMatch.layerKey })
                }
            }
            else {
                layerDupesNeeded.push(ily)
            }
        })
        //console.log('stateLayersByType after matches:', stateLayersByType)
        //console.log('layerDupesNeeded:',layerDupesNeeded)
        layerDupesNeeded.forEach(dupeThisLayer => {
            let dupeResult = createDuplicateLayer(stateLayers, dupeThisLayer)
            stateLayers = dupeResult.updatedStateLayers
            let newLayerKey = dupeResult.newLayerKey
            if (dupeThisLayer.layerKey !== newLayerKey) {
                layerKeyChanges.push({ from: dupeThisLayer.layerKey, to: newLayerKey })
            }
        })
        //console.log('layerKeyChanges:',layerKeyChanges)

        layerKeyChanges.forEach(fromTo => {
            let from = fromTo.from
            let to = fromTo.to
            //console.log('from layerKey:',from,'to layerKey:', to) 
            let copyActiveLayerValues = { ...activeLayerValues }
            for (const [key, value] of Object.entries(copyActiveLayerValues)) {
                if (key.startsWith(from + '_')) {
                    //console.log('changing alv: ', key,'=',value)
                    let inputKey = key.split('_')[1]
                    activeLayerValues[to + '_' + inputKey] = value
                    //console.log('activeLayerValues[' + to + '_' + inputKey + '] =', activeLayerValues[to + '_' + inputKey])
                    delete activeLayerValues[key]
                }
            }
            slots.forEach(sl => {
                if (sl.counterState !== null) {
                    let copyActiveLayerValues = { ...sl.counterState.activeLayerValues }
                    for (const [key, value] of Object.entries(copyActiveLayerValues)) {
                        if (key.startsWith(from + '_')) {
                            let inputKey = key.split('_')[1]
                            sl.counterState.activeLayerValues[to + '_' + inputKey] = value
                            delete sl.counterState.activeLayerValues[key]
                        }
                    }
                }
            })

        })

        // activate layers we need to have on
        // this is just a stop gap for now. We will need to have any dupe layers fixed, and layerKey changes propagated,
        // before we can do this accurately. But for now, lets just do this so I can get all the other crap working.

        // first turn off all the state layers
        stateLayers.forEach(sl => sl.layerName === 'base counter' ? sl.layerActive = 1 : sl.layerActive = 0)
        activeIncomingLayers.forEach(ily => {
            let incomingLayerKey = ily.layerKey
            let foundStateLayer = stateLayers.find(sl => sl.layerKey === incomingLayerKey)
            if (foundStateLayer) {
                foundStateLayer.layerActive = 1
                foundStateLayer.layerHidden = 0
            }
        })

        // we need to gather the state custom layers, since we will be updating their input svg lists (if needed).
        let stateLayersCustomSvgs = []
        let stateLayersCustomImages = []
        stateLayers.forEach(sl => {
            if (Utility.isCustomSvgsLayer2(sl, state.layers)) {
                stateLayersCustomSvgs.push({ ...sl })
            }
            if (Utility.isCustomImagesLayer2(sl, state.layers)) {
                stateLayersCustomImages.push({ ...sl })
            }
        })
        //console.log('stateLayersCustomSvgs:', stateLayersCustomSvgs)
        //console.log('stateLayersCustomImages:', stateLayersCustomImages)

        // remove spacers from layers input.list 
        layers.forEach(ly => {
            ly.inputs = ly.inputs.filter(ly => ly.inputKey !== 5 && ly.inputKey !== 59 && ly.inputKey !== 72)
            ly.layerInputKeys = ly.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
        })
        // layersOpen is a new store. We can leave it empty (no layer menu items are open).
        workspace.layersOpen = []

        // add sheetSide (new field) to sheetSettings
        let sheetSettings = workspace.sheetSettings
        sheetSettings.sheetSide = 'front'

        // fix up slots for rear col row and x y settings
        let cols = workspace.sheetSettings.countersColumnsRows[0]
        let row = -1
        //console.log('lastSheetDisplayWidth;', state.lastSheetDisplayWidth)
        let ratio = workspace.sheetSettings.printableArea[0] / workspace.sheetSettings.printableArea[1]
        //console.log('ratio:', ratio)
        let ratioMMtoPixels = state.lastSheetDisplayWidth / workspace.sheetSettings.printableArea[0]
        //console.log('ratioMMtoPixels:', ratioMMtoPixels)1729959961025
        let counterSizeInPixels = Utility.roundFloat(workspace.sheetSettings.counterSize * ratioMMtoPixels, 10)
        //console.log('counterSizeInPixels:', counterSizeInPixels)
        let marginLeftPixels = workspace.sheetSettings.counterMargins[3] * ratioMMtoPixels
        let marginTopPixels = workspace.sheetSettings.counterMargins[0] * ratioMMtoPixels
        let marginRightPixels = workspace.sheetSettings.counterMargins[1] * ratioMMtoPixels
        let marginBottomPixels = workspace.sheetSettings.counterMargins[2] * ratioMMtoPixels
        let pageLeftPixels = workspace.sheetSettings.pageMargins[3] * ratioMMtoPixels
        let pageTopPixels = workspace.sheetSettings.pageMargins[0] * ratioMMtoPixels
        let totalCounterPixelsWidth = counterSizeInPixels + marginLeftPixels + marginRightPixels
        let totalCounterPixelsHeight = counterSizeInPixels + marginTopPixels + marginBottomPixels
        slots.forEach(sl => {
            let number = sl.number
            let column = number % cols
            if (column === 0) {
                row++
            }
            let numberOnRightSide = ((row + 1) * cols)
            let rearNumber = (numberOnRightSide - column) - 1
            sl.rearNumber = rearNumber
            sl.position = { col: column, row: row }
            sl.rearPosition = { col: cols - column - 1, row }
            sl.pixelsWidth = counterSizeInPixels
            sl.xy = { x: column * totalCounterPixelsWidth, y: row * totalCounterPixelsHeight }
            sl.xy.x += marginLeftPixels + pageLeftPixels;
            sl.xy.y += marginTopPixels + pageTopPixels
            sl.centerXy = { x: sl.xy.x + (counterSizeInPixels / 2), y: sl.xy.y + (counterSizeInPixels / 2) }
        })

        ////////////////////////////////////////////////////////////////////////////////
        // handle incoming svgs
        ////////////////////////////////////////////////////////////////////////////////
        let newSvgs = []
        let changeSvgIdFromTo = []
        //let svgs = incomingFixedSvgscutSvgCode.
        incomingFixedSvgs.forEach(svg => {
            let svgType = 'svg'
            if (svg.svgCode.includes('svgxlink:href') && svg.svgCode.includes('<image')) {
                svgType = 'image'
            }
            let found = strippedStateSvgs.find(ss => ss.svgKey === svg.svgKey && ss.svgCodeStripped === svg.svgCodeStripped)
            // if the svgKey and the svgCode matches whats in state, then nothing to do.
            if (!found) {
                // see if the svg exists, even if the key has changed
                found = strippedStateSvgs.find(ss => ss.svgCodeStripped === svg.svgCodeStripped)
                if (found) {
                    changeSvgIdFromTo.push({ from: svg.svgKey, to: found.svgKey, svgType })
                }
                else {
                    // must be a new custom image or svg the user installed.
                    // check to see if there is a svg name match, then do a comparison check on the svgCodeStripped values.
                    found = strippedStateSvgs.find(ss => ss.svgName === svg.svgName)
                    let found2 = strippedIncomingSvgs.find(ss => ss.svgName === svg.svgName)
                    if (found && found2) {
                        let rawQ = stringComparison.cosine.similarity(found.svgCodeStripped, found2.svgCodeStripped)
                        let matchQuotient = Utility.roundFloat(rawQ)
                        if (matchQuotient === 1) { // if the svgCodes almosvgKeyChangesst perfectly match, consider it a match.
                            if (found.svgKey !== found2.svgKey) {
                                changeSvgIdFromTo.push({ from: found2.svgKey, to: found.svgKey, svgType })
                            }
                        }
                        else {
                            svg.svgType = svgType
                            newSvgs.push(svg)
                        }
                    }
                    else {
                        svg.svgType = svgType
                        newSvgs.push(svg)
                    }
                }
            }
        })
        //console.log('t2 newSvgs:', newSvgs)

        //console.log('changeSvgIdFromTo:', changeSvgIdFromTo)
        //console.log('newSvgs:', newSvgs)
        let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
        useKey++
        if (useKey < 3000) {
            useKey = 3000 // lets start at a baseline
        }
        newSvgs.forEach(newSvg => {
            changeSvgIdFromTo.push({ from: newSvg.svgKey, to: useKey, svgType: newSvg.svgType })
            newSvg.svgKey = useKey
            useKey++
        })
        //console.log('newSvgs:', newSvgs)
        //console.log('changeSvgIdFromTo:', changeSvgIdFromTo)
        let layerUpdates = importSvgKeyChanges(changeSvgIdFromTo, stateLayersCustomImages, stateLayersCustomSvgs, activeLayerValues)
        let customImagesUpdated = layerUpdates.customImages
        let customSvgsUpdated = layerUpdates.customSvgs
        //console.log('activeLayerValues after fn call:', activeLayerValues)
        
        const fixSvg = (svg, pixelsWidth, number) => {
            let firstEndTag = svg.indexOf('>')
            let cutSvg = svg.substring(firstEndTag + 1)
            let newStart = '<svg id="combined_' + number + '" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="20 20 200 200"><g transform="scale(1.2)">'
            svg = newStart + cutSvg + '</svg>'

            return svg
        }

        // fix up the slots activeLayerValues "{"10_100":"#000000","10_101":"#FFFFFF","10_102":10,"10_103":[0,0],"10_104":[0,0],"10_60":"3","10_61":"4","10_62":"dot","10_63":60,"10_64":"ABeeZee","10_65":"#000000","10_66":"#FFFFFF","10_67":"normal","10_68":[4.4,63.8],"10_69":110,"10_73":10,"10_74":"separator settings","10_75":[0,0],"10_76":10,"10_77":10,"10_78":"#000000","10_79":"#FFFFFF","10_80":1,"10_95":"combat factor","10_96":"movement factor","10_97":60,"10_98":"Inter","10_99":"normal","19_182":[73,-18.2],"19_185":0,"19_186":100,"19_192":2268,"19_212":[32,32],"1_181":"{\"fillType\":\"solid\",\"fillColor\":\"#d0ecab\",\"gradientDirection\":\"linear\",\"xyPositionStart\":\"0,0\",\"xyPositionEnd\":\"1,1\",\"colorStart\":\"#00FF00\",\"colorMid\":\"#44ee44\",\"colorEnd\":\"#66ff66\"}","1_38":0,"2_12":3,"2_169":0,"2_17":[2.8,-17.2],"2_170":2,"2_176":"{\"fillType\":\"solid\",\"fillColor\":\"#FFFFFF\",\"gradientDirection\":\"linear\",\"xyPositionStart\":\"0,0\",\"xyPositionEnd\":\"1,1\",\"colorStart\":\"#00FFFF\",\"colorMid\":\"#444ddd\",\"colorEnd\":\"#669999\"}","2_194":"#000000","2_204":[80.5,68.4],"2_24":0,"2_3":100,"3_13":13,"3_173":25,"3_174":15,"3_205":[10,10],"3_22":0,"3_28":[0,-66.2],"3_6":"#000000","3_7":100,"23_3":100,"23_12":5,"23_17":[-67.7,-72.7],"23_24":0,"23_169":0,"23_170":2,"23_176":"{\"fillType\":\"solid\",\"fillColor\":\"#FFFFFF\",\"gradientDirection\":\"linear\",\"xyPositionStart\":\"0,0\",\"xyPositionEnd\":\"1,1\",\"colorStart\":\"#00FFFF\",\"colorMid\":\"#444ddd\",\"colorEnd\":\"#669999\"}","23_194":"#000000","23_204":[37.6,32]}"and insert the proper v3 slot.counterState format
        slots.forEach(sl => {
            if (sl.counterState !== null) {
                let slotActiveLayerValues = sl.counterState.activeLayerValues
                let slotLayers = sl.counterState.layers


                for (const [key] of Object.entries(Utility.safeJsonParse(slotActiveLayerValues))) {
                    // remove spacer inputs (not used in SnapCounter version 3)
                    if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                        delete slotActiveLayerValues[key]
                    }
                }
                slotLayers.forEach(ly => {
                    ly.inputs = ly.inputs.filter(ly => ly.inputKey !== 5 && ly.inputKey !== 59 && ly.inputKey !== 72)
                    ly.layerInputKeys = ly.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
                })

                /*
                I need to adjust any layers that were changed for counter, and apply them here - because layers
                in the slots are supposed to match what are in state. Theres no later interpretations.
                */

                slotLayers = slotLayers.filter(sl => sl.layerActive === 1)
                slotLayers.forEach(ly => {
                    changeSvgIdFromTo.forEach(changeSvgKey => {
                        let fromSvgKey = changeSvgKey.from
                        let toSvgKey = changeSvgKey.to
                        let requiredInputKey = ly.layerActiveRequiredInputKey
                        if (slotActiveLayerValues[ly.layerKey + '_' + requiredInputKey] &&
                            slotActiveLayerValues[ly.layerKey + '_' + requiredInputKey] === fromSvgKey) {
                            slotActiveLayerValues[ly.layerKey + '_' + requiredInputKey] = toSvgKey
                        }
                    })
                })
                let fixedSvg = fixSvg(sl.svg, sl.pixelsWidth, sl.number)
                sl.counterState.frontSvg = fixedSvg
                sl.counterState.rearSvg = fixedSvg
                let customSvgKeys = []
                let customImageKeys = []
                // ok we got two sources we need to use - did we remove a dupe? Did we change svg keys or add new svgs?
                svgKeyChanges.forEach(svgKeyChange => {
                    let fromSvgKey = svgKeyChange.from
                    let toSvgKey = svgKeyChange.to
                    let found = sl.counterState.customSvgs.find(csvg => csvg.svgKey === fromSvgKey)
                    if (found) {
                        found.svgKey = toSvgKey
                    }
                })
                changeSvgIdFromTo.forEach(fromTo => {
                    let fromSvgKey = fromTo.from
                    let toSvgKey = fromTo.to
                    let found = sl.counterState.customSvgs.find(csvg => csvg.svgKey === fromSvgKey)
                    if (found) {
                        found.svgKey = toSvgKey
                    }
                })
                sl.counterState.customSvgs.forEach(cSvg => {
                    let svgType = 'svg'
                    if (cSvg.svgCode.includes('svgxlink:href') && cSvg.svgCode.includes('<image')) {
                        svgType = 'image'
                    }
                    if (svgType === 'svg') {
                        customSvgKeys.push(cSvg.svgKey)
                    }
                    if (svgType === 'image') {
                        customImageKeys.push(cSvg.svgKey)
                    }
                })
                let stringyAlv = JSON.stringify(slotActiveLayerValues)
                let slotCounterSideActive = {
                    active: "front",
                    front: stringyAlv,
                    index: 0,
                    rear: stringyAlv,
                }
                let fonts = extractFontsUsed(sl.svg)
                
                sl.counterState.package = {
                    counterSideActive: slotCounterSideActive,
                    customImageSvgKeys: customImageKeys,
                    customSvgKeys: customSvgKeys,
                    fonts: fonts,
                    layers: slotLayers
                }
                delete sl.svg
                delete sl.counterState.activeLayerValues
                delete sl.counterState.customSvgs
                delete sl.counterState.layers
            }
        })

        let fontsData = []
        newSvgs.forEach(svg => {
            delete svg.svgType
        })

        function removeLastInstance(badtext, str) {
            var charpos = str.lastIndexOf(badtext);
            if (charpos < 0) return str;
            let ptone = str.substring(0, charpos);
            let pttwo = str.substring(charpos + (badtext.length));
            return (ptone + pttwo);
        }

        // make any custom svgs or images layers to layeHidden = 0 if it got new items added to it.
        if (newSvgs.length > 0) {
            let customLayer = stateLayers.find(ly => ly.layerName === 'custom svgs')
            let input = customLayer.inputs.find(li => li.named === 'svgKey')
            if (input.list.length > 0) {
                customLayer.layerHidden = 0
            }
            customLayer = stateLayers.find(ly => ly.layerName === 'custom images')
            input = customLayer.inputs.find(li => li.named === 'svgKey')
            if (input.list.length > 0) {
                customLayer.layerHidden = 0
            }
            newSvgs.forEach(svg => {
                let endWrapperSvgTag = svg.svgCode.indexOf('/defs>')
                let cutSvgCode = svg.svgCode.substring(endWrapperSvgTag + 6)
                let startXmlVersion = cutSvgCode.indexOf('<!--?xml')
                if (startXmlVersion > -1) {
                    let endXmlVersion = cutSvgCode.indexOf('>', startXmlVersion)
                    if (endXmlVersion > -1) {
                        cutSvgCode = cutSvgCode.substring(endXmlVersion + 1)
                    }
                }
                cutSvgCode = cutSvgCode.replace('<!-- Created with Inkscape (http://www.inkscape.org/) -->', '')
                let newSvgCode = removeLastInstance('</svg>', cutSvgCode)
                svg.svgCode = newSvgCode
            })

        }

        // create counterSideActive
        let stringyAlv = JSON.stringify(activeLayerValues)
        let counterSideActive = {
            active: "front",
            front: stringyAlv,
            index: 0,
            rear: stringyAlv,
        }

        let layerKeysThatShouldBeActive = Utility.layerKeysActive(activeLayerValues)
        stateLayers.forEach(sl => {
            sl.layerActive = 0
            if (layerKeysThatShouldBeActive.includes(sl.layerKey)) {
                sl.layerActive = 1
            }
        })

        // almost forgot
        newSvgs.forEach(ns => delete ns.svgCodeStripped)

        workspace.counterSideActive = [counterSideActive]
        workspace.activeLayerValues = stateActiveLayerValuesToDexie(activeLayerValues)
        //console.log('counterSideActive.front:', JSON.parse(counterSideActive.front))
        //console.log('counterSideActive.rear:', JSON.parse(counterSideActive.rear))
        workspace.fonts = workspace.fonts
        workspace.layers = stateLayers
        workspace.savedCounters = []
        workspace.savedSheets = []
        workspace.sheetSettings = [sheetSettings]
        workspace.slots = slots
        workspace.svgs = ([...state.svgs, ...newSvgs]) // the new svgs will need to be sent to state, and custom layers need their input svg list adjusted.
        console.log('modified workspace:', workspace)

        return workspace
    }

    const createDuplicateLayer = (layers, dupeThisLayer) => {
        let newLayer = null
        let updatedStateLayers = [...layers]
        let parentLayer = updatedStateLayers.find(sl => sl.layerKey === dupeThisLayer.parentLayerKey)
        if (parentLayer) {
            newLayer = { ...parentLayer }
            newLayer.parentLayerKey = newLayer.layerKey
            newLayer.layerOrder = newLayer.layerOrder + 0.5
            let maxLayerKey = Math.max(...updatedStateLayers.map(ly => parseInt(ly.layerKey)), 0)
            newLayer.layerKey = maxLayerKey + 1
            newLayer.layerHidden = 0
            newLayer.layerActive = 1
            // check name to avoid dupe names
            let incNum = 2
            let dupeName = dupeThisLayer.layerName
            while (updatedStateLayers.find(nl => nl.layerName === dupeName)) {
                dupeName = newLayer.layerName + incNum
                incNum++
                if (incNum > 100) {
                    break
                }
            }
            newLayer.layerName = dupeName
            // fix up orderings, since we're jamming in a new layer inbetween two (unless its at the end)
            updatedStateLayers.push(newLayer)
            updatedStateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            updatedStateLayers.forEach((ly, index) => {
                ly.layerOrder = index
            })

        }
        return { updatedStateLayers: updatedStateLayers, newLayerKey: newLayer.layerKey }
    }

    const extractNativeViewBoxArray = str => {
        // find out if there is a viewBox coming in on this svg.
        let viewBoxString = ''
        let _viewBoxNativeArray = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let beginningViewboxStringIndex = svgTag.indexOf('viewBox="')
            if (beginningViewboxStringIndex > -1) {
                let endingViewboxStringIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 1)
                if ((beginningViewboxStringIndex > -1 &&
                    endingViewboxStringIndex > -1) &&
                    (endingViewboxStringIndex > beginningViewboxStringIndex)) {
                    let viewboxEndIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 10)
                    if (viewboxEndIndex > -1) {
                        viewBoxString = svgTag.substring(beginningViewboxStringIndex + 9, viewboxEndIndex)
                        viewBoxString = viewBoxString.replaceAll(',', ' ')
                        viewBoxString = viewBoxString.replace(/  +/g, ' ');
                        _viewBoxNativeArray = viewBoxString.split(' ').map(n => Number(n))
                        if (!_viewBoxNativeArray ||
                            !Array.isArray(_viewBoxNativeArray) ||
                            _viewBoxNativeArray.length !== 4) {
                            _viewBoxNativeArray = null
                        }
                    }
                }
            }
        }

        return _viewBoxNativeArray
    }

    const extractTopSvgTag = str => {
        let startSvgTag = str.indexOf('<svg')
        if (startSvgTag > -1) {
            let endSvgTag = str.indexOf('>', startSvgTag + 1)
            if ((startSvgTag > -1 && endSvgTag > -1) && (endSvgTag > startSvgTag)) {
                return str.substring(startSvgTag, endSvgTag)
            }
        }
        return null
    }

    const extractNativeWidthHeightStrings = str => {
        let nativeWidthString = null
        let nativeHeightString = null

        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            splitted.forEach(str => {
                if (str.startsWith('width=')) {
                    nativeWidthString = str
                }
                if (str.startsWith('height=')) {
                    nativeHeightString = str
                }
            })

        }

        if (nativeWidthString && nativeHeightString) {
            return { widthString: nativeWidthString, heightString: nativeHeightString }
        }
        return null
    }

    const configureSvg = svg => {
        let svgString = svg
        svgString = svgString.replace('<?xml version="1.0"?>', '')
        svgString = svgString.trim()
        svgString = svgString.replace(/\s+/g, ' ');
        svgString = svgString.replace(/\t|\n|\r/g, ' ')

        let parsed = Snap.parse(svgString)
        let nativeId = parsed.node.id

        const prependText = 'p' + Utility.randomString(5) + '_'
        setIdPrependText(prependText)
        // if there isn't an id on it, give it one.
        if (nativeId === null || nativeId === undefined || nativeId.length === 0) {
            svgString = svgString.replace('<svg', '<svg id="' + svgId + '"')
        }
        else {
            svgString = svgString.replace('id="' + nativeId + '"', 'id="' + svgId + '"')
        }



        let nativeWidthHeightStrings = extractNativeWidthHeightStrings(svgString)
        let nativeWidth = 0
        let nativeHeight = 0
        let vbFromWidthHeightInspection = ''
        if (nativeWidthHeightStrings) {
            nativeWidth = (nativeWidthHeightStrings.widthString)
            nativeWidth = nativeWidth.replace(/[^0-9.]/g, '')
            nativeHeight = (nativeWidthHeightStrings.heightString)
            nativeHeight = nativeHeight.replace(/[^0-9.]/g, '')
            nativeWidth = Math.round(nativeWidth)
            nativeHeight = Math.round(nativeHeight)
            const viewBoxString = "0 0 " + nativeWidth + " " + nativeHeight
            vbFromWidthHeightInspection = viewBoxString
            svgString = svgString.replace(nativeWidthHeightStrings.widthString, '')
            svgString = svgString.replace(nativeWidthHeightStrings.heightString, '')
        }

        // deploy the incoming svg
        parsed = Snap.parse(svgString)
        paperTarget.append(parsed)



        let nativeViewBox = extractNativeViewBoxArray(svgString)
        if (!nativeViewBox) {
            let node = paperTarget.select('#' + svgId)
            if (node) {
                let bbox = node.getBBox()
                if (bbox) {
                    // lets try padding a bit since some svgs seem to use shadows or stroke widths that extend beyond the
                    // width and height settings of the svg.
                    bbox.x -= bbox.width * 0.03
                    bbox.y -= bbox.height * 0.03
                    bbox.width += bbox.width * 0.06
                    bbox.height += bbox.height * 0.06
                    bbox.x = Math.round(bbox.x)
                    bbox.y = Math.round(bbox.y)
                    bbox.width = Math.round(bbox.width)
                    bbox.height = Math.round(bbox.height)
                    let replacementViewBox = 'viewBox="' + bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height + '"'
                    svgString = svgString.replace('id="' + svgId + '"', replacementViewBox + ' id="' + svgId + '"')
                    paperTarget.clear()
                    parsed = Snap.parse(svgString)
                    paperTarget.append(parsed)
                    replacementViewBox = replacementViewBox.replace('viewBox="', '')
                    replacementViewBox = replacementViewBox.replace('"', '')
                    //setViewBoxArray(replacementViewBox)
                }
            }
        }
        else {
            vbFromWidthHeightInspection = vbFromWidthHeightInspection.replace('viewBox="', '')
            vbFromWidthHeightInspection = vbFromWidthHeightInspection.replace('"', '')
            //setViewBoxArray(vbFromWidthHeightInspection)
        }

        // get ids that are in the svg
        let node2 = paperTarget.select('#' + svgId)
        let foundIds = Utility.distillIdsFromSnapElement(paperTarget.select('#' + svgId))
        let foundHrefs = Utility.distillHrefsFromSnapElement(paperTarget.select('#' + svgId))
        let allIds = [...foundIds, ...foundHrefs]
        allIds = [...new Set(allIds)];
        // // replace all ids with a unique prepend
        svgString = Utility.replaceIdsInSvgString(allIds, svgString, prependText)
        // now clear the target and redeploy
        paperTarget.clear()
        parsed = Snap.parse(svgString)
        paperTarget.append(parsed)
        // setSvgModifiedSvg(svgString)
        //  setSvgLoadError('')
    }

    const extractFontsUsed = (svg) => {
        let fonts = []
        let checkForFontsText = svg.replaceAll('&quot;', '"')
        checkForFontsText = checkForFontsText.replaceAll('font-family: ', 'font-family:')
        let foundAt = 0
        let exitAt = 1000
        while (foundAt > -1) {
            foundAt = checkForFontsText.indexOf('font-family:', foundAt + 12)
            if (foundAt > -1) {
                let endQuote = checkForFontsText.indexOf('"', foundAt + 12)
                let endSemiColon = checkForFontsText.indexOf(';', foundAt + 12)
                if (endSemiColon < endQuote && endSemiColon > 0) {
                    endQuote = endSemiColon
                }
                if (endQuote > -1) {
                    let fontFamilyText = checkForFontsText.substring(foundAt + 12, endQuote)
                    if( ! fonts.find(font => font === fontFamilyText.trim())) {
                        fonts.push(fontFamilyText.trim())
                    }
                }
            }
            exitAt--
            if (exitAt === 0) {
                break
            }
        }
        // the regex is broken, not sure why. Its not finding any font-family.
        // let matches = checkForFontsText.matchAll(/font-family:\".*?"/g);
        // console.log('matches:', matches)
        // if (matches) {
        //     for (const ffont of matches) {
        //         console.log('ffont:', ffont)
        //         let matchedItem = ffont[0]
        //         let splitted = matchedItem.split(':')
        //         if (splitted && splitted.length === 2) {
        //             let fontName = splitted[1].replaceAll('"', '')
        //             if (fontName && fonts.includes(fontName) === false) {
        //                 fonts.push(fontName)
        //             }
        //         }
        //     }
        // }

        return fonts
    }

    const importSvgKeyChanges = (svgKeyChanges, customImageLayers, customSvgLayers, activeLayerValues) => {
        svgKeyChanges.forEach(fromTo => {
            let fromSvgKey = fromTo.from
            let toSvgKey = fromTo.to
            let svgType = fromTo.svgType
            let layers = [...customImageLayers, ...customSvgLayers]
            layers.forEach(ly => {
                let requiredInputKey = ly.layerActiveRequiredInputKey
                if (activeLayerValues[ly.layerKey + '_' + requiredInputKey] &&
                    activeLayerValues[ly.layerKey + '_' + requiredInputKey] === fromSvgKey) {
                    activeLayerValues[ly.layerKey + '_' + requiredInputKey] = toSvgKey
                }
                // change the svgKey from the layer input list
                let input = ly.inputs.find(li => li.named === 'svgKey')
                if (input) {
                    if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                        let list = [...input.list]
                        if (customImageLayers.find(cil => cil.layerKey === ly.layerKey) && svgType === 'image') {
                            if (list.includes(fromSvgKey)) {
                                list = list.filter(li => li !== fromSvgKey)
                            }
                            if (list.includes(toSvgKey) === false) {
                                list.push(toSvgKey)
                            }
                        }
                        if (customSvgLayers.find(cil => cil.layerKey === ly.layerKey) && svgType === 'svg') {
                            if (list.includes(fromSvgKey)) {
                                list = list.filter(li => li !== fromSvgKey)
                            }
                            if (list.includes(toSvgKey) === false) {
                                list.push(toSvgKey)
                            }
                        }

                        input.list = list
                    }
                }
            })
        })

        return { customImages: customImageLayers, customSvgs: customSvgLayers }
    }

    const stateActiveLayerValuesToDexie = (data) => {
        if (data) {
            let dexieCompatible = []
            for (const [combinedKey, value] of Object.entries(data)) {
                dexieCompatible.push({ lik: combinedKey, value })
            }
            return dexieCompatible
        }
    }

    const loadDatabaseBackup = stores => {
        let abort = false
        //console.log('stores:', stores)
        let storesData = [
            { store: "counterSideActive", data: null, count: 0, 'required': true },
            { store: "activeLayerValues", data: null, count: 0, 'required': true },
            { store: "fonts", data: null, count: 0, 'required': true },
            { store: "layers", data: null, count: 0, 'required': true },
            { store: "savedCounters", data: null, count: 0, 'required': false },
            { store: "savedSheets", data: null, count: 0, 'required': false },
            { store: "sheetSettings", data: null, count: 0, 'required': true },
            { store: "slots", data: null, count: 0, 'required': true },
            { store: "svgs", data: null, count: 0, 'required': true }]
        //console.log('loadDatabaseBackup:', stores)
        for (const [store, data] of Object.entries(stores)) {
            //console.log(store, '-', data.length)
            let entry = storesData.find(sd => sd.store === store)
            if (entry) {
                if (entry.required && data.length === 0) {
                    console.error('file is bad, required data for', store, ' is empty')
                    abort = true
                    break
                }
                entry.data = data
                entry.count = data.length
            }
        }
        if (!abort) {
            storesData = storesData.filter(sd => sd.count > 0)
            //console.log('load backup into app:', storesData)
            actions.importDatabase(storesData)
        }
    }

    useEffect(() => {
        if (databaseToRestore) {
            console.log('databaseToRestore:', databaseToRestore)
            //setDatabaseToRestore(null)
        }
    }, [databaseToRestore])

    const onClickLoadFile = evt => {
        setLoadWorkspaceFileInvalid(false)
        setLoadWorkspaceErrors([])
    }

    const onClickExecuteImport = async () => {
        if (databaseToRestore) {
            actions.importDatabase(databaseToRestore.workspace)
        }
    }

    const onClickCancelImport = () => {
        setDatabaseToRestore(null)
    }

    return (
        <div className="view-app">


            <div className="entry export">
                <div><button className={showExportDatabaseForm ? 'standard-button disabled' : 'standard-button blue'} onClick={showExportDatabaseForm ? null : clickExportDatabase}>export database</button></div>
                <div className="info">
                    <div className="title">Export entire app database to a file</div>
                    <div className="export-container">
                        <div className={showExportDatabaseForm ? 'comments hide' : 'comments'}>
                            <p>
                                You can save the database, basically the "workspace" of the app, to an external file. This file can then be
                                imported later to restore the workspace.
                            </p>Export
                            <p>
                                This gives you a backup of the current workspace that lets you put everything back the way it was.
                                The file can also be used to reproduce the same workspace on a different instance of the app,
                                such as on a different browser, different computer, or sent to a colleague who can load your
                                workspace into their app instance.
                            </p>
                            <p>
                                This has nothing to do with counters or sheets that were saved to files. Those aren't affected.
                            </p>
                        </div>
                        <div className={showExportDatabaseForm ? 'export-database-form' : 'display-none'}>
                            export database filename: <input ref={exportDatabaseFilenameRef} value={exportDatabaseFilename} onChange={onChangeExportDatabaseFilename} type="text" />
                            <button className={exportDatabaseFilename.length > 1 ? 'standard-button blue' : 'standard-button disabled'} onClick={exportDatabaseFilename.length > 1 ? exportDatabase : null}>submit</button>
                            <button className="standard-button yellow" onClick={() => setShowExportDatabaseForm(false)}>cancel</button>
                            <div className="append-date" onClick={appendDateToText}>click to append date to end of filename</div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="entry import">

                {databaseToRestore ?
                    <div className="import-ready">
                        <p>Import is ready to install:</p>
                        <div className="import-info-box">
                            <p>import name: <span>{databaseToRestore.fileName}</span></p>
                            <p>date created: <span>{databaseToRestore.dateSaved}</span></p>
                            <div className="action-buttons">
                                <button className="standard-button blue" onClick={onClickExecuteImport}>execute import</button>
                                <button className="standard-button yellow" onClick={onClickCancelImport}>cancel</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="import-form">
                        <input type="file" id="sheet_file" accept=".zip" onClick={onClickLoadFile} onChange={onChangeLoadFromFile} />
                        {loadWorkspaceFileInvalid ?
                            <div className="error">{loadWorkspaceFileInvalid}</div>
                            : ''
                        }
                        {loadWorkspaceErrors.length > 0 ?
                            <div className="error">
                                <div>There are problems:</div>
                                {loadWorkspaceErrors.map((err, index) => <div key={index}>{err.table}: {err.error}</div>)}
                            </div>
                            : ''}
                    </div>


                }


                <div className="entry-import-form">
                    <div className="info">
                        <div className="title">Import database from file</div>
                        <div className="comments">
                            <p>
                                You can restore the workspace of this app by importing a database file
                                that you previously saved.
                            </p>
                            <p>
                                This action will remove any customizations you have made to the app as it exists now,
                                including any custom svgs or images, any counters or sheets that are currently
                                saved in the app, and it will replace your sheet settings.
                            </p>
                        </div>
                    </div>
                </div>
            </div>





            <div className="entry reset">

                <div className="entry-reset-buttons">

                    {resetDatabaseErrorMessage ?
                        <div className="error">{resetDatabaseErrorMessage}</div>
                        :
                        <button className={resetDatabaseConfirm ? 'standard-button red' : 'standard-button yellow'} onClick={clickResetDatabase}>
                            <div className="text-options">
                                <span className={resetDatabaseConfirm ? 'disappear' : ''}>reset database</span>
                                <span className={resetDatabaseConfirm ? 'reset-confirm' : 'reset-confirm disappear'}>are you sure?</span>
                            </div>
                        </button>

                    }
                </div>



                <div className="info">
                    <div className="title">Reset database to default settings</div>
                    <div className="comments">
                        <p>
                            If the database on this app has a problem, is corrupted somehow, or if you just want to erase all changes
                            you made to it, you can reset the database to its original state.
                        </p>
                        <p>
                            This will remove all saved counters and sheets that have been saved in the app. I will also all remove
                            any duplicated menu layers, and all custom svgs and images. The sheet settings will also be reverted to defaults.
                        </p>
                        <p>
                            This will not affect any saved counters or saved sheets you may have saved as files to your computer system.
                        </p>
                    </div>
                </div>
            </div>

            <svg id="paperTarget" />

        </div>
    );
}
export default ViewApp

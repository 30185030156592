import React, { useEffect, useContext, useState } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import './ThreeDeeViewButton.scss'
import { StoreContext } from "../../context/StoreContext";
const ThreeDeeViewButton = ({reportThreeDeeActive}) => {
    const controller = new AbortController();
    const { signal } = controller;
    const signalmxy = useSignal(null)
    const { actions, state } = useContext(StoreContext)
    const [active, setActive] = useState(false)

    useEffect(() => {

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const click3D = evt => {
        setActive( evt.target.id === 'exit3D' ? false : true )
        actions.colorPicker({ x: -1, y: -1, hexColor: null })
        actions.threeDeeViewActive( evt.target.id === 'exit3D' ? false : true )
    }

    useEffect(() => {
       if( state.colorPicker.x > -1 ) {
          // if color picker gets turned on
          if( active ) {
            setActive(false)
            actions.threeDeeViewActive(false)
          }
       }
    }, [state.colorPicker])

    useEffect(() => {
        reportThreeDeeActive(active)
    }, [active])

    return (
        <div className="three-dee-view-button">
            {active ?
                <div><button className="standard-button yellow" id="exit3D" onClick={click3D}>Exit 3D</button></div>
                :
                <div><button className="standard-button blue" id="enter3D" onClick={click3D}>3D</button></div>
            }
        </div>
    )

}
export default ThreeDeeViewButton
import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const Rectangle = {

    draw: (g, settings) => {
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'label': break
                case 'variable spacer': break
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'center xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.centerXpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.centerYpos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'fill color':
                    derivedValues.fillColor = setting.value
                    break
                case 'stroke color':
                    derivedValues.stroke = setting.value
                    break
                case 'stroke width':
                    derivedValues.strokeWidth = Number(setting.value)
                    break
                case 'rotation':
                    derivedValues.rotation = Number(setting.value)
                    break
                case 'opacity':
                    derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'round corners':
                    derivedValues.roundCorners = Number(setting.value)
                    break
                case 'spacer': break
                default: console.warn('we seem to have missed one: ', setting.name, ' with value ', setting.value, ' setting:', setting)
            }
        })
        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }
        Rectangle.drawRectangle(g, derivedValues)

        if (derivedValues.opacity < 1) {
            g.attr({ "opacity": derivedValues.opacity })
        }

        if (derivedValues.rotation > 0) {
            Rectangle.applyRotation(g, derivedValues.rotation)
        }

        if (derivedValues.centerXpos !== 0 || derivedValues.centerYpos !== 0) {
            Rectangle.applyTranslation(g, derivedValues.centerXpos, derivedValues.centerYpos)
        }
    },

    createRoundedPathString: (pathCoords, curveRadius) => {
        const path = [];
        // Reset indexes, so there are no gaps
        pathCoords = pathCoords.slice();
        for (let i = 0; i < pathCoords.length; i++) {

            // 1. Get current coord and the next two (startpoint, cornerpoint, endpoint) to calculate rounded curve
            const c2Index = ((i + 1) > pathCoords.length - 1) ? (i + 1) % pathCoords.length : i + 1;
            const c3Index = ((i + 2) > pathCoords.length - 1) ? (i + 2) % pathCoords.length : i + 2;

            const c1 = pathCoords[i];
            const c2 = pathCoords[c2Index];
            const c3 = pathCoords[c3Index];

            // 2. For each 3 coords, enter two new path commands: Line to start of curve, bezier curve around corner.

            // Calculate curvePoint c1 -> c2
            const c1c2Distance = Math.sqrt(Math.pow(c1.x - c2.x, 2) + Math.pow(c1.y - c2.y, 2));
            const c1c2DistanceRatio = (c1c2Distance - curveRadius) / c1c2Distance;
            const c1c2CurvePoint = [
                ((1 - c1c2DistanceRatio) * c1.x + c1c2DistanceRatio * c2.x).toFixed(2),
                ((1 - c1c2DistanceRatio) * c1.y + c1c2DistanceRatio * c2.y).toFixed(2)
            ];

            // Calculate curvePoint c2 -> c3
            const c2c3Distance = Math.sqrt(Math.pow(c2.x - c3.x, 2) + Math.pow(c2.y - c3.y, 2));
            const c2c3DistanceRatio = curveRadius / c2c3Distance;
            const c2c3CurvePoint = [
                ((1 - c2c3DistanceRatio) * c2.x + c2c3DistanceRatio * c3.x).toFixed(2),
                ((1 - c2c3DistanceRatio) * c2.y + c2c3DistanceRatio * c3.y).toFixed(2)
            ];

            // If at last coord of polygon, also save that as starting point
            if (i === pathCoords.length - 1) {
                path.unshift('M' + c2c3CurvePoint.join(','));
            }

            // Line to start of curve (L endcoord)
            path.push('L' + c1c2CurvePoint.join(','));
            // Bezier line around curve (Q controlcoord endcoord)
            path.push('Q' + c2.x + ',' + c2.y + ',' + c2c3CurvePoint.join(','));
        }
        // Logically connect path to starting point again (shouldn't be necessary as path ends there anyway, but seems cleaner)
        path.push('Z');

        return path.join(' ');
    },

    drawRectangle: (g, derivedValues) => {
        let x = 0 - Utility.roundFloat(derivedValues.width / 2, 2)
        let y = 0 - Utility.roundFloat(derivedValues.height / 2, 2)
        let width = derivedValues.width
        let height = derivedValues.height
        let smallestSide = Math.min(width, height)
        let radius = (derivedValues.roundCorners / 100) * (smallestSide / 2)

        let fillColorObj = derivedValues.fillColor
        let fillInfo = ''
        if (fillColorObj.fillType === 'solid') {
            fillInfo = fillColorObj.fillColor
        }
        else { // gradient
            let type = derivedValues.fillColor.gradientDirection === 'radial' ? 'r' : 'l'
            let pxStart = derivedValues.fillColor.xyPositionStart.split(',')[0]
            let pyStart = derivedValues.fillColor.xyPositionStart.split(',')[1]
            let pxEnd = derivedValues.fillColor.xyPositionEnd.split(',')[0]
            let pyEnd = derivedValues.fillColor.xyPositionEnd.split(',')[1]
            let color1 = derivedValues.fillColor.colorStart
            let color2 = derivedValues.fillColor.colorMid
            let color3 = derivedValues.fillColor.colorEnd
            let defg = `${type}(${pxStart}, ${pyStart}, ${pxEnd}, ${pyEnd})${color1}-${color2}-${color3}`
            let parent = g.parent()
            var cg = parent.gradient(defg);
            fillInfo = cg
        }
        derivedValues.fillInfo = fillInfo

        let d = [{ x, y },
        { x: (x + width), y },
        { x: (x + width), y: (y + height) }, { x, y: (y + height) }]
        let roundedD = Rectangle.createRoundedPathString(d, radius)
        g.path(roundedD).attr({
            stroke: derivedValues.stroke,
            strokeWidth: derivedValues.strokeWidth,
            fill: fillInfo
        })

    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
    
    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
}

export default Rectangle
import React, { useContext, useState, useEffect } from 'react'
import { StoreContext } from "../../context/StoreContext"
import CloseX from "../CloseX/CloseX"
import InstallCustomSvg from "../InstallCustomSvg/InstallCustomSvg"
import InstallCustomImage from "../InstallCustomImage/InstallCustomImage"
import ViewCustoms from "../ViewCustoms/ViewCustoms"
import ViewFonts from "../ViewFonts/ViewFonts"
import InstallFont from "../InstallFont/InstallFont"
import ViewSavedCounters from "../ViewSavedCounters/ViewSavedCounters"
import ViewSavedSheets from "../ViewSavedSheets/ViewSavedSheets"
import ViewApp from "../ViewApp/ViewApp"
import './TopMenuView.scss'

const TopMenuView = ({ page }) => {
    const { state, actions } = useContext(StoreContext)
    const [optionalInfo, setOptionalInfo] = useState('')
    const [add, setAdd] = useState(false)

    const close = () => {
        setOptionalInfo('')
        actions.topMenuView(null)
    }

    const onAdd = evt => {
        setAdd(!add)
    }

    useEffect(() => {
        setAdd(false)
        setOptionalInfo('')
    }, [page])

    const removePlural = str => {
        if (str.endsWith('s')) {
            return str.substring(0, str.length - 1)
        }
    }

    const optionalInfoUpdate = message => {
        setOptionalInfo(message)
    }

    return (
        <div className="top-menu-view">
            <div className="sticky-top">
                <div className="top">
                    <div className="left-side">
                        <div>{page}</div>
                        <div className={page === 'fonts' ? 'wider':''}>{optionalInfo ? <span>{optionalInfo}</span> : ''}</div>
                        {page === 'images' || page === 'svgs' || page === 'fonts' ?
                            <div>{add ? '' :
                                <button id={`add_${page}`} className="standard-button blue"
                                    onClick={onAdd}>Add {removePlural(page)}</button>}
                            </div>
                            : ''}
                    </div>
                    <CloseX close={close} />
                </div>
            </div>
            {page === 'app' ?
                <ViewApp mode={page} close={close} optionalInfo={optionalInfoUpdate} /> : ''
            }
            {page === 'images' || page === 'svgs' ?
                add ?
                    page === 'images' ?
                        <InstallCustomImage mode={page} close={close} add={add} optionalInfo={optionalInfoUpdate} /> :
                        <InstallCustomSvg mode={page} close={close} add={add} optionalInfo={optionalInfoUpdate} /> :
                    <ViewCustoms mode={page} close={close} add={add} optionalInfo={optionalInfoUpdate} />
                : ''
            }
            {page === 'fonts' ?
                add ?
                    <InstallFont mode={page} close={close} add={add} optionalInfo={optionalInfoUpdate} /> :
                    <ViewFonts mode={page} close={close} add={add} optionalInfo={optionalInfoUpdate} />
                : ''
            }
            {page === 'saved counters' ?
                <ViewSavedCounters mode={page} close={close} optionalInfo={optionalInfoUpdate} /> : ''
            }
            {page === 'saved sheets' ?
                <ViewSavedSheets mode={page} close={close} optionalInfo={optionalInfoUpdate} /> : ''
            }
            {/* {page === 'install custom svg' ? <InstallCustomSvg close={close} /> : null}
        {page === 'view custom svgs' ? <ViewCustoms mode="svgs" close={close} /> : null}
        {page === 'install custom image' ? <InstallCustomImage close={close} /> : null}
        {page === 'view custom images' ? <ViewCustoms mode="images" close={close} /> : null}
        {page === 'view saved counters' ? <ViewSavedCounters mode="savedCounters" close={close} /> : null}
        {page === 'install font' ? <InstallFont close={close} /> : null}
        {page === 'view fonts' ? <ViewFonts mode="fonts" close={close} /> : null} */}
        </div>
    )
}
export default TopMenuView
import React from 'react'
import './DexieActivityIcon.scss'

const DexieActivityIcon = ({dexieActivity}) => {
    return <div className="dexie-activity-icon">
              <div>{dexieActivity}</div>
              <div className={dexieActivity ? 'dual-ring' : 'display-none'} />
            </div>
}

export default DexieActivityIcon;
import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import { useSignal } from "@preact/signals-react"
import './InputSlider.scss'
const InputSlider = ({ layerKey, input, setValue, useValue, disabled }) => {
    const [rangeval, setRangeval] = useState(useValue)
    const [rangevalText, setRangevalText] = useState(useValue)
    const [minRange, setMinRange] = useState(1)
    const [maxRange, setMaxRange] = useState(100)
    const [initialEffect, setInitialEffect] = useState(true)
    const [stepValue, setStepValue] = useState(1)
    const [readoutDecoration, setReadoutDecoration] = useState('')
    const [warningOnInput, setWarningOnInput] = useState(false)
    const { state } = useContext(StoreContext)
    const signalChange = useSignal(null)
    const [waiting, setWaiting] = useState(null)

    useEffect(() => {

        let arr = Utility.convertPostgresArrayToArray(input.defaultArrayMinMaxValue)
        if (input.comment && input.comment.includes('percent')) {
            setReadoutDecoration('%')
        }
        if (input.comment && input.comment.includes('degrees')) {
            setReadoutDecoration('°')
        }
        if (input.comment && input.comment.includes('divide by 10')) {
            setStepValue(0.1)
        }
        else {
            setStepValue(0.1) // force it to 0.1
        }
        if (input.comment && input.comment.includes('use x')) {
            setReadoutDecoration('x')
            setStepValue(0.1)
        }

        let minVal = arr[0]
        let maxVal = arr[1]
        setMinRange(minVal)
        setMaxRange(maxVal)
        return () => {
            if (waiting) {
                clearTimeout(waiting)
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (rangeval === null || rangeval === undefined) {
            return
        }
        if (initialEffect) {
            setInitialEffect(false)
            return
        }

        setValue(input.inputKey, rangeval)
    }, [rangeval])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (rangeval !== useValue) {
                let value = useValue
                if (stepValue === 1) {
                    value = parseInt(value)
                }
                setRangeval(value)
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeRangeVal = evt => {
        if (Utility.isNumeric(evt.target.value)) {
            let value = Utility.roundFloat(evt.target.value, 1)
            if (stepValue === 1) {
                value = parseInt(evt.target.value)
            }
            if (value < minRange || value > maxRange) {
                setWarningOnInput(true)
            }
            else {
                setWarningOnInput(false)
                signalChange.v = value
                if (waiting) {
                    return
                }
                setRangeval(value)
                setRangevalText(value)
                let _waiting = setTimeout(() => {
                    setRangeval(signalChange.v)
                    setRangevalText(signalChange.v)
                    setWaiting(null)
                }, 20)
                setWaiting(_waiting)
            }
        }
        else {
            setWarningOnInput(true)
        }
    }

    useEffect(() => {
        if (state.mouseDblClick) {
            if (state.mouseDblClick.target && state.mouseDblClick.target.id) {
                if (state.mouseDblClick.target.id === 'slider_' + layerKey + '_' + input.inputKey) {
                    let foundLayer = state.layers.find(ly => ly.layerKey === layerKey)
                    if (foundLayer) {
                        let foundInput = foundLayer.inputs.find(inp => inp.inputKey === input.inputKey)
                        if (foundInput) {
                            let intVal = foundInput.defaultIntValue
                            if (intVal !== null) {
                                setRangeval(intVal)
                                setRangevalText(intVal)
                            }
                        }
                    }
                }
            }
        }
    }, [state.mouseDblClick]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id={`input_slider_${input.inputKey}`} className="default-input-container input-slider">
            <div id={`slider_${layerKey}_${input.inputKey}`} className={`named ${disabled ? 'disabled' : ''}`}>
                {input.comment && input.comment.includes('combat')
                    && !input.comment.includes('movement')
                    ? 'combat ' : ''}
                {input.comment && input.comment.includes('movement')
                    && input.comment && !input.comment.includes('combat')
                    ? 'movement ' : ''}
                {input.comment && input.comment.includes('separator') ? 'separator ' : ''}
                {input.named}</div>

            <div className="input-flex">
                <div className="input-flex-1">
                    <input type="range" min={minRange} max={maxRange} className="input-flex-slider" id={`input_${input.inputKey}`}
                        step={stepValue}
                        value={rangeval}
                        onChange={changeRangeVal}
                        disabled={disabled}
                    />
                </div>
                <div className={warningOnInput ? 'text-readout warning' : 'text-readout'}>
                    <input className={maxRange <= 100 ? 'input-flex-slider skinny' : 'input-flex-slider'}
                        type="text" id={`input_text_${layerKey}_${input.inputKey}`} onChange={changeRangeVal}
                        value={rangevalText}
                        disabled={disabled}
                    /><span className="decoration">{readoutDecoration}</span></div>
            </div>
        </div>
    )
}

export default InputSlider
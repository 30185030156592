import React, { useState, useEffect, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './SheetOperations.scss'

const SheetOperations = ({ sheetParameters, changeSheetMenuOperationsOpened, changeGuideType, changePrintableArea }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state, actions } = useContext(StoreContext)
    const [sheetOperationsOpen, setSheetOperationsOpen] = useState(true)
    const [saveOption, setSaveOption] = useState('app')
    const [saveName, setSaveName] = useState('')
    const [saveActionsResult, setSaveActionsResult] = useState('')
    const sgLastSentGuideChange = useSignal(false)
    const [printableAreaMM_width, setPrintableAreaMM_width] = useState(sheetParameters.printableAreaMM[0])
    const [printableAreaMM_height, setPrintableAreaMM_height] = useState(sheetParameters.printableAreaMM[1])
    const [buildSheetPaper, setBuildSheetPaper] = useState(null)
    const [savedSheetsMetadata, setSavedSheetsMetadata] = useState([])
    const [selectedSheetHash, setSelectedSheetHash] = useState('')
    const [loadFrom, setLoadFrom] = useState('app')
    const [loadSheetErrorMessage, setLoadSheetErrorMessage] = useState('')

    useEffect(() => {
        let _buildSheetPaper = Snap("#build_sheet")
        if (_buildSheetPaper) {
            setBuildSheetPaper(_buildSheetPaper)
        }
        return () => {
            controller.abort();
        };

    }, [])

    useEffect(() => {
        changeSheetMenuOperationsOpened(sheetOperationsOpen)
    }, [sheetOperationsOpen])

    const onSaveName = evt => {
        let value = evt.target.value
        if (value === '') {
            setSaveName('')
            return
        }
        let regexResult = value.match(/[-_)( a-zA-Z0-9]+$/)
        if ((regexResult === null) || (regexResult && regexResult.index > 0)) {
            // _message.text = "Only alpha-numeric, spaces, and - _ ) ('"
            // _message.color = "red"
            // setMessage(_message)
            return
        }
        setSaveName(value)
    }

    const saveSheet = () => {
        // if saving a sheet in app, dont include the custom svgs data or fonts data, cause that would
        // take too much memory. For saving as an external file, do add the data.
        if (saveOption === 'app') {
            let preparedSvg = prepareSheetSvg()
            let hash = Utility.cyrb53(preparedSvg, 'snapcounter_sheet_v2')
            if (state.savedSheets[hash]) {
                setSaveActionsResult('Sheet previously saved!')
                return
            }
            let compressedSvg = Utility.compressString(preparedSvg)

            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            let saveSheetObj = {}
            saveSheetObj[hash] = {
                hash: hash,
                name: saveName,
                slots: occupiedSlots,
                sheetSettings: { ...state.sheetSettings },
                compressedSvg: compressedSvg,
                dateSaved: Utility.currentDate()
            }
            actions.savedSheets(saveSheetObj)
            setSaveActionsResult('Sheet has been saved in app')

        }
        if (saveOption === 'file') {
            let preparedSvg = prepareSheetSvg()
            let hash = Utility.cyrb53(preparedSvg, 'snapcounter_sheet_v2')
            let compressedSvg = Utility.compressString(preparedSvg)

            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            const fontsData = assembleFontsData(occupiedSlots)
            const customSvgsData = assembleCustomSvgsData(occupiedSlots)

            const saveSheetData = {
                version: 3,
                type: 'sheet',
                hash: hash,
                name: saveName,
                slots: occupiedSlots,
                sheetSettings: { ...state.sheetSettings },
                compressedSvg: compressedSvg,
                fontsData,
                customSvgsData,
                dateSaved: Utility.currentDate()
            }


            var zip = new JSZip();
            zip.file(saveName + ".json", JSON.stringify(saveSheetData));
            zip.generateAsync({ type: "blob", compression: "DEFLATE" })
                .then(function (content) {
                    // see FileSaver.js
                    saveAs(content, saveName + '.scz');
                });

            setSaveActionsResult('Sheet has been saved to file')

        }

        if (saveOption === 'svg') {
            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            const fontsData = assembleFontsData(occupiedSlots)
            const preparedSvg = prepareSheetSvg(fontsData)
            const blob = new Blob([preparedSvg], { type: 'image/svg+xml' });
            saveAs(blob, saveName + '.svg');
        }

        if (saveOption === 'png') {

            const getImageDataURL = (image, type = 'png') => {
                if (type === 'jpg') type = 'jpeg';
                // const { width, height } = image;
                let width = 0
                let height = 0
                let printableWidthInches = sheetParameters.printableAreaMM[0] * 0.0393701
                let printableHeightInches = sheetParameters.printableAreaMM[1] * 0.0393701
                if (sheetParameters.height / sheetParameters.width) {
                    width = (sheetParameters.width) * ((printableWidthInches * 300) / sheetParameters.width)
                    height = (sheetParameters.height) * ((printableWidthInches * 300) / sheetParameters.width)
                }
                else {
                    width = (sheetParameters.width) * (((printableHeightInches * 300) * 300) / sheetParameters.height)
                    height = (sheetParameters.height) * ((printableHeightInches * 300) / sheetParameters.height)
                }
                const canvas = document.createElement('canvas');

                const context = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;

                if (type === 'jpeg') {
                    context.fillStyle = 'white';
                    context.fillRect(0, 0, width, height);
                }

                context.drawImage(image, 0, 0, width, height);

                return canvas.toDataURL(`image/${type}`);
            }

            const convertSvg = (svgString, type = 'png') => new Promise((res, rej) => {
                const image = new Image();
                image.onload = () => res(getImageDataURL(image, type));
                image.src = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
            });

            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            const fontsData = assembleFontsData(occupiedSlots)
            let source = prepareSheetSvg(fontsData)
            convertSvg(source, 'png').then(dataURL => {
                saveAs(dataURL, saveName + '.png');
            })



        }
    }

    const assembleCustomSvgsData = occupiedSlots => {
        let customSvgsData = []
        occupiedSlots.forEach(os => {
            os.counterState.package.customImageSvgKeys.forEach(iSvgKey => {
                if (!customSvgsData.find(csvgd => csvgd.svgKey === iSvgKey)) {
                    let foundSvg = state.svgs.find(ssvg => ssvg.svgKey === iSvgKey)
                    if (foundSvg) {
                        customSvgsData.push(foundSvg)
                    }
                }
            })
            os.counterState.package.customSvgKeys.forEach(iSvgKey => {
                if (!customSvgsData.find(csvgd => csvgd.svgKey === iSvgKey)) {
                    let foundSvg = state.svgs.find(ssvg => ssvg.svgKey === iSvgKey)
                    if (foundSvg) {
                        customSvgsData.push(foundSvg)
                    }
                }
            })
        })

        return customSvgsData
    }

    const detectFontsInALV = (alv) => {
        let fontInputKeys = []
        let fontFamilies = []

        // find layers that have fonts in them
        state.layers.forEach(ly => {
            let fontInput = ly.inputs.find(inp => inp.named === 'font')
            if (fontInput) {
                if (!fontInputKeys.includes(fontInput.inputKey)) {
                    fontInputKeys.push(fontInput.inputKey)
                }
            }
        })
        let objectKeys = Object.keys(alv)
        objectKeys.forEach(key => {
            let splitKey = key.split('_')
            if (splitKey.length === 2) {
                let inputKey = Number(splitKey[1])
                if (fontInputKeys.includes(inputKey)) {
                    if (!fontFamilies.includes(alv[key])) {
                        fontFamilies.push(alv[key])
                    }
                }
            }
        })

        return fontFamilies
    }

    const assembleFontsData = occupiedSlots => {
        let fontsUsed = []
        let fontData = []

        occupiedSlots.forEach(os => {
            if (os.counterState.fonts && Array.isArray(os.counterState.fonts) && os.counterState.fonts.length > 0) {
                fontsUsed = fontsUsed.concat(os.counterState.fonts)
            }
        })
        if (fontsUsed.length > 0) {
            fontsUsed = [...(new Set(fontsUsed))]
        }

        fontsUsed.forEach(fontFamily => {
            let fd = state.fonts.find(st => st.fontFamily === fontFamily)
            if (fd) {
                fontData.push(fd)
            }
        })

        return fontData
    }

    const insertFontsToDef = (paper, fontsData) => {
        if (!fontsData || !Array.isArray(fontsData) || fontsData.length === 0) {
            return
        }
        let styleString = '<defs><style id="fontsStyle" type="text/css">'
        fontsData.forEach(fontData => {

            styleString += "@font-face { font-family: '" + fontData.fontFamily + "'; "
            styleString += 'font-weight: ' + fontData.fontWeight + '; '
            if (fontData.fontSrc.includes("format('woff2')") === false) {
                styleString += 'src: ' + fontData.fontSrc + " format('woff2');"
            }
            else {
                styleString += 'src: ' + fontData.fontSrc + ';'
            }
            styleString += '}'
            styleString = styleString.replaceAll(';;', ';')

        })
        styleString += '</style></defs>'
        let snapFont = Snap.parse(styleString)
        paper.append(snapFont)

        const svg = paper.toString()
        return svg
    }

    useEffect(() => {
        let arr = []
        if (state.savedSheets) {
            for (const [key, value] of Object.entries(state.savedSheets)) {
                arr.push({ hash: key, name: value.name })
            }
            setSavedSheetsMetadata(arr)
        }
    }, [state.savedSheets])

    useEffect(() => {
        setPrintableAreaMM_width(sheetParameters.printableAreaMM[0])
        setPrintableAreaMM_height(sheetParameters.printableAreaMM[1])
    }, [sheetParameters])

    const prepareSheetSvg = (fontsData = []) => {
        const originalSvgSource = state.sheetPaper.toString()

        buildSheetPaper.attr({ width: sheetParameters.width, height: sheetParameters.height });
        let parsed = Snap.parse(originalSvgSource)

        if (fontsData.length > 0) {
            insertFontsToDef(buildSheetPaper, fontsData)
        }
        buildSheetPaper.append(parsed)

        let holderElements = buildSheetPaper.selectAll(`[data-type="holder"]`)
        holderElements.forEach(holder => {
            if (holder) {
                holder.remove()
            }
        })
        let holderTexts = buildSheetPaper.selectAll(`[data-type="holderText"]`);
        holderTexts.forEach(holderText => {
            if (holderText) {
                holderText.remove()
            }
        })
        let pageMargins = buildSheetPaper.selectAll(`[data-type="pageMargin"]`);
        pageMargins.forEach(pageMargin => {
            if (pageMargin) {
                pageMargin.remove()
            }
        })

        let preparedSvg = buildSheetPaper.toString()
        preparedSvg = preparedSvg.replaceAll('<defs></defs>', '')
        preparedSvg = preparedSvg.replaceAll('style="pointer-events: visiblepainted;"', '')
        buildSheetPaper.clear()
        return preparedSvg
    }

    const saveActionsOk = () => {
        setSaveActionsResult('')
    }

    const onChangeGuideType = guideType => {
        if (sgLastSentGuideChange.value !== guideType) {
            sgLastSentGuideChange.value = guideType
            changeGuideType(guideType)
        }
    }

    const onChangePrintableArea = evt => {
        let id = evt.target.id
        let value = evt.target.value
        let index = -1
        if (id.includes('width')) {
            index = 0
        }
        if (id.includes('height')) {
            index = 1
        }
        if (index > -1) {
            if (Utility.isNumeric(value)) {
                value = Utility.roundFloat(value, 3)
                if (value >= 100 && value <= 400) {
                    let printableAreaMM = [...sheetParameters.printableAreaMM]
                    printableAreaMM[index] = value
                    changePrintableArea(printableAreaMM)
                }
            }
            if (index === 0) {
                setPrintableAreaMM_width(value)
            }
            if (index === 1) {
                setPrintableAreaMM_height(value)
            }
        }
    }

    const resetPrintableArea = () => {
        changePrintableArea([207.44, 270.94])
        setPrintableAreaMM_width(207.44)
        setPrintableAreaMM_height(270.94)
    }

    const printableAreaIsAtDefaults = () => {
        return printableAreaMM_width === 207.44 && printableAreaMM_height === 270.94
    }

    const validPrintableAreaWidth = () => {
        if (printableAreaMM_width === '') {
            return false
        }
        if (Utility.isNumeric(printableAreaMM_width)) {
            let v = Math.round(printableAreaMM_width)
            if (v < 100 || v > 500) {
                return false
            }
            return true
        }

        return false
    }

    const validPrintableAreaHeight = () => {
        if (printableAreaMM_height === '') {
            return false
        }
        if (Utility.isNumeric(printableAreaMM_height)) {
            let v = Math.round(printableAreaMM_height)
            if (v < 100 || v > 500) {
                return false
            }
            return true
        }

        return false
    }

    const loadSheetFromApp = evt => {
        let hash = evt.target.value
        if (hash) {
            let sheet = state.savedSheets[hash]
            console.log('load sheet from app:', sheet)
            if (sheet && sheet.sheetSettings && sheet.slots) {
                let sheetSettings = sheet.sheetSettings
                let slots = sheet.slots
                actions.loadSheet({ sheetSettings, slots })
                setSelectedSheetHash(hash)
            }
            else {
                console.warn('could not find saved sheet with hash', hash)
            }
        }
    }

    const onLoadFrom = from => {
        setLoadFrom(from)
    }

    const clickClearSheet = () => {
        let slots = [...state.slots]
        slots.forEach(sl => sl.counterState = null)
        actions.slots(slots)
    }

    const updateOldSlotSvg = strOld => {

        // get rid of the start <g tag and the ending /g> tag'
        let startEndG = strOld.indexOf('>')
        let newOld = strOld.substring(startEndG + 1)
        newOld = newOld.substring(0, newOld.length - 4)
        let drawLayerPos = newOld.indexOf('_drawLayer')

        while (drawLayerPos > -1) {
            let viewBoxPos = newOld.indexOf('viewBox=', drawLayerPos)
            let checkNumbersText = newOld.substring(viewBoxPos, viewBoxPos + 30)
            if (checkNumbersText.includes('-120, -120, 240, 240')) {
                newOld = newOld.substring(0, viewBoxPos) + ' width="100%" height="100%" viewBox="-100, -100, 200, 200"' + newOld.substring(viewBoxPos + 30)
            }
            else {
                newOld = newOld.replace('0, 0, 240, 240', '20, 20, 200, 200', viewBoxPos)
            }
            drawLayerPos = newOld.indexOf('_drawLayer', drawLayerPos + 10)
        }
        newOld = '<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">' + newOld + '</svg>'
        newOld = newOld.replaceAll('class="counter-svg"', '')
        newOld = newOld.replaceAll('style="pointer-events: visiblepainted; cursor: pointer;"', '')

        return newOld
    }

    const installAnyNeededFonts = fontsData => {
        if (!fontsData || fontsData.length === 0) {
            return
        }
        let installFonts = []
        fontsData.forEach(font => {
            let fontFamily = font.fontFamily
            let foundFont = state.fonts.find(sf => sf.fontFamily === fontFamily)
            if (foundFont) {
                console.log('found font in state')
            }
            else {
                console.log('did not find font in state, we need to install this font-', fontFamily)
                if (!installFonts.find(f => f.fontFamily === fontFamily)) {
                    installFonts.push(font)
                }
            }
        })
        let fonts = [...state.fonts, ...installFonts]
        console.log('installing fonts:', fonts)
        actions.fonts(fonts)
    }

    const updateStateLayersListWithNewSvgKeys = svgKeyUpdates => {
        // update the state layers of custom svg/image svg types to include the new svg keys
        let stateLayers = JSON.parse(JSON.stringify(state.layers))
        if (!svgKeyUpdates) {
            return stateLayers
        }
        let customImageSvgLayers = Utility.allLayersOfType(stateLayers, 'custom images')
        console.log('customImageSvgLayers:', customImageSvgLayers)
        let customSvgLayers = Utility.allLayersOfType(stateLayers, 'custom svgs')
        console.log('customSvgLayers: ', customSvgLayers)
        let allCustomSvgLayers = [...customImageSvgLayers, ...customSvgLayers]
        console.log('allCustomSvgLayers:', allCustomSvgLayers)
        svgKeyUpdates.forEach(svgSet => {
            console.log('svgSet:', svgSet)
            let type = svgSet.type
            let newSvgKey = svgSet.toSvgKey
            console.log('type:', type, 'newSvgKey: ', newSvgKey)
            if (type === 'image') {
                customImageSvgLayers.forEach(cil => {
                    let input = cil.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                            if (input.list.includes(newSvgKey) === false) {
                                input.list.push(newSvgKey)
                                cil.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                                cil = { ...cil }
                            }
                        }
                    }
                })

            }
            if (type === 'svg') {
                console.log('ok this is a svgSet for type svg:', svgSet)
                console.log('type: ', type)
                console.log('newSvgKey:', newSvgKey)
                customSvgLayers.forEach(csl => {
                    console.log('looking at state svg layer', csl.layerName)
                    let input = csl.inputs.find(li => li.named === 'svgKey')
                    console.log('its input is:', input)
                    if (input) {
                        if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                            console.log('does it have the newSvgKey', newSvgKey, '?')
                            if (input.list.includes(newSvgKey) === false) {
                                console.log('nope, pushing it in.')
                                input.list.push(newSvgKey)
                                csl.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                                csl = { ...csl }
                                console.log('new csl:', csl)
                            }
                            else {
                                console.log('it does have the newSvgKey', newSvgKey)
                            }
                        }
                    }
                })

            }
        })
        console.log('actions layers')
        actions.layers(stateLayers)
        return stateLayers
    }

    const updateLayersIncomingSlotsLayers = (newStateLayers, svgKeyUpdates, incomingSlots) => {
        // copy in the state layer lists to the slots layer lists, since those have already been updated with any new svgs.
        // get the new lists (may not have been changed any but thats ok - we're not going to know at this point - unless I pass more variables.
        // but lets just do it the brute force simple way cause it dont matter if we optimize it (bug liable) or not (less bug liable)
        let slots = JSON.parse(JSON.stringify(incomingSlots))
        let updatedStateLayerCustomImageList = []
        let updatedStateLayerCustomSvgList = []
        let customImageLayer = newStateLayers.find(nsl => nsl.layerName === 'custom images')
        if (customImageLayer) { // should find it unless the db is corrupt.
            let input = customImageLayer.inputs.find(li => li.named === 'svgKey')
            if (input) {
                if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                    updatedStateLayerCustomImageList = [...input.list]
                }
            }
        }
        let customSvgLayer = newStateLayers.find(nsl => nsl.layerName === 'custom svgs')
        if (customSvgLayer) { // should find it unless the db is corrupt.
            let input = customSvgLayer.inputs.find(li => li.named === 'svgKey')
            if (input) {
                if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                    updatedStateLayerCustomSvgList = [...input.list]
                }
            }
        }

        let stateCustomImagesLayer = state.layers.find(sl => sl.layerName === 'custom images')
        let layerKeyCustomImages = stateCustomImagesLayer.layerKey
        let stateCustomSvgsLayer = state.layers.find(sl => sl.layerName === 'custom svgs')
        let layerKeyCustomSvgs = stateCustomSvgsLayer.layerKey

        slots.forEach(slot => {
            if (slot.counterState) {
                let slotLayers = slot.counterState.package.layers
                slotLayers.forEach(sl => {
                    if (sl.layerKey === layerKeyCustomImages || sl.parentLayerKey === layerKeyCustomImages) {
                        let input = sl.inputs.find(li => li.named === 'svgKey')
                        if (input) {
                            if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                                input.list = updatedStateLayerCustomImageList
                            }
                        }
                    }
                    if (sl.layerKey === layerKeyCustomSvgs || sl.parentLayerKey === layerKeyCustomSvgs) {
                        let input = sl.inputs.find(li => li.named === 'svgKey')
                        if (input) {
                            if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                                input.list = updatedStateLayerCustomSvgList
                            }
                        }
                    }
                })
            }
        })

        return slots
    }

    const extractLayerKeysFromActiveLayerValues = alv => {
        let keys = Object.keys(alv)
        let layerKeys = []
        keys.forEach(key => {
            let splitted = key.split('_')
            let layerKey = Number(splitted[0])
            if (layerKeys.includes(layerKey) === false) {
                layerKeys.push(layerKey)
            }
        })

        return layerKeys
    }

    const updateLayersIncomingSlotsActiveLayerValues = (svgKeyUpdates, incomingSlots) => {
        if (!svgKeyUpdates) {
            svgKeyUpdates = []
        }
        let updatedIncomingSlots = JSON.parse(JSON.stringify(incomingSlots))
        let indexPos = -1
        updatedIncomingSlots.forEach(incomingSlot => {
            if (incomingSlot.counterState) {
                let layers = incomingSlot.counterState.package.layers
                let activeLayerValues = incomingSlot.counterState.activeLayerValues
                let customImageSvgKeys = incomingSlot.counterState.customImageSvgKeys
                let customSvgKeys = incomingSlot.counterState.customSvgKeys
                let layerKeysFromActiveLayerValues = extractLayerKeysFromActiveLayerValues(activeLayerValues)
                let customImageSvgLayers = Utility.allLayersOfType(layers, 'custom images')
                let customSvgLayers = Utility.allLayersOfType(layers, 'custom svgs')

                let allIncomingCustomLayers = [...customImageSvgLayers, ...customSvgLayers]
                for (let i = 0; i < allIncomingCustomLayers.length; i++) {
                    let targetIncomingLayer = allIncomingCustomLayers[i]
                    let lk = targetIncomingLayer.layerKey
                    let svgInputKey = targetIncomingLayer.layerActiveRequiredInputKey // if its a custom image or svg layer, it has a layerActiveRequiredIncomingInputKey
                    let currentValue = activeLayerValues[lk + '_' + svgInputKey]
                    let svgKeyUpdate = svgKeyUpdates.find(ku => ku.fromSvgKey === currentValue)
                    if (svgKeyUpdate) {
                        activeLayerValues[lk + '_' + svgInputKey] = svgKeyUpdate.toSvgKey
                        indexPos = customImageSvgKeys.indexOf(currentValue)
                        if (indexPos > -1) {
                            customImageSvgKeys[indexPos] = svgKeyUpdate.toSvgKey
                        }
                        indexPos = customSvgKeys.indexOf(currentValue)
                        if (indexPos > -1) {
                            customSvgKeys[indexPos] = svgKeyUpdate.toSvgKey
                        }
                    }
                }

            }
        })

        return updatedIncomingSlots
    }

    const installAnyNeededSvgs = (checkSvgs) => {
        console.log('installAnyNeededSvgs got checkSvgs:', checkSvgs)
        if (!checkSvgs) {
            return
        }
        console.log('checkSvgs, where is type?:', checkSvgs)
        // this will hold any svgs that need to be installed.
        let installSvgs = []
        let changeIncomingSvgKeysFromTo = []

        // this creates a list of currently existing custom svgs.
        let listOfCustomStateSvgsCleanedCode = state.svgs.filter(svg => svg.svgKey >= 2000)
        console.log('from state.svgs - installAnyNeededSvgs, listOfCustomStateSvgsCleanedCode:', listOfCustomStateSvgsCleanedCode)
        listOfCustomStateSvgsCleanedCode = JSON.parse(JSON.stringify(listOfCustomStateSvgsCleanedCode))
        // strip the svgs of any prepend strings so we can do a svgCode comparison later
        listOfCustomStateSvgsCleanedCode.forEach(css => {
            if (css.uniquePrepend) {
                css.svgCode = css.svgCode.replaceAll(css.uniquePrepend, '')
            }
        })

        // put the incoming svgs into one array so we can process them together.

        if (checkSvgs.length > 0) {

            // useKey will be the start point from adding new svgKeys
            let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
            for (let i = 0; i < checkSvgs.length; i++) {
                let incomingSvg = checkSvgs[i]
                let splittedCombined = incomingSvg.combined.split(':')
                let svgKeyFromCombined = Number(splittedCombined[0])
                let svgTypeFromCombined = splittedCombined[2]
                console.log('svgKeyFromCombined:', svgKeyFromCombined, 'svgTypeFromCombined:', svgTypeFromCombined)

                if (svgKeyFromCombined !== incomingSvg.svgKey) {
                    console.warn('svgKey mismatch! replacing incomingSvg.svgKey', incomingSvg.svgKey, ' with ', svgKeyFromCombined)
                    incomingSvg.svgKey = svgKeyFromCombined
                }
                if (svgTypeFromCombined !== incomingSvg.type) {
                    console.warn('type mismatch! replacing svg.type', incomingSvg.type, ' with ', svgTypeFromCombined)
                    incomingSvg.svgType = svgTypeFromCombined
                }

                let matchCode = incomingSvg.svgCode
                if (incomingSvg.uniquePrepend) {
                    matchCode = incomingSvg.svgCode.replaceAll(incomingSvg.uniquePrepend, '')
                }

                // see if theres a direct match. If so, nothing needs to be done.
                let foundSvg = listOfCustomStateSvgsCleanedCode.find(ssvg => ssvg.svgKey === incomingSvg.svgKey && ssvg.svgCode === matchCode)
                if (!foundSvg) {
                    console.log('no matching svg with svgKey and svgCode')
                    // see if theres a code match
                    foundSvg = listOfCustomStateSvgsCleanedCode.find(ssvg => ssvg.svgCode === matchCode)
                    if (foundSvg) {
                        console.log('we found a match on the code, but not the svgKey, for incoming', incomingSvg)
                        console.log('changing - fromSvgKey', incomingSvg.svgKey, ' to ', foundSvg.svgKey)
                        changeIncomingSvgKeysFromTo.push({ fromSvgKey: incomingSvg.svgKey, toSvgKey: foundSvg.svgKey, type: incomingSvg.type, new: false, nameIncoming: incomingSvg.svgName, nameExisting: foundSvg.svgName })
                    }
                    else {
                        console.log('did not find the svgCode in state svgs.')
                        // insert incoming svg to state as new svg

                        console.log('got highest key:', useKey)
                        if (useKey < 2000) {
                            useKey = 2000
                        }
                        else {
                            useKey++
                        }
                        console.log('new key for incoming svg:', useKey)

                        console.log('changing - fromSvgKey', incomingSvg.svgKey, ' to ', useKey)
                        changeIncomingSvgKeysFromTo.push({ fromSvgKey: incomingSvg.svgKey, toSvgKey: useKey, type: incomingSvg.type, new: true, nameIncoming: incomingSvg.svgName, nameExisting: null })
                        incomingSvg.svgKey = useKey
                        console.log('Setted this up ', incomingSvg, ' to be installed.')
                        installSvgs.push(incomingSvg)
                    }
                }
                else {
                    console.log('Incoming svg is ok - it was found with matching svgKey and code.')
                }

            }
            if (installSvgs.length === 0) {
                console.log('>>> DECISION >>>>>>>>>>>>>>>>>>> we are not installing any svgs.')
            }
            if (changeIncomingSvgKeysFromTo.length > 0) {
                console.log('>>> DECISION >>>>>>>>>>>>>>>>>>> we are changing these incoming svgKeys to updated svgKeys (may be new svgs or just pointing at a different svg in state.', changeIncomingSvgKeysFromTo)
            }
            if (installSvgs.length > 0) {
                console.log('installSvgs with extra keys:', installSvgs)
                // delete the temporarily added keys
                installSvgs.forEach(isvg => {
                    delete isvg.combined
                    delete isvg.type
                })
                console.log('installSvgs with extra keys removed:', installSvgs)
                let newStateSvgs = [...state.svgs, ...installSvgs]
                console.log('newStateSvgs set to state:', newStateSvgs)
                actions.svgs(newStateSvgs)
            }
        }

        return changeIncomingSvgKeysFromTo
    }


    /*
        incomingSvgs.forEach(isvg => {
            // see if this svg exists                        console.log('data1:', data)
            console.log('test: isvg,', isvg)
            console.log('isvg.svgKey:', isvg.svgKey)
            console.log('type of isvg.svgKey:', (typeof isvg.svgKey))
            let incomingPrependCode = isvg.uniquePrepend ? isvg.uniquePrepend : ''
            console.log('incomingPrependCode:', incomingPrependCode)
            let foundSvg = stateSvgsCustom.find(svg => svg.svgKey === isvg.svgKey &&
                svg.svgCode.replaceAll(svg.uniquePrepend, incomingPrependCode) === isvg.svgCode)
            if (foundSvg) {
                console.log('the svgKey and svgCodes match. No need to change anything.')
            }
            else {                        console.log('data1:', data)
                console.log('about to test for svgCode equivalency for isvg.svgName:', isvg.svgName)
                foundSvg = stateSvgsCustom.find(svg => svg.svgCode.replaceAll(svg.uniquePrepend, incomingPrependCode) === isvg.svgCode)
                console.log('foundSvg:', foundSvg)
                if (foundSvg) {
                    console.log('found matching svg by code, but the keys dont match.')
                    type = 'image'
                    if (isvg.uniquePrepend) {
                        type = 'svg'
                    }
                    ssvg.svgKey === svg.svgKey     updateIncomingSvgKeys.push({ incomingSvgKey: isvg.svgKey, newSvgKey: foundSvg.svgKey, type, newSvg: false, reason: "keys dont match, but we found a state svg entry that has the same svgCode. Change incoming references of the svgCode to the newSvgCode." })
                }
                else {svgKeysFromOldVersionSlot(slot.counterState, changeIncomingSvgKeysFromTo, 'image')
                    // this incoming svg apparently is new. New install.
                    console.log('we need to install this puppy.')
                    // get the next available key (above 2000 to stay away from standard svgKey range)
                    let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey;
                    console.log('got highest key:', useKey, 'and repeatedInserts is:', repeatedInserts)endTagPos
     
                    if (useKey < 2000) {
                        useKey = 2000
                    }
                    else {
                        useKey++
                    }
                    useKey = Number(useKey) + repeatedInserts
                    console.log('and plus repeatedInserts', repeatedInserts, ' is: ', useKey)
                    let incomingSvgKey = isvg.svgKey
                    isvg.svgKey = useKey
                    console.log('incomingSvgKey:', installAnyNeededSvgsincomingSvgKey, ' changed to ', isvg.svgKey)
                    type = 'image'
                    if (isvg.uniquePrepend) {
                        type = 'svg'customSavesDataFromSlot
                        // let newPrependCode = 'p' + Utility.randomString(5)
                        // isvg.svgCode = isvg.svgCode.replaceAll(isvg.uniquePrepend, newPrependCode)
                        // isvg.uniquePrepend = newPrependCode
                    }
                    updateIncomingSvgKeys.push({ incomingSvgKey: incomingSvgKey, newSvgKey: useKey, type, newSvg: true, reason: "create new svg for svgs store, using the new svgKey - the incoming key may be the same - but if not, we need to change the svgKey in the incoming data." })
                    repeatedInserts++ // we are in loop here, so if we have multiple svgs to add, we need to increment the svgKey numbers.
                    isvg.svgName = isvg.svgName + '_generated_' + Utility.randomString(5)                        console.log('data1:', data)
                    installSvgs.push(isvg)
                }
            }
        })
        */

    const svgKeysFromOldVersionSlot = (changeIncomingSvgKeysFromTo, type) => {
        let svgKeys = []
        if (!changeIncomingSvgKeysFromTo || !type) {
            return []
        }
        changeIncomingSvgKeysFromTo.forEach(icsk => {
            if (icsk.type === type) {
                svgKeys.push(icsk.toSvgKey)
            }
        })

        return svgKeys
    }

    // new method for installing external files. For each counterState, we inspect the custom svgs, see if they are installed or not.
    // if they are, and the svgKeys match, nothing to do with that custom svg.
    // if they are, but are in a different svgKey - fix up the layers.inputs, and fix up the activeLayerValues to reflect that.
    // If they are not installed, install them, then updated the layers inputs and the activeLayerValues to reflect the new svgKey
    // Any duped layers will need to wait until counter load event, either through slot->edit or loadCounter.
    // Fonts should also be scanned and loaded into state and dom. No use having the same font data repeated a number of times sitting
    // in different slots.
    // slot: centerXy: {x: f, y: f}
    //  counterState:
    //      activeLayerValues: {lik : val, lik: val, ...}
    //      combinedSvg: "svgString with all layer svgs in it"
    //      customImageSvgKeys: [n,n,n,...]
    //      customSvgKeys: [n,n,n,...]
    //      fonts: [{...},{...}, . . . [
    //      layers: [{...},{...}, . .  . ]
    //  number: n
    //  pixelsWidth: f
    //  position: [col,row]
    //  xy: [f,f]


    // on loading a sheet from file, we dont worry about dupe layers, since they will be handled if the user selects "edit"
    // and the file gets sent to CounterArea.js, which will figure things out. But we do need to install fonts and
    // svgs that are incoming, since the system needs to know whats fonts and svgs it needs to check to prevent deletion,
    // and we can't just have multiple copies of the same svgs and fonts sitting in a sheet, eating up memory.
    const loadSheetFromFile = evt => {
        console.log('loadSheetFromFile')
        let file = evt.target.files[0]

        JSZip.loadAsync(file)
            .then((zip) => {
                Object.keys(zip.files).forEach((filename) => {
                    zip.files[filename].async('string').then(async (fileData) => {
                        let data = null
                        data = JSON.parse(fileData)
                        let incomingCustomSvgs = []
                        if (data.version === 3) {
                            if (data.type !== 'sheet') {
                                console.log('data:', data)
                                actions.addErrorMessages([{
                                    comment: "File error",
                                    lineItems: ['this file is a counter file, not a sheet file']
                                }])
                            }
                            else {
                                console.log('...new version sheet incoming:', data)

                                // check for incoming fonts
                                let fontsData = data.fontsData
                                let installFonts = []
                                fontsData.forEach(fd => {
                                    if (!state.fonts.find(sf => sf.fontFamily === fd.fontFamily)) {
                                        installFonts.push(fd)
                                    }
                                })
                                if (installFonts.length > 0) {
                                    console.log('installing fonts:', installFonts)
                                    let fonts = [...state.fonts, ...installFonts]
                                    actions.fonts(fonts)
                                }

                                // check for unknown incoming custom svgs
                                let customSvgsData = data.customSvgsData
                                console.log('customSvgsData:', customSvgsData)
                                let changeCustomSvgKeysFromTo = []
                                let installSvgs = []
                                customSvgsData.forEach(isvg => {
                                    if (state.svgs.find(ss => ss.svgKey === isvg.svgKey && ss.svgCode === isvg.svgCode)) {
                                        console.log('this is ok, svgKey and svgCode matches.')
                                    }
                                    else {
                                        // see if the code matches svg in state
                                        let foundSvg = state.svgs.find(ss => ss.svgCode === isvg.svgCode)
                                        if (foundSvg) {
                                            console.log('found svg,', isvg, ' but it is at a different key')
                                            // exists but at a different svgKey
                                            changeCustomSvgKeysFromTo.push({ from: isvg.svgKey, to: foundSvg.svgKey })
                                        }
                                        else {
                                            // we need to install it.
                                            console.log('nope dont have it, going to install', isvg)
                                            installSvgs.push(isvg)
                                        }
                                    }
                                })

                                let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
                                console.log('highest state svgKey:', useKey)
                                console.log('installSvgs:', installSvgs)
                                for (let i = 0; i < installSvgs.length; i++) {
                                    let svg = installSvgs[i]
                                    console.log('going to install', svg)
                                    let svgType = 'svg'
                                    if (svg.svgCode.includes('svgxlink:href') && svg.svgCode.includes('<image')) {
                                        svgType = 'image'
                                    }
                                    console.log('it is of type:', svgType)
                                    // get next svgKey
                                    if (useKey < 2000) {
                                        useKey = 2000
                                    }
                                    else {
                                        useKey++
                                    }
                                    let fromKey = svg.svgKey
                                    let toKey = useKey
                                    svg.svgKey = toKey
                                    changeCustomSvgKeysFromTo.push({ from: fromKey, to: toKey })
                                }
                                console.log('so installSvgs has:', installSvgs)
                                console.log('changeCustomSvgKeysFromTo:', changeCustomSvgKeysFromTo)

                                if (changeCustomSvgKeysFromTo.length > 0) {
                                    console.log('ok we need to update the svgKeys accordingly')
                                    // the svgKeys referenced in the alv values in the slots needs to be updated,
                                    // and the svg lists in the layers need to be updated in state.layers

                                }


                                if (!data.sheetSettings.sheetSide) {
                                    let firstCounter = data.slots.find(slot => slot.counterState !== null)
                                    if (firstCounter) {
                                        data.sheetSettings.sheetSide = firstCounter.counterState.package.counterSideActive.active
                                    }
                                    else {
                                        data.sheetSettings.sheetSide = 'front'
                                    }
                                }
                                console.log('going to call actions on data.sheetSettings:', data.sheetSettings)
                                console.log('and data.slots:', data.slots)




                                if (installSvgs.length > 0) {
                                    console.log('calling actions.svgs to install', installSvgs)
                                    // add the new svgs to the layers input svg list
                                    let stateCustomImageLayers = []
                                    let stateCustomSvgLayers = []
                                    let stateNotCustomLayers = []
                                    state.layers.forEach(sl => {
                                        let pulled = false
                                        let layerType = ''
                                        if (sl.layerName === 'custom images') {
                                            stateCustomImageLayers.push({ ...sl })
                                            pulled = true
                                        }
                                        if (sl.layerName === 'custom svgs') {
                                            stateCustomSvgLayers.push({ ...sl })
                                            pulled = true
                                        }
                                        if (sl.parentLayerKey > -1) {
                                            layerType = Utility.originalLayerName(state.layers, sl)
                                            if (layerType === 'custom images') {
                                                stateCustomImageLayers.push({ ...sl })
                                                pulled = true
                                            }
                                            if (layerType === 'custom svgs') {
                                                stateCustomSvgLayers.push({ ...sl })
                                                pulled = true
                                            }
                                        }
                                        if (pulled === false) {
                                            stateNotCustomLayers.push(sl)
                                        }
                                    })
                                    installSvgs.forEach(svg => {
                                        let type = 'svg'
                                        if (svg.svgCode.includes('data:image') > 0) {
                                            type = 'image'
                                        }
                                        if (type === 'image') {
                                            stateCustomImageLayers.forEach(ly => {
                                                let input = ly.inputs.find(li => li.named === 'svgKey')
                                                if (input) {
                                                    if (input.list && Array.isArray(input.list)) {
                                                        ly.layerHidden = 0
                                                        if (input.list.includes(svg.svgKey) === false) {
                                                            input.list.push(svg.svgKey)
                                                            console.log('layer', ly.layerName, 'input.list:', input.list)
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                        if (type === 'svg') {
                                            stateCustomSvgLayers.forEach(ly => {
                                                let input = ly.inputs.find(li => li.named === 'svgKey')
                                                if (input) {
                                                    if (input.list && Array.isArray(input.list)) {
                                                        ly.layerHidden = 0
                                                        if (input.list.includes(svg.svgKey) === false) {
                                                            input.list.push(svg.svgKey)
                                                            console.log('layer', ly.layerName, 'input.list:', input.list)
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    })

                                    actions.svgs([...state.svgs, ...installSvgs])
                                    stateCustomImageLayers = JSON.parse(JSON.stringify(stateCustomImageLayers))
                                    actions.layers([...stateNotCustomLayers, ...stateCustomImageLayers, ...stateCustomSvgLayers])
                                }
                                console.log('calling loadSheet data.slots:', data.slots)
                                actions.loadSheet({
                                    sheetSettings: data.sheetSettings,
                                    slots: data.slots
                                })
                            }


                        }
                        else {
                            console.log('old version sheet')
                            console.log(JSON.parse(JSON.stringify(data)))
                            if (data.type === 'counter') {
                                actions.addErrorMessages([{
                                    comment: "File error",
                                    lineItems: ['this file is a counter file, not a sheet']
                                }])

                            }
                            else {
                                if (data.sheetSettings && data.slots && data.type === 'sheet') {
                                    handleIncomingOldVersionSheet(data)
                                }
                                else {
                                    actions.addErrorMessages([{
                                        comment: "File error",
                                        lineItems: ['this file is a not a sheet file']
                                    }])
                                }
                            }
                        }
                    })
                })
            })

    }

    const handleIncomingVersion3sheet = data => {
        let incomingCustomSvgs = []
    }

    const handleIncomingOldVersionSheet = data => {
        let incomingCustomSvgs = []
        let customImageSvgLayers = Utility.allLayersOfType(state.layers, 'custom images')
        let customSvgLayers = Utility.allLayersOfType(state.layers, 'custom svgs')
        //      console.log('**** top, customImageSvgLayers:', customImageSvgLayers)
        //    console.log('**** top, customSvgLayers:', customSvgLayers)
        let allFontsUsed = []

        // filter out unused layers. Old version had all layers in it, whether used or now.
        data.slots.forEach(slot => {
            //  console.log('slot:', slot)
            if (slot.counterState) {
                //      console.log('slot.counterState:', slot.counterState)
                slot.counterState.activeLayerValues = removeSpacersActiveLayerValues(slot.counterState.activeLayerValues)
                slot.counterState.layers = slot.counterState.layers.filter(lr => lr.layerActive === 1)
                slot.counterState.layers = removeSpacersLayers(slot.counterState.layers)
                //     console.log(slot.number, 'slot.counterState.customSvgs:', slot.counterState.customSvgs)
                slot.counterState.customSvgs.forEach(csvg => {
                    if (!incomingCustomSvgs.find(icsvg => icsvg.svgCode === csvg.svgCode)) {
                        //        console.log('pushing in custom (general) svg: ', csvg)
                        console.log('pushing into incomingCustomSvgs name:', csvg.svgName)
                        incomingCustomSvgs.push(csvg)
                    }
                })
                slot.counterState.layers.forEach(ly => {
                    //          console.log('is there a ly.svg?:', ly)
                })
                let layerSvgs = slot.counterState.layers.map(ly => ly.svg)
                //     console.log('layerSvgs:', layerSvgs)
                slot.counterState.combinedSvg = combineDirectSvgs(layerSvgs)
                slot.counterState.fonts = detectFontsInALV(slot.counterState.activeLayerValues)
                allFontsUsed = [...allFontsUsed, ...slot.counterState.fonts]
                allFontsUsed = Array.from(new Set(allFontsUsed))
            }
            delete slot.svg
        })

        // deal with custom svgs
        let customSvgsToInstall = []
        let changeSvgKeys = []
        if (incomingCustomSvgs.length > 0) {
            let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey;
            if (useKey < 2000) {
                useKey = 2000
            }
            else {
                useKey++
            }
            let cleanedStateSvgs = state.svgs.filter(ssvg => ssvg.svgKey > 1080)
            // this below seems to notconsole.log('************************ CHECKING ON JUST NAME ***********************************')
            // let matchedInState = cleanedStateSvgs.find(ssvg => ssvg.svgName === icsvg.svgName)
            // if( matchedInState ) {
            //     let id = extractNativeId(matchedInState.cleanedSvgCode)
            //     console.log('id1:', id)
            //     let id2 = extractNativeId(icsvg.cleanedSvgCode)
            //     console.log('id2:', id2)

            //     console.log('found a match on name', matchedInState.svgName)
            //     console.log('cleaned state: ', matchedInState.cleanedSvgCode)
            //     console.log('cleaned incom: ', icsvg.cleanedSvgCode)
            // }
            // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^') be working. Find out why its not finding svgs that are already installed.
            cleanedStateSvgs = JSON.parse(JSON.stringify(cleanedStateSvgs))

            cleanedStateSvgs.forEach(cssvg => {
                if (!cssvg.uniquePrepend) {
                    cssvg.uniquePrepend = ''
                }
                cssvg.cleanedSvgCode = cssvg.svgCode.replaceAll(cssvg.uniquePrepend, '')
                // remove id since we dont want those to cause code comparisons to fail
                let id = extractNativeId(cssvg.cleanedSvgCode)
                if (id) {
                    cssvg.cleanedSvgCode = cssvg.cleanedSvgCode.replace('id="' + id + '"', 'id=""')
                }



            })

            // strip extraneous svg code wrapped around the actual svg we want
            incomingCustomSvgs.forEach(icsvg => {
                if (icsvg.uniquePrepend) {
                    let firstSvgPos = icsvg.svgCode.indexOf('<svg')
                    if (firstSvgPos > -1) {
                        let secondSvgPos = icsvg.svgCode.indexOf('<svg', firstSvgPos + 1)
                        if (secondSvgPos > -1) {
                            let trimmedSvg = icsvg.svgCode.substring(secondSvgPos)
                            if (trimmedSvg) {
                                let lastEndSvgPos = trimmedSvg.lastIndexOf('</svg>')
                                if (lastEndSvgPos) {
                                    trimmedSvg = trimmedSvg.substring(0, lastEndSvgPos)
                                    if (trimmedSvg) {
                                        icsvg.svgCode = trimmedSvg
                                        icsvg.cleanedSvgCode = icsvg.svgCode.replaceAll(icsvg.uniquePrepend, '')
                                        let id = extractNativeId(icsvg.cleanedSvgCode)
                                        if (id) {
                                            icsvg.cleanedSvgCode = icsvg.cleanedSvgCode.replace('id="' + id + '"', 'id=""')
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
                else { // new version has simplified way of storing custom images. Just store the image. Not any wrapping svg.
                    let xlinkStart = icsvg.svgCode.indexOf('xlink:href')
                    if (xlinkStart > -1) {
                        let xlinkEnd = icsvg.svgCode.indexOf('></image></svg>')
                        if (xlinkEnd > xlinkStart) {
                            let newImageSrc = icsvg.svgCode.substring(xlinkStart, xlinkEnd)
                            newImageSrc = '<image width=\"100%\" height=\"100%\" ' +
                                newImageSrc + '/>'
                            icsvg.svgCode = newImageSrc
                            icsvg.cleanedSvgCode = newImageSrc
                        }
                    }
                }
            })

            console.log('incomingCustomSvgs:', incomingCustomSvgs)
            incomingCustomSvgs.forEach(icsvg => {

                // see if don't have a match
                let matchedInState = cleanedStateSvgs.find(ssvg => ssvg.svgKey === icsvg.svgKey && ssvg.cleanedSvgCode === icsvg.cleanedSvgCode)
                if (!matchedInState) {
                    // see if the same svg is under a different svgKey in state.svgs
                    let svgType = 'svg'
                    if (icsvg.uniquePrepend === '') {
                        svgType = 'image'
                    }
                    matchedInState = cleanedStateSvgs.find(ssvg => ssvg.cleanedSvgCode === icsvg.cleanedSvgCode)
                    if (matchedInState) {
                        console.log('match found on cleaned svgs')
                        changeSvgKeys.push({ from: icsvg.svgKey, to: matchedInState.svgKey, type: svgType })
                    }
                    else {
                        console.log('did not find match on cleaned svgs')

                        // console.log('************************ CHECKING ON JUST NAME ***********************************')
                        // let matchedInState = cleanedStateSvgs.find(ssvg => ssvg.svgName === icsvg.svgName)
                        // if( matchedInState ) {
                        //     let id = extractNativeId(matchedInState.cleanedSvgCode)
                        //     console.log('id1:', id)
                        //     let id2 = extractNativeId(icsvg.cleanedSvgCode)
                        //     console.log('id2:', id2)

                        //     console.log('found a match on name', matchedInState.svgName)
                        //     console.log('cleaned state: ', matchedInState.cleanedSvgCode)
                        //     console.log('cleaned incom: ', icsvg.cleanedSvgCode)
                        // }
                        // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^')


                        let previousSvgKey = icsvg.svgKey
                        icsvg.svgKey = useKey++
                        delete icsvg.cleanedSvgCode
                        let inc = 2
                        while (state.svgs.find(svg => svg.svgName === icsvg.svgName)) {
                            icsvg.svgName = icsvg.svgName + '_' + inc
                            inc++
                            if (inc > 100) { // prevent runaway
                                icsvg.svgName = icsvg.svgName.substring(0, 5) + '_' + Utility.randomString(5)
                                break
                            }
                        }
                        customSvgsToInstall.push(icsvg)

                        changeSvgKeys.push({ from: previousSvgKey, to: icsvg.svgKey, type: svgType })
                    }
                }
            })
        }
        if (customSvgsToInstall.length > 0) {
            actions.svgs([...state.svgs, ...customSvgsToInstall])
        }
        if (changeSvgKeys.length > 0) {
            // need to change the layer inputs and input list of custom layers (and their dupes)
            // and need to update the activeLayerValues
            data.slots.forEach(slot => {
                slot.counterState.activeLayerValues = updateSvgKeysInActiveLayerValues(slot.counterState.layers, slot.counterState.activeLayerValues, changeSvgKeys)
            })
            // and need to split apart the (pure)svgs and svg wrapped images.
            // And then need to create the customSvgKeys and customSvgImageKeys fields to the slot.counterState

        }
        //console.log('updated data:', data)


        // console.log('customImageSvgLayers:', customImageSvgLayers)
        //  console.log('customSvgLayers:', customSvgLayers)


        // need to change the layer inputs and input list of custom layers (and their dupes)
        // and need to update the activeLayerValues
        // and need to split apart the (pure)svgs and svg wrapped images.
        // And then need to create the customSvgKeys and customSvgImageKeys fields to the slot.counterState

        data.slots.forEach(slot => {
            //   console.log(' ')
            //   console.log('********************************************************************')
            //   console.log('*** slot.number: ', slot.number, '**********************************')
            //   console.log('********************************************************************')
            let customImageSvgLayerTopKey = customImageSvgLayers[0].layerKey
            let customSvgLayerTopKey = customSvgLayers[0].layerKey
            let incomingImageSvgLayers = slot.counterState.layers.filter(ly => ly.layerKey === customImageSvgLayerTopKey || ly.parentLayerKey === customImageSvgLayerTopKey)
            let incomingSvgLayers = slot.counterState.layers.filter(ly => ly.layerKey === customSvgLayerTopKey || ly.parentLayerKey === customSvgLayerTopKey)

            // simply push the new keys into the state layers lists
            customImageSvgLayers.forEach(cisly => {
                cisly = updateLayerInputs(cisly, changeSvgKeys, 'image', false)
            })
            customSvgLayers.forEach(csly => {
                csly = updateLayerInputs(csly, changeSvgKeys, 'svg', false)
            })

            // for incoming we need to remove any being replaced.
            incomingImageSvgLayers.forEach(icisly => {
                icisly = updateLayerInputs(icisly, changeSvgKeys, 'image', true)
            })
            incomingSvgLayers.forEach(icsly => {
                icsly = updateLayerInputs(icsly, changeSvgKeys, 'svg', true)
            })
            // console.log(' ')
            // console.log('slot number', slot.number, 'slot:', slot)
            // console.log('is svg inputKeys updated?:', customSvgLayers)
            // console.log('is image inputKeys updated?:', customImageSvgLayers)
            // console.log('is incoming svg inputKeys updated?:', incomingSvgLayers)
            // console.log('is incoming image inputKeys updated?:', incomingImageSvgLayers)
            // console.log('slot.counterState.customSvgs:', slot.counterState.customSvgs)
            // console.log('changeSvgKeys:', changeSvgKeys)

            slot.counterState.customImageSvgKeys = []
            slot.counterState.customSvgKeys = []
            slot.counterState.customSvgs.forEach(csvg => {
                let svgKeyToUse = csvg.svgKey
                let found = changeSvgKeys.find(csk => csk.to === svgKeyToUse)
                // console.log('found:', found)
                if (found) {
                    if (found.type === 'image') {
                        if (!slot.counterState.customImageSvgKeys.includes(svgKeyToUse)) {
                            slot.counterState.customImageSvgKeys.push(svgKeyToUse)
                        }
                    }
                    if (found.type === 'svg') {
                        if (!slot.counterState.customSvgKeys.includes(svgKeyToUse)) {
                            slot.counterState.customSvgKeys.push(svgKeyToUse)
                        }
                    }
                }
                else {
                    if (csvg.uniquePrepend === '') {
                        slot.counterState.customImageSvgKeys.push(csvg.svgKey)
                    }
                    else {
                        slot.counterState.customSvgKeys.push(csvg.svgKey)
                    }
                }
            })

        })

        // remove deprecated counterState.customSvgs field
        data.slots.forEach(slot => {
            if (slot.counterState) {
                delete slot.counterState.customSvgs
            }
        })
        //  console.log('data.slots:', data.slots)
        let updatedSlots = transferSlots(data.slots)
        //  console.log('updatedSlots:', updatedSlots)
        actions.slots(updatedSlots)
        //  console.log('data.sheetSettings:', data.sheetSettings)
        console.log('sending to actions.sheetSettings:', data.sheetSettings)
        actions.sheetSettings(data.sheetSettings)

        let combinedSvgArray = [...customImageSvgLayers, ...customSvgLayers]
        if (combinedSvgArray.length > 0) {

            let layers = state.layers
            layers.forEach(ly => {
                let foundLayer = combinedSvgArray.find(csa => csa.layerKey === ly.layerKey)
                if (foundLayer) {
                    ly = foundLayer
                }
            })

            //actions.layers([...layers])
            actions.layers(JSON.parse(JSON.stringify(layers)))
        }

        let missingFonts = allFontsUsed.filter(font => {
            return !state.fonts.find(sf => sf.fontFamily === font)
        })
        //    console.log('missingFonts:', missingFonts)
        if (missingFonts.length > 0) {
            actions.addErrorMessages([{
                comment: "The file being imported references unknown font(s):",
                lineItems: [missingFonts.join(', ')]
            }])
        }


    }

    const extractTopSvgTag = str => {
        let startSvgTag = str.indexOf('<svg')
        if (startSvgTag > -1) {
            let endSvgTag = str.indexOf('>', startSvgTag + 1)
            if ((startSvgTag > -1 && endSvgTag > -1) && (endSvgTag > startSvgTag)) {
                return str.substring(startSvgTag, endSvgTag)
            }
        }
        return null
    }

    const extractNativeId = str => {
        let idResult = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            for (let i = 0; i < splitted.length; i++) {
                let testStr = splitted[i]
                if (testStr.startsWith('id=') || testStr.startsWith('ID=')) {
                    let splittedId = testStr.split('=')
                    idResult = splittedId[1].replaceAll('"', '')
                    break
                }
            }
        }

        return idResult
    }

    const transferSlots = newSlots => {
        //  console.log('state.slots:', state.slots)
        //  console.log('newSlots:', newSlots)
        let _slots = [...state.slots]
        _slots.forEach(ss => {
            ss.counterState = null
        })
        //   console.log('after all slots counterState set to null:', _slots)
        newSlots.forEach(ns => {
            //console.log('new slot: ', ns)
            if (ns.counterState) {
                //  console.log('does have counterState:', ns.counterState)
                let number = ns.number
                //    console.log('number:', number)
                _slots[number].counterState = ns.counterState
            }
        })

        return _slots
    }

    const updateSvgKeysInActiveLayerValues = (layers, _activeLayerValues, changeSvgKeys) => {
        let activeLayerValues = { ..._activeLayerValues }
        // console.log('initial activeLayerValues:', _activeLayerValues)
        layers.forEach(layer => {
            let lk = layer.layerKey
            let svgInputKey = layer.layerActiveRequiredInputKey // if its a custom image or svg layer, it has a layerActiveRequiredIncomingInputKey
            let currentValue = activeLayerValues[lk + '_' + svgInputKey]
            let svgKeyUpdate = changeSvgKeys.find(ck => ck.from === currentValue)
            if (svgKeyUpdate) {
                activeLayerValues[lk + '_' + svgInputKey] = svgKeyUpdate.to
            }
        })
        //   console.log('updated activeLayerValues:', activeLayerValues)
        return activeLayerValues
    }

    const updateLayerInputs = (layer, changes, type, replace) => {
        changes.forEach(oldNewSvgKeys => {
            if (oldNewSvgKeys.type === type) {

                let input = layer.inputs.find(inp => inp.named === 'svgKey')
                if (input) {
                    if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                        if (input.list.includes(oldNewSvgKeys.to) === false) {
                            input.list.push(oldNewSvgKeys.to)
                            layer.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                            layer = { ...layer }
                        }
                        if (replace) {
                            if (input.list.includes(oldNewSvgKeys.from)) {
                                input.list = input.list.filter(il => il !== oldNewSvgKeys.from)
                                layer.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                                layer = { ...layer }
                            }
                        }
                    }
                }

            }
        })

        return layer
    }

    const combineDirectSvgs = svgs => {
        let combinedSvgs = '<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">'
        svgs.forEach(svg => {
            if (svg) {
                if (svg.includes("-120, -120, 240, 240")) {
                    svg = svg.replace("-120, -120, 240, 240", "-100, -100, 200, 200")
                }
                if (svg.includes("0, 0, 240, 240")) {
                    let matrixIndex = svg.indexOf("<g transform")
                    if (matrixIndex > 0) {
                        // a transform over the transform - easier than trying to compute the correct numbers for the existing transform.
                        svg = svg.replace("<g transform", '<g transform="matrix(1.2, 0, 0, 1.2, -24, -24)"><g transform')
                    }
                    let lastSvgTag = svg.lastIndexOf('</svg>')
                    svg = svg.substring(0, lastSvgTag) + '</g></svg>'
                }
            }
            combinedSvgs += svg

        })
        combinedSvgs += '</svg>'

        return combinedSvgs
    }

    const combineSvgs = svgsData => {
        let combinedSvgs = '<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">'
        svgsData.forEach(svgData => {
            if (svgData.svg) {
                if (svgData.viewBox) {
                    if (svgData.viewBox === "-120, -120, 240, 240") {
                        svgData.svg = svgData.svg.replace("-120, -120, 240, 240", "-100, -100, 200, 200")
                    }
                    if (svgData.viewBox === "0, 0, 240, 240") { // custom svgs come in with a 240x240 size.
                        let matrixIndex = svgData.svg.indexOf("<g transform")
                        if (matrixIndex > 0) {
                            // a transform over the transform - easier than trying to compute the correct numbers for the existing transform.
                            svgData.svg = svgData.svg.replace("<g transform", '<g transform="matrix(1.2, 0, 0, 1.2, -24, -24)"><g transform')
                        }
                        let lastSvgTag = svgData.svg.lastIndexOf('</svg>')
                        svgData.svg = svgData.svg.substring(0, lastSvgTag) + '</g></svg>'
                    }
                }
                combinedSvgs += svgData.svg
            }
        })
        combinedSvgs += '</svg>'

        return combinedSvgs
    }

    const removeSpacersActiveLayerValues = _alv => {
        let alv = { ..._alv }
        for (const [key] of Object.entries(alv)) {
            if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                delete alv[key] // remove the useless deprecated 'spacer' entry
            }
        }

        return alv
    }

    const removeSpacersLayers = _layers => {
        let layers = [..._layers]
        // remove spacers from layer.inputs
        layers.forEach(ly => {
            //    console.log('ly.inputs:', ly.inputs)
            ly.inputs = ly.inputs.filter(inp => inp.inputKey !== 5 && inp.inputKey !== 59 && inp.inputKey !== 72)
            ly.layerInputKeys = ly.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
        })

        return layers
    }

    const updateOldSlotToNewSlot = _counterState => {
        let counterState = JSON.parse(JSON.stringify(_counterState))




        if (counterState.customSvgs && counterState.customSvgs.length > 0) {
            counterState.customSvgs.forEach(csvg => {
                // see if these svgs exist in state (unlikely since the user is loading an old sheet file).
                let changedSvgKeys = findMatchingSvgInState(csvg)
                if (changedSvgKeys) {
                    //  console.log('changedKeys:', changedSvgKeys)
                }
                else {
                    //   console.log('did not find csvg:', csvg)
                }
            })
        }

        return counterState
    }

    const findMatchingSvgInState = csvg => {
        if (!csvg.uniquePrepend) {
            csvg.uniquePrepend = ''
        }
        let cleanedIncomingSvgCode = csvg.svgCode.replaceAll(csvg.uniquePrepend, '')
        for (let i = 0; i < state.svgs.length; i++) {
            let ssvg = state.svgs[i]
            if (ssvg.svgKey < 1000) {
                continue
            }
            let cleanedStateSvgCode = ssvg.svgCode
            if (ssvg.uniquePrepend) {
                cleanedStateSvgCode = cleanedStateSvgCode.replaceAll(ssvg.uniquePrepend, '')
            }
            if (cleanedIncomingSvgCode === cleanedStateSvgCode) {
                if (ssvg.svgKey !== csvg.svgKey) {
                    return { fromKey: csvg.svgKey, toKey: ssvg.svgKey }
                }
            }
        }

        return false
    }


    const loadFromFileOnChangeSaved = evt => {
        let file = evt.target.files[0]

        JSZip.loadAsync(file)
            .then((zip) => {
                Object.keys(zip.files).forEach((filename) => {
                    zip.files[filename].async('string').then(async (fileData) => {
                        let data = null
                        data = JSON.parse(fileData)
                        let newSvgsData = []
                        if (data.version === 3) {
                            console.log('new version sheet incoming:', data)
                        }
                        else {
                            // see if we can convert this
                            // app: "snapCounter
                            // dateSaved: "2024-06-24"
                            // name: "ver1sheet..."
                            // sheetSettings: {...}
                            // slots: [
                            //   {
                            //     centerXy: {}
                            //     counterState:
                            //         activeLayerValues
                            //         customSvgs: []
                            //         layers: [
                            //            ...
                            //            svg: ""
                            //            svgIdPrepend: ""
                            //         ]
                            //     fonts: ['fontFamilyName1','fontFamilyName2']
                            //     number: 0
                            //     pixelsWidth: {col, row}
                            //     xy: {}
                            //   }...
                            // ]
                            // type: "sheet"

                            console.log('old version sheet incoming: ', data)
                            //          console.log('data.slots:', data.slots)




                        }


                        if (data.version === 3) {
                            console.log('new version of sheet.')
                            installAnyNeededFonts(data.counterState.fontsData)
                            //      console.log('data.customSvgsData:', JSON.parse(JSON.stringify(data.customSvgsData)))
                            for (let i = 0; i < data.customSvgsData.length; i++) {
                                let csvg = data.customSvgsData[i]
                                //     console.log('1 csvg:', csvg)
                                if (csvg.uniquePrepend) {
                                    //      console.log('2 csvg:', csvg)
                                    csvg.combined = csvg.svgKey + ':' + csvg.svgName + ':' + 'svg'
                                    csvg.type = 'svg'
                                }
                                else {
                                    //       console.log('3 csvg:', csvg)
                                    csvg.combined = csvg.svgKey + ':' + csvg.svgName + ':' + 'image'
                                    csvg.type = 'image'
                                }
                                newSvgsData.push({ ...csvg })
                            }
                            //                console.log('newSvgsData:', newSvgsData)
                            data.customSvgsData = newSvgsData
                            let changeIncomingSvgKeysFromTo = installAnyNeededSvgs(data.customSvgsData) // calls actions.svgs(newStateSvgs)
                            let updatedStateLayers = updateStateLayersListWithNewSvgKeys(changeIncomingSvgKeysFromTo) // this calls actions.layers with new svg lists
                            data.slots = updateLayersIncomingSlotsLayers(updatedStateLayers, changeIncomingSvgKeysFromTo, data.slots)
                            data.slots = updateLayersIncomingSlotsActiveLayerValues(changeIncomingSvgKeysFromTo, data.slots)
                            // createIncomingDupedLayersToState only do this on individual load counter process.
                            actions.loadSheet({
                                sheetSettings: data.sheetSettings,
                                slots: data.slots
                            })
                        }
                        else {
                            console.log('apparently this file is an old sheet file.')
                            console.log(data)
                            // get list of any fonts used.
                            let fontsUsed = []
                            let customSvgsData = []
                            let updatedLayers = []
                            let updatedActiveLayerValues = null
                            // lets keep things separate                            // let customSvgsData = slot.counterState.customSvgs ? slot.counterState.customSvgs : []
                            // console.log('customSvgsData:', customSvgsData)
                            // let changeIncomingSvgKeysFromTo = installAnyNeededSvgs(customSvgsData) for now
                            // deal with fonts
                            let fontsThisSlot = []

                            // lets get the state slots ready to receive data from the incoming old slot file.
                            // The positioning data on the incoming slots will be wrong, we need to preserve the
                            // positioning in the current state of slots.
                            let stateSlots = JSON.parse(JSON.stringify(state.slots))
                            // blank it out
                            stateSlots.forEach(ss => {
                                ss.counterState = null
                            })

                            // filter out unused layers.
                            data.slots.forEach(slot => {
                                if (slot.counterState) {
                                    slot.counterState.package.layers = slot.counterState.package.layers.filter(lr => lr.layerActive === 1)
                                }
                            })

                            data.slots.forEach(slot => {
                                if (slot.counterState) {
                                    fontsThisSlot = []
                                    let svg = slot.svg
                                    // get list of font names that are used. We dont have font data though from old sheet files.
                                    if (svg) {
                                        let checkForFontsText = svg.replaceAll('&quot;', '"')
                                        checkForFontsText = checkForFontsText.replaceAll('font-family: ', 'font-family:')
                                        let matches = checkForFontsText.matchAll(/font-family:\".*?"/g);
                                        if (matches) {
                                            for (const ffont of matches) {
                                                let matchedItem = ffont[0]
                                                let splitted = matchedItem.split(':')
                                                if (splitted && splitted.length === 2) {
                                                    let fontName = splitted[1].replaceAll('"', '')
                                                    if (fontName && fontsUsed.includes(fontName) === false) {
                                                        fontsUsed.push(fontName)
                                                        fontsThisSlot.push(fontName)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    slot.fonts = [...fontsThisSlot]
                                }
                            })

                            // deal with customSvgs
                            data.slots.forEach(slot => {
                                let customSvgsDataFromSlot = slot.counterState.package.customSvgs ? slot.package.counterState.customSvgs : []
                                if (customSvgsDataFromSlot.length > 0) {
                                    console.log('customSvgsDataFromSlot:', customSvgsDataFromSlot)
                                    customSvgsDataFromSlot.forEach(csvg => {
                                        if (csvg.uniquePrepend) {
                                            csvg.combined = csvg.svgKey + ':' + csvg.svgName + ':' + 'svg'
                                            csvg.type = 'svg'
                                        }
                                        else {
                                            csvg.combined = csvg.svgKey + ':' + csvg.svgName + ':' + 'image'
                                            csvg.type = 'image'
                                        }
                                    })
                                    console.log('ok this should have combined and type:', customSvgsDataFromSlot)
                                }
                                if (customSvgsDataFromSlot.length > 0) {
                                    customSvgsData = [...customSvgsData, ...customSvgsDataFromSlot]
                                }
                            })
                            console.log('customSvgsData, is the image in there?:', customSvgsData)

                            // let customSvgsData = slot.counterState.customSvgs ? slot.counterState.customSvgs : []
                            // console.log('customSvgsData:', customSvgsData)
                            // let changeIncomingSvgKeysFromTo = installAnyNeededSvgs(customSvgsData)
                            // console.log('changeIncomingSvgKeysFromTo:', changeIncomingSvgKeysFromTo)
                            // let updatedStateLayers = updateStateLayersListWithNewSvgKeys(changeIncomingSvgKeysFromTo) // this calls actions.layers
                            // console.log('updatedStateLayers:', updatedStateLayers)

                            customSvgsData.forEach(csd => {
                                let firstSvgPos = csd.svgCode.indexOf('<svg')
                                let secondSvgPos = csd.svgCode.indexOf('<svg', firstSvgPos + 1)
                                let trimmedSvg = csd.svgCode.substring(secondSvgPos)
                                let lastEndSvgPos = trimmedSvg.lastIndexOf('</svg>')
                                trimmedSvg = trimmedSvg.substring(0, lastEndSvgPos)

                                console.log('trimmedSvg:', trimmedSvg)
                                csd.svgCode = trimmedSvg
                            })
                            let changeIncomingSvgKeysFromTo = installAnyNeededSvgs(customSvgsData)
                            console.log('changeIncomingSvgsFromTo:', changeIncomingSvgKeysFromTo)
                            let updatedStateLayers = updateStateLayersListWithNewSvgKeys(changeIncomingSvgKeysFromTo) // this calls actions.layers with new svg lists
                            console.log('from updateStateLayersListWithNewSvgKeys got back: updatedStateLayers', updatedStateLayers)
                            data.slots = updateLayersIncomingSlotsLayers(updatedStateLayers, changeIncomingSvgKeysFromTo, data.slots)
                            console.log('and incomingSlots updated with svgKey changes, if needed:', data.slots)
                            console.log('updating the incoming activeLayerValues so it references the updated svgKey')
                            // ok we need to create the customImageSvgKeys and customSvgKeys to put into the slots.counterState
                            // so that it matches the structure of the new version sheet file.
                            // let customImageSvgKeys = incomingSlot.counterState.customImageSvgKeys
                            // let customSvgKeys = incomingSlot.counterState.customSvgKeys
                            data.slots.forEach(slot => {
                                if (slot.counterState) {
                                    slot.counterState.package.customImageSvgKeys = svgKeysFromOldVersionSlot(changeIncomingSvgKeysFromTo, 'image')
                                    slot.counterState.package.customSvgKeys = svgKeysFromOldVersionSlot(changeIncomingSvgKeysFromTo, 'svg')
                                    delete slot.counterState.package.customSvgs

                                    // the old version sheet had somewhat different positioning values, so we need to update the
                                    // old slot's svg to get it to be positioned correctly.
                                    let updatedSvg = updateOldSlotSvg(slot.svg)
                                    slot.counterState.package.combinedSvg = updatedSvg
                                    delete slot.svg
                                    if (slot.counterState.package.layers) {
                                        slot.counterState.package.layers.forEach(fl => {
                                            delete fl.svg
                                            delete fl.svgIdPrepend
                                        })
                                    }

                                    let alv = slot.counterState.package.activeLayerValues
                                    for (const [key] of Object.entries(alv)) {
                                        if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                                            delete alv[key] // remove the useless deprecated 'spacer' entry
                                        }
                                    }


                                }
                            })

                            console.log('these incoming slots should have svgKeys and imageKey fields.:', data.slots)
                            data.slots = updateLayersIncomingSlotsActiveLayerValues(changeIncomingSvgKeysFromTo, data.slots)
                            console.log('data.slots:', data.slots)
                            // tranfer counterState to the version 3 of slots, since the 
                            // positioning values will be different than in the old version.
                            console.log('stateSlots before getting changed:', stateSlots)
                            data.slots.forEach(ds => {
                                console.log('ds:', ds)
                                if (ds.counterState) {
                                    console.log('ds.counterState:', ds.counterState)
                                    let number = ds.number
                                    console.log('using number:', number)
                                    console.log('stateSlots.length:', stateSlots.length)
                                    if (number < stateSlots.length) {
                                        console.log('assigning ds.counterState', ds.counterState, 'to stateSlots[', number, ']:', stateSlots[number])
                                        stateSlots[number].counterState = ds.counterState
                                        console.log('so now we have stateSlots[', number, '] = ', stateSlots[number])
                                    }
                                }
                            })
                            console.log('stateSlots after change:', stateSlots)
                            actions.loadSheet({
                                sheetSettings: data.sheetSettings,
                                slots: stateSlots
                            })
                        }


                        // console.log('loading file from onchange, data: ', data)
                        //                         if (data.compressedSvg &&
                        //                             data.customImageSvgsData &&
                        //                             data.customSvgsData &&
                        //                             data.dateSaved &&
                        //                             data.fontsData &&
                        //                             data.hash &&
                        //                             data.name &&
                        //                             data.sheetSettings &&
                        //                             data.slots) {
                        // console.log('being treated as snapCounter3')
                        //                             actions.loadSheet({
                        //                                 sheetSettings: data.sheetSettings,
                        //                                 slots: data.slots,
                        //                                 customImageSvgsData: data.customImageSvgsData,
                        //                                 customSvgsData: data.customSvgsData,
                        //                                 fontsData: data.fontsData
                        //                             })
                        //                         }
                        // else {
                        //     console.log('not snapCounter3 ?')
                        //     if (data.app === "snapCounter" &&
                        //         data.sheetSettings &&
                        //         data.slots &&
                        //         data.type === "sheet") {
                        //         console.log('this is a snapCounter1 saved sheet.')
                        //         let customSvgs = []
                        //         let customImages = []
                        //         let stateSlots = JSON.parse(JSON.stringify(state.slots))
                        //         // blank it out
                        //         stateSlots.forEach(ss => {
                        //             ss.counterState = null
                        //         })

                        //         data.slots.forEach(slot => {
                        //             slot.counterState.customSvgs.forEach(cs => {
                        //                 if (cs.uniquePrepend) {
                        //                     customSvgs.push(cs)
                        //                 }
                        //                 else {
                        //                     customImages.push(cs)
                        //                 }
                        //             })
                        //             // we need to bring in the current slot positions, since they are diffent between versions.
                        //             let counterNumber = slot.number
                        //             let foundMatchSlot = stateSlots[counterNumber]
                        //             let fixedLayers = slot.counterState.layers.filter( ly => ly.layerActive === 1)
                        //             fixedLayers.forEach( fl => {
                        //                 delete fl.svg
                        //                 delete fl.svgIdPrepend
                        //             })
                        //             let alv = slot.counterState.activeLayerValues
                        //             for( const[key, value] of Object.entries(alv) ) {
                        //                if( key.endsWith('_5' ) || key.endsWith('_59' ) ) {
                        //                    delete alv[key] // remove the useless deprecated 'spacer' entry
                        //                }
                        //             }
                        //             console.log('alv cleaned:', slot.counterState.activeLayerValues)
                        //             slot.counterState.layers = fixedLayers 
                        //             slot.counterState.fonts = [] // apparently in version 1 I wasn't saving font data.
                        //             If people in a fluoridated community make a petition to stop the fluoridation of their water, the city council will happily take that petition, and tear it up and put it in the trash. The Fluoride Industry foundMatchSlot.counterState = slot.counterState
                        //             console.log('foundMatchSlot.counterState.layers:', foundMatchSlot.counterState.layers)
                        //             let updatedSvg = updateOldSlotSvg(slot.svg)
                        //             foundMatchSlot.counterState.combinedSvg = updatedSvg
                        //         })

                        //         data.customImageSvgsData = customImages
                        //         data.customSvgsData = customSvgs
                        //         data.fontsData = []

                        //         actions.loadSheet({
                        //             sheetSettings: data.sheetSettings,
                        //             slots: stateSlots,
                        //             customImageSvgsData: data.customImageSvgsData,
                        //             customSvgsData: customSvgs,
                        //             fontsData: data.fontsData
                        //         })
                        //     }
                        //     else {
                        //         console.log('is not ok')
                        //         setLoadSheetErrorMessage(filename + ' is not a proper sheet file.')
                        //     }
                        // }


                    })

                })

            })
            .catch((error) => setLoadSheetErrorMessage('file is invalid.'))

    }

    return (
        <div>
            <div className={sheetOperationsOpen ? 'sheet-operations opened' : 'sheet-operations'}>

                <div className={sheetOperationsOpen ? 'controls-opener opened' : 'controls-opener closed'}>
                    <div className="title">sheet operations</div>
                    <button className={sheetOperationsOpen ? 'standard-button yellow closed' : 'standard-button yellow'} onClick={() => setSheetOperationsOpen(true)}>open</button>
                    <button className={sheetOperationsOpen ? 'standard-button red' : 'standard-button red closed'} onClick={() => setSheetOperationsOpen(false)}>close</button>
                </div>

                <div className={sheetOperationsOpen ? 'controls' : 'display-none'}>

                    <div className="controls-container">
                        <div className="options">
                            <div className="title">
                                <div className="popupable" data-popup="Save sheet"
                                    onClick={actions.popupInfoActive}>save sheet</div>
                            </div>
                            <div className="">
                                <input type="checkbox" onChange={() => setSaveOption('app')} id="in-app" checked={saveOption === 'app' ? 'checked' : ''} /> <label htmlFor="in-app">in app</label>
                                <input type="checkbox" onChange={() => setSaveOption('file')} id="to-file" checked={saveOption === 'file' ? 'checked' : ''} /> <label htmlFor="to-file">to file</label>
                            </div>
                        </div>
                        <div className="options">
                            <div className="title">
                                export sheet:
                            </div>
                            <div className="">
                                <input type="checkbox" onChange={() => setSaveOption('svg')} id="to-svg" checked={saveOption === 'svg' ? 'checked' : ''} /> <label htmlFor="to-svg">to svg</label>
                                <input type="checkbox" onChange={() => setSaveOption('png')} id="to-png" checked={saveOption === 'png' ? 'checked' : ''} /> <label htmlFor="to-png">to png</label>
                            </div>
                        </div>

                        <div className="actions">
                            <div className={saveActionsResult ? 'display-none' : ''}>
                                name: <input type="text" onChange={onSaveName} value={saveName} />
                                <button className={saveName ? 'standard-button blue' : 'standard-button disabled'}
                                    onClick={saveName ? saveSheet : null}
                                >submit</button>
                            </div>
                            <div className={saveActionsResult ? 'saved' : 'display-none'}>
                                <span>{saveActionsResult}</span>
                                <button className="standard-button yellow"
                                    onClick={saveActionsOk}
                                >ok</button>
                            </div>
                        </div>
                    </div>




                    <div className="controls-container load">
                        <div className="title-with-button">
                            <div className="popupable" data-popup="Load sheet"
                                onClick={actions.popupInfoActive}>load sheet</div>

                            <button className="standard-button yellow" onClick={clickClearSheet}>clear sheet</button>

                        </div>

                        {loadSheetErrorMessage === '' ?

                            <div className="load-from">

                                <span>load from:</span>
                                <input type="radio"
                                    onChange={() => onLoadFrom('app')}
                                    id="loadFrom_app"
                                    checked={loadFrom === 'app'}
                                    value="app"
                                    onClick={() => onLoadFrom('app')}
                                /> <label htmlFor="loadFrom_app">app</label>

                                <input type="radio"
                                    onChange={() => onLoadFrom('file')}
                                    id="loadFrom_file"
                                    checked={loadFrom === 'file'}
                                    value="file"
                                    onClick={() => onLoadFrom('file')}
                                /> <label htmlFor="loadFrom_file">file</label>

                                <div className="selects">
                                    {loadFrom === 'app' ?
                                        <div >
                                            <select id="loadSheet" value={selectedSheetHash} onChange={loadSheetFromApp}>
                                                <option key="none" value="">choose sheet</option>
                                                {savedSheetsMetadata ?
                                                    savedSheetsMetadata.map((ss, index) => <option key={index} value={ss.hash}>{ss.name}</option>)
                                                    : ''}
                                            </select>
                                        </div>
                                        : <div>
                                            <div className="load-from-file">
                                                <input type="file" id="sheet_file" accept=".scz" onChange={loadSheetFromFile} />
                                            </div>
                                        </div>}
                                </div>

                            </div>
                            :
                            <div className="error">
                                <div>{loadSheetErrorMessage}</div>
                                <button className="standard-button green" onClick={() => setLoadSheetErrorMessage('')}>Ok</button>
                            </div>
                        }

                    </div>

                    <div className="controls-container">
                        <div className="title">
                            <div className="popupable" data-popup="Cutting guides"
                                onClick={actions.popupInfoActive}>cutting guides</div>
                        </div>
                        <div className="options cutting-guides">



                            <input type="radio"
                                onChange={() => onChangeGuideType('none')}
                                id="cuttingGuide_none"
                                checked={sheetParameters.guideType === 'none'}
                                value="none"
                                onClick={() => onChangeGuideType('none')}
                            /> <label htmlFor="cuttingGuide_none">none</label>

                            <input type="radio"
                                onChange={() => onChangeGuideType('edges')}
                                id="cuttingGuide_edges"
                                checked={sheetParameters.guideType === 'edges'}
                                value="edges"
                                onClick={() => onChangeGuideType('edges')}
                            /> <label htmlFor="cuttingGuide_edges">edges</label>


                        </div>
                        <div className="options cutting-guides">

                            <input type="radio"
                                onChange={() => onChangeGuideType('corners')}
                                id="cuttingGuide_corners"
                                checked={sheetParameters.guideType === 'corners'}
                                value="corners"
                                onClick={() => onChangeGuideType('corners')}
                            /> <label htmlFor="cuttingGuide_corners">corners</label>

                            <input type="radio"
                                onChange={() => onChangeGuideType('lines')}
                                id="cuttingGuide_lines"
                                checked={sheetParameters.guideType === 'lines'}
                                value="lines"
                                onClick={() => onChangeGuideType('lines')}
                            /> <label htmlFor="cuttingGuide_lines">lines</label>


                        </div>
                    </div>






                    <div className="controls-container">
                        <div className="title-with-button">
                            <div className="popupable" data-popup="Printable area" onClick={evt => actions.popupInfoActive(evt)}>printable area</div>
                            <button className={printableAreaIsAtDefaults() ? 'standard-button disabled' : 'standard-button blue'} onClick={resetPrintableArea}>reset</button>
                        </div>
                        <div className="printable-area-settings">
                            <div><div>width:</div>  <input type="text" id="printableArea_width" className={validPrintableAreaWidth() ? '' : 'warning'} onChange={onChangePrintableArea} value={printableAreaMM_width} /> mm</div>
                            <div><div>length:</div> <input type="text" id="printableArea_height" className={validPrintableAreaHeight() ? '' : 'warning'} onChange={onChangePrintableArea} value={printableAreaMM_height} /> mm</div>
                        </div>
                    </div>











                </div>
            </div>
            <div className="temp-sheet">
                <svg id="build_sheet" width="1200" height="1553" />
            </div>
        </div>
    );
}
export default SheetOperations
/*

<g xmlns="http://www.w3.org/2000/svg" data-number="0" id="sheet_number_0" transform="matrix(0.075,0,0,0.075,-7.5,-20.7375)">
    <svg xmlns="http://www.w3.org/2000/svg" id="l1lvi8r3y_drawLayer1" class="counter-svg" viewBox="-120, -120, 240, 240"><defs/><g id="l1lvi8r3y_layerGroup_1"><rect x="-100" y="-100" width="200" height="200" fill="#11ff33" style="stroke-width: 0;"/></g></svg>
    <svg xmlns="http://www.w3.org/2000/svg" id="l2l89oqjz_drawLayer2" class="counter-svg" viewBox="-120, -120, 240, 240"><defs><mask id="l2l89oqjz_maskSlx4z80kd1nq"><g><rect x="-50" y="-42.5" width="100" height="85" rx="0" ry="0" stroke="#ffffff" fill="#ffffff" style="stroke-width: 2; opacity: 1;"/><rect x="-40" y="42.5" width="80" height="30" fill="#ffffff" stroke="#ffffff" style=""/></g></mask></defs><g mask="url('#l2l89oqjz_maskSlx4z80kd1nq')" id="l2l89oqjz_layerGroup_2" style="pointer-events: visiblepainted;"><rect x="-50" y="-42.5" width="100" height="85" rx="0" ry="0" stroke="#000000" fill="#ffffff" style="stroke-width: 2;"/><g><line x1="-49.238" x2="49.238" y1="41.85" y2="-41.85" stroke="#000000" style="stroke-width: 2; stroke-linecap: round;"/></g><rect x="-50" y="-42.5" width="100" height="85" rx="0" ry="0" stroke="#000000" fill="none" style="stroke-width: 2;"/></g></svg>
</g>


<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">
   <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" id="pbljz7_svgLayer1" viewBox="-100, -100, 200, 200"><defs/><g><rect x="-100" y="-100" width="200" height="200" fill="#11ff33" style="stroke-width: 0;"/></g></svg>
   <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" id="pypylk_svgLayer3" viewBox="-100, -100, 200, 200"><defs/><g style="pointer-events: visiblepainted;"><ellipse cx="-24.37" cy="0" rx="10" ry="10" stroke="none" fill="#000000" style=""/><ellipse cx="0" cy="0" rx="10" ry="10" stroke="none" fill="#000000" style=""/><ellipse cx="24.38" cy="0" rx="10" ry="10" stroke="none" fill="#000000" style=""/></g></svg>
   <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" id="pz7mey_svgLayer23" viewBox="-100, -100, 200, 200"><defs/><g transform="matrix(1,0,0,1,7.4,-37.6)" style="pointer-events: visiblepainted;"><ellipse cx="0" cy="0" rx="8" ry="8" stroke="#000000" fill="none" style="stroke-width: 3.5;"/><line x1="-8" x2="8" y1="8" y2="-8" stroke="#000000" style="stroke-width: 3.5; stroke-linecap: round;"/></g></svg>
</svg>


*/
import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const Ellipse = {

    draw: (g, settings) => {
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'label': break
                case 'variable spacer': break
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'center xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.centerXpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.centerYpos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'fill color': derivedValues.fillColor = setting.value
                    break
                case 'stroke color':
                    derivedValues.stroke = setting.value
                    break
                case 'stroke width':
                    derivedValues.strokeWidth = Number(setting.value)
                    break
                case 'rotation':
                    derivedValues.rotation = Number(setting.value)
                    break
                case 'opacity':
                    derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'spacer': break
                default: console.warn('we seem to have missed one: ', setting.name, ' with value ', setting.value, ' setting:', setting)
            }
        })

        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }


        let fillColorObj = derivedValues.fillColor
        let fillInfo = ''
        if (!fillColorObj) {
            console.error('missing fillColorObj for ellipse. Putting in placeholder.')
            fillColorObj = {
                "fillType": "solid",
                "fillColor": "#FFA0FF",
                "gradientDirection": "linear",
                "xyPositionStart": "0,0",
                "xyPositionEnd": "1,1",
                "colorStart": "#00FFFF",
                "colorMid": "#444ddd",
                "colorEnd": "#669999"
            }
        }
        if (fillColorObj.fillType === 'solid') {
            fillInfo = fillColorObj.fillColor
        }
        else { // gradient
            let type = derivedValues.fillColor.gradientDirection === 'radial' ? 'r' : 'l'
            let pxStart = derivedValues.fillColor.xyPositionStart.split(',')[0]
            let pyStart = derivedValues.fillColor.xyPositionStart.split(',')[1]
            let pxEnd = derivedValues.fillColor.xyPositionEnd.split(',')[0]
            let pyEnd = derivedValues.fillColor.xyPositionEnd.split(',')[1]
            let color1 = derivedValues.fillColor.colorStart
            let color2 = derivedValues.fillColor.colorMid
            let color3 = derivedValues.fillColor.colorEnd
            let defg = `${type}(${pxStart}, ${pyStart}, ${pxEnd}, ${pyEnd})${color1}-${color2}-${color3}`
            let parent = g.parent()
            var cg = parent.gradient(defg);
            fillInfo = cg
        }
        derivedValues.fill = fillInfo

        Ellipse.drawEllipse(g, derivedValues)

        if (derivedValues.opacity < 1) {
            g.attr({ "opacity": derivedValues.opacity })
        }

        if (derivedValues.rotation > 0) {
            Ellipse.applyRotation(g, derivedValues.rotation)
        }

        if (derivedValues.centerXpos !== 0 || derivedValues.centerYpos !== 0) {
            Ellipse.applyTranslation(g, derivedValues.centerXpos, derivedValues.centerYpos)
        }

    },

    drawEllipse: (g, derivedValues) => {
        let width = derivedValues.width
        let height = derivedValues.height

        g.ellipse(0, 0, width, height).attr({
            stroke: derivedValues.stroke,
            strokeWidth: derivedValues.strokeWidth,
            fill: derivedValues.fill
        })

    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
}

export default Ellipse
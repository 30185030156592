import React, { useState, useEffect, useContext } from 'react'
import Utility from "../../objects/Utility";
import { useSignal } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import './InputWidthHeight.scss'
const InputWidthHeight = ({ layerKey, input, setValue, useValue = [0, 0] }) => {
    const { state } = useContext(StoreContext)
    const [widthHeightVal, setWidthHeightVal] = useState([Utility.roundFloat(useValue[0], 1), Utility.roundFloat(useValue[1], 1), null, null]);
    const [widthHeightTextVal, setWidthHeightTextVal] = useState([Utility.roundFloat(useValue[0], 1), Utility.roundFloat(useValue[1], 1)]);
    const [aspectRatioLocked, setAspectRatioLocked] = useState(false)
    const [aspectLockedRatio, setAspectLockedRatio] = useState(null)
    const [initialEffect, setInitialEffect] = useState(true)
    const [showPercent, setShowPercent] = useState('')
    const [textInputStyle, setTextInputStyle] = useState('')
    const [warningTextInputWidth, setWarningTextInputWidth] = useState('')
    const [warningTextInputHeight, setWarningTextInputHeight] = useState('')
    const [sliderMinMaxValues, setSliderMinMaxValues] = useState([1, 300])
    const [waiting, setWaiting] = useState(null)
    const signalChange = useSignal(null)

    useEffect(() => {

        // some patches
        let unitSizeLayers = state.layers.filter(sl => sl.layerKey === 3 || sl.parentLayerKey === 3)
        if (unitSizeLayers.find(nl => nl.layerKey === layerKey)) {
            setSliderMinMaxValues([1, 100])
        }
        let ellipseLayers = state.layers.filter(sl => sl.layerKey === 15 || sl.parentLayerKey === 15)
        if (ellipseLayers.find(nl => nl.layerKey === layerKey)) {
            setSliderMinMaxValues([1, 100])
        }
        let natoLayers = state.layers.filter(sl => sl.layerKey === 2 || sl.parentLayerKey === 15)
        if (natoLayers.find(nl => nl.layerKey === layerKey)) {
            setSliderMinMaxValues([1, 200])
        }

        let _textInputStyle = ''
        if (input.comment && input.comment.includes('percent')) {
            setShowPercent('%')
            _textInputStyle = 'percent'
        }

        if (state.browser.includes('Chrome') && state.os.includes('windows')) {
            _textInputStyle += ' windows-chrome'
        }

        if (_textInputStyle) {
            setTextInputStyle(_textInputStyle.trim())
        }

        return () => {
            if (waiting) {
                clearTimeout(waiting)
            }
        };

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (widthHeightVal[0] !== useValue[0] || widthHeightVal[1] !== useValue[1]) {
                setWidthHeightVal(useValue)
            }
        }


    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (initialEffect) {
            setInitialEffect(false)
            return
        }
        if (widthHeightVal === null || widthHeightVal === undefined) {
            return
        }
        if (Array.isArray(widthHeightVal) === false) {
            return
        }
        if (widthHeightVal.length === 0) {
            return
        }
        if (widthHeightVal[0] === null || widthHeightVal[0] === undefined ||
            widthHeightVal[1] === null || widthHeightVal[1] === undefined) {
            return
        }
        let width = Utility.roundFloat(Number(widthHeightVal[0]), 1)
        let height = Utility.roundFloat(Number(widthHeightVal[1]), 1)

        setValue(input.inputKey, [width, height])

    }, [widthHeightVal]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeWidthHeightTextVal = evt => {
        let value = evt.target.value
        let target = evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey ? 'width' : 'height'
        // determine if the value can be converted into a valid number
        let numericValue = false
        if (Utility.isNumeric(value)) {
            numericValue = Utility.roundFloat(value, 1)
        }
        // always do this case
        if (numericValue) {
            if (numericValue < 1 || numericValue > 240) {
                if (target === 'width') {
                    setWidthHeightTextVal([value, widthHeightTextVal[1]])
                    setWarningTextInputWidth('warning')
                    return
                }
                if (target === 'height') {
                    setWidthHeightTextVal([widthHeightTextVal[0], value])
                    setWarningTextInputHeight('warning')
                    return
                }
            }
        }
        if (!numericValue) { // user typed something that cannot be considered a number (yet). So just let them type it in, but dont adjust anything.
            if (target === 'width') {
                setWidthHeightTextVal([value, widthHeightTextVal[1]])
                setWarningTextInputWidth('warning')
                return
            }
            if (target === 'height') {
                setWidthHeightTextVal([widthHeightTextVal[0], value])
                setWarningTextInputHeight('warning')
                return
            }
        }
        if (numericValue && !aspectRatioLocked) { // and range is between 1 and 240 inclusive
            // simply set the slider values to reflect text input
            if (target === 'width') {
                setWidthHeightTextVal([value, widthHeightTextVal[1]])
                setWidthHeightVal([numericValue, widthHeightVal[1]])
                setWarningTextInputWidth('')
            }
            if (target === 'height') {
                setWidthHeightTextVal([widthHeightTextVal[0], value])
                setWidthHeightVal([widthHeightVal[0], numericValue])
                setWarningTextInputHeight('')
            }
            return
        }
        if (numericValue && aspectRatioLocked) {
            // check for text value being an impossible value, causing the other to exceed bounds, or adjust the other to reflect locked aspect.
            if (target === 'width') {
                let adjustedHeight = Utility.roundFloat(numericValue / aspectLockedRatio, 1)
                if (adjustedHeight >= 1 && adjustedHeight <= 300) {
                    setWidthHeightTextVal([value, adjustedHeight])
                    setWidthHeightVal([numericValue, adjustedHeight])
                    setWarningTextInputWidth('')
                    setWarningTextInputHeight('')
                }
                else {
                    setWidthHeightTextVal([value, widthHeightTextVal[1]])
                    setWarningTextInputWidth('warning')
                }
            }
            if (target === 'height') {
                let adjustedWidth = Utility.roundFloat(numericValue * aspectLockedRatio)
                if (adjustedWidth >= 1 && adjustedWidth <= 300) {
                    setWidthHeightTextVal([adjustedWidth, value])
                    setWidthHeightVal([adjustedWidth, numericValue])
                    setWarningTextInputWidth('')
                    setWarningTextInputHeight('')
                }
                else {
                    setWidthHeightTextVal([widthHeightTextVal[0], value])
                    setWarningTextInputHeight('warning')
                }
            }

        }

    }

    const changeWidthHeightVal = evt => {
        if (evt.target.id === 'input_width') {
            let setW = Utility.roundFloat(evt.target.value, 1)
            let setH = widthHeightVal[1]
            if (aspectLockedRatio) {
                setH = setW * (1 / aspectLockedRatio)
            }
            if (setH < 1) {
                setH = 1
                setW = setH * aspectLockedRatio
            }
            if (setH > sliderMinMaxValues[1]) {
                setH = sliderMinMaxValues[1]
                setW = setH * aspectLockedRatio
            }
            setW = Utility.roundFloat(setW, 1)
            setH = Utility.roundFloat(setH, 1)
            signalChange.v = [setW, setH]
        }
        if (evt.target.id === 'input_height') {
            let setH = Utility.roundFloat(evt.target.value, 1)
            let setW = widthHeightVal[0]
            if (aspectLockedRatio) {
                setW = setH * aspectLockedRatio
            }
            if (setW < 1) {
                setW = 1
                setH = setW * (1 / aspectLockedRatio)
            }
            if (setW > sliderMinMaxValues[1]) {
                setW = sliderMinMaxValues[1]
                setH = setW * (1 / aspectLockedRatio)
            }
            setW = Utility.roundFloat(setW, 1)
            setH = Utility.roundFloat(setH, 1)
            signalChange.v = [setW, setH]
        }

        if (waiting) {
            return
        }
        setWidthHeightVal(signalChange.v)
        setWidthHeightTextVal(signalChange.v)
        let _waiting = setTimeout(() => {
            setWidthHeightVal(signalChange.v)
            setWidthHeightTextVal(signalChange.v)
            setWaiting(null)
        }, 100)
        setWaiting(_waiting)

        setWarningTextInputWidth('')
        setWarningTextInputHeight('')
    }

    useEffect(() => {
        if (state.mouseDblClick) {
            if (state.mouseDblClick.target && state.mouseDblClick.target.id) {
                if (state.mouseDblClick.target.id &&
                    state.mouseDblClick.target.id.startsWith('widthHeight_' + layerKey + '_' + input.inputKey)) {
                    let foundLayer = state.layers.find(ly => ly.layerKey === layerKey)
                    if (foundLayer) {
                        let foundInput = foundLayer.inputs.find(inp => inp.inputKey === input.inputKey)
                        if (foundInput) {
                            let widthHeightVal_default = foundInput.defaultFloatArrayValue
                            if (input.inputKey === 211) { // patch
                                widthHeightVal_default = '{100,100}'
                            }

                            if (widthHeightVal_default !== null) {
                                if (typeof widthHeightVal_default === 'string') {
                                    widthHeightVal_default = Utility.convertPostgresArrayToArray(widthHeightVal_default)
                                }
                                if (widthHeightVal_default && Array.isArray(widthHeightVal_default) && widthHeightVal_default.length === 2) {
                                    if (state.mouseDblClick.target.id.endsWith('_width')) {
                                        setWidthHeightVal([widthHeightVal_default[0], widthHeightVal[1]])
                                        setWidthHeightTextVal([widthHeightVal_default[0], widthHeightTextVal[1]])
                                        setAspectRatioLocked(false)
                                        setWarningTextInputWidth('')
                                    }
                                    else {
                                        if (state.mouseDblClick.target.id.endsWith('_height')) {
                                            setWidthHeightVal([widthHeightVal[0], widthHeightVal_default[1]])
                                            setWidthHeightTextVal([widthHeightTextVal[0], widthHeightVal_default[1]])
                                            setAspectRatioLocked(false)
                                            setWarningTextInputHeight('')
                                        }
                                        else {
                                            setWidthHeightVal([widthHeightVal_default[0], widthHeightVal_default[1]])
                                            setWidthHeightTextVal([widthHeightVal_default[0], widthHeightVal_default[1]])
                                            setAspectRatioLocked(false)
                                            setWarningTextInputWidth('')
                                            setWarningTextInputHeight('')
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }, [state.mouseDblClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const aspectRatioLocker = () => {
        return aspectRatioLocked ?
            <svg width="28" height="32" >
                <path d="M0 2 L 9 2 9 11" stroke="white" strokeWidth="2" fill="none" />
                <path d="M3 10   L 15 10   15 21  3 21 z  " stroke="white" strokeWidth="1" fill="white" />
                <path d="M0 28.3 L 9 28.3 9 19" stroke="white" strokeWidth="2" fill="none" />
                <circle cx="9" cy="14.2" r="3.5" fill="black" />
                <circle cx="9" cy="14.2" r="2" fill="white" />
                <path d="M4.5 14.5 L 14 14.5    14 20    4.5 20 z" stroke="none" fill="black" />
            </svg>
            :
            <svg width="28" height="32">
                <path d="M0 2 L 9 2 9 8" stroke="white" strokeWidth="2" fill="none" />
                <path d="M3 7.5   L 15 7.5   15 13   9.1 10   3 13 z" stroke="white" strokeWidth="1" fill="white" />
                <path d="M3 23  L 15 23  15 21    9.1 18   3 21   z" stroke="white" strokeWidth="1" fill="white" />
                <path d="M0 28.3 L 9 28.3 9 23" stroke="white" strokeWidth="2" fill="none" />
            </svg>
    }

    const lockClick = () => {
        setAspectRatioLocked(!aspectRatioLocked)
    }

    useEffect(() => {
        let lockedRatio = aspectRatioLocked ? (widthHeightVal[0]) / (widthHeightVal[1]) : null
        setAspectLockedRatio(lockedRatio)
    }, [aspectRatioLocked]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id={`input_widthHeight_position_${input.inputKey}`} className="default-input-container input-width-height">
            <div id={`widthHeight_${layerKey}_${input.inputKey}`} className="named">{input.named}</div>

            <div className="width-height-control">
                <table>
                    <tbody>
                        <tr>
                            <td className="dimension" id={`widthHeight_${layerKey}_${input.inputKey}_width`}>width:</td>
                            <td className={aspectRatioLocked ? 'slider width locked' : 'slider width'}><input type="range"
                                min={sliderMinMaxValues[0]} max={sliderMinMaxValues[1]}
                                id="input_width"
                                value={widthHeightVal[0]}
                                step="0.1"
                                onChange={changeWidthHeightVal} />
                            </td>
                            <td rowSpan="2" className={aspectRatioLocked ? 'aspect-ratio' : 'aspect-ratio inactive'} onClick={lockClick}>{aspectRatioLocker()}</td>
                            <td className="td-readout"><input type="text" className={showPercent ? `${textInputStyle} ${warningTextInputWidth} percent` : `${textInputStyle} ${warningTextInputWidth}`} onChange={changeWidthHeightTextVal} id={`input_text_width_${layerKey}_${input.inputKey}`} value={widthHeightTextVal[0]} />{showPercent}</td>
                        </tr>
                        <tr>
                            <td className="dimension" id={`widthHeight_${layerKey}_${input.inputKey}_height`}>height:</td>
                            <td className={aspectRatioLocked ? 'slider height locked' : 'slider height'}><input type="range"
                                min={sliderMinMaxValues[0]} max={sliderMinMaxValues[1]}
                                id="input_height"
                                value={widthHeightVal[1]}
                                step="0.1"
                                onChange={changeWidthHeightVal} />
                            </td>
                            <td className="td-readout"><input type="text" className={showPercent ? `${textInputStyle} ${warningTextInputHeight} percent` : `${textInputStyle} ${warningTextInputHeight}`} onChange={changeWidthHeightTextVal} id={`input_text_height_${layerKey}_${input.inputKey}`} value={widthHeightTextVal[1]} />{showPercent}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InputWidthHeight
import React, { useEffect, useContext, useState, useRef } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './LayerMenuItem.scss'
import LayerActivateButton from '../LayerActivateButton/LayerActivateButton'
import InputXyPosition from '../InputXyPosition/InputXyPosition'
import InputWidthHeight from '../InputWidthHeight/InputWidthHeight'
import InputText from '../InputText/InputText'
import InputSlider from '../InputSlider/InputSlider'
import InputRadioGroup from '../InputRadioGroup/InputRadioGroup'
import InputFont from '../InputFont/InputFont'
import InputCounterShapes from '../InputCounterShapes/InputCounterShapes'
import InputMaterialSymbol from '../InputMaterialSymbol/InputMaterialSymbol'
import InputSvgGroup from '../InputSvgGroup/InputSvgGroup'
import InputColor from "../InputColor/InputColor"
import InputFill from "../InputFill/InputFill"
import LayerMenuOpenerEdit from "../LayerMenuOpenerEdit/LayerMenuOpenerEdit"
import LayerOptionsOpener from "../LayerOptionsOpener/LayerOptionsOpener"
import LayerOptions from "../LayerOptions/LayerOptions"

const LayerMenuItem = ({ layer, registerRef, editingLayer, clickRegister, dragSubscribe, draggingMenuLayer, optionsOpen, activeOptionsOpen }) => {
    const { state, actions } = useContext(StoreContext)
    const signalValues = useSignal(null);
    const signalValuesPrevious = useSignal(null)
    const signalLayerOpen = useSignal(false)
    const signalLayerCanBeActive = useSignal(true)
    const signalLayerEditingClass = useSignal('')
    const signalLayerOptionsOpener = useSignal(false)
    const signalDragActive = useSignal(false)
    const signalTimerCombatMovement = useSignal(false)
    const [loadingCounterState, setLoadingCounterState] = useState(null)
    const signalTimer = useSignal(null)
    const [autoActivateLayer, setAutoActivateLayer] = useState(false)
    const [combatMovementInputKeys, setCombatMovementInputKeys] = useState({ combat: -1, movement: -1 })
    const [setupFromDuplication, setSetupFromDuplication] = useState(null)
    const [currentlyDragging, setCurrentlyDragging] = useState(false)
    const copyActiveLayerValues = useSignal({})

    useEffect(() => {
        if (layer.layerKey !== activeOptionsOpen) {
            signalLayerOptionsOpener.value = false
        }
    }, [activeOptionsOpen])

    useEffect(() => {
        if (state.duplicatedLayerValuesSetup && state.duplicatedLayerValuesSetup.layerKey === layer.layerKey) {
            actions.duplicatedLayerValuesSetup(null)
            setSetupFromDuplication(JSON.parse(JSON.stringify(state.duplicatedLayerValuesSetup.values)))
        }
    }, [state.duplicatedLayerValuesSetup])

    useEffect(() => {
        if (setupFromDuplication !== null) {
            signalValues.value = setupFromDuplication
            setSetupFromDuplication(null)
        }
    }, [setupFromDuplication])

    const dragUpdateSubscribeFn = update => {
        signalDragActive.value = true
        // line has inputKeys 107 and 108 for startXY and endXY
        if (update.inputKey2 === null) {
            setValue(update.inputKey, [Utility.roundFloat(update.x, 1), Utility.roundFloat(update.y, 1)]
            )
        }
        else {
            setValue(update.inputKey, [Utility.roundFloat(update.x, 1), Utility.roundFloat(update.y, 1)],
                update.inputKey2, [Utility.roundFloat(update.x2, 1), Utility.roundFloat(update.y2, 1)],
                update.lineDragType
            )
        }

    }

    useEffect(() => {
        if (layer.layerActive === 1) {
            if (Utility.emptyCheck(state.activeLayerValues)) {
                // init setup for activating base counter layer, which will always be active.
                setupInputValuesWithDefaultValues()
                layerValuesPublish()
            }
        }
        dragSubscribe({ layerKey: layer.layerKey, callback: dragUpdateSubscribeFn, add: true })
        setupCombatMovementInputKeysInfo() // special case for layer with two (sort of) required fields
        return () => {
            dragSubscribe({ layerKey: layer.layerKey, callback: dragUpdateSubscribeFn, add: false })
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const setupCombatMovementInputKeysInfo = () => {
        if (Utility.layerType(layer.layerKey, state.layers) === 'combat-movement') {
            let combatStrengthTextInputKey = ''
            let movementAllowanceTextInputKey = ''
            let input = layer.inputs.find(li => li.named === "combat")
            if (input) {
                combatStrengthTextInputKey = input.inputKey
            }
            input = layer.inputs.find(li => li.named === "movement")
            if (input) {
                movementAllowanceTextInputKey = input.inputKey
            }
            setCombatMovementInputKeys({
                combat: combatStrengthTextInputKey,
                movement: movementAllowanceTextInputKey
            })
        }
    }

    useEffect(() => {
        signalLayerEditingClass.value = editingLayer === layer.layerKey ? 'editing' : ''
    }, [editingLayer])  // eslint-disable-line react-hooks/exhaustive-deps

    const layerValuesPublish = () => {
        if (layer.layerActive === 1) {
            const stateVersionObj = JSON.parse(JSON.stringify(copyActiveLayerValues.v))
            for (const [key, value] of Object.entries(signalValues.v)) {
                stateVersionObj[layer.layerKey + '_' + key] = value
            }
            if (JSON.stringify(stateVersionObj) !== JSON.stringify(copyActiveLayerValues.v)) {
                actions.activeLayerValuesReset(stateVersionObj)
            }
        }
    }

    useEffect(() => {
        if (state.activeLayerValues) {
            copyActiveLayerValues.v = JSON.parse(JSON.stringify(state.activeLayerValues))
            if (signalValues.value === null) {
                if (Utility.layerIsInActiveLayerValues(state.activeLayerValues, layer.layerKey)) {
                    setupInputValuesWithActiveLayerValues()
                }
                else {
                    setupInputValuesWithDefaultValues()
                }
                checkLayerCanBeActive()
            }
            else {
                syncInputValues()
            }

        }
        
        //let layersThatShouldBeActive = Utility.activeLayerKeys(state.activeLayerValues)
    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (layer.layerActive) {
            layerValuesPublish()
        }
    }, [layer]) // eslint-disable-line react-hooks/exhaustive-deps

    const setupInputValuesWithActiveLayerValues = () => {
        // get key values for this layer's inputs from state.activeLayerValues
        let _values = {}
        let badValue = false
        for (const [key, value] of Object.entries(state.activeLayerValues)) {
            if (key.startsWith(layer.layerKey + '_')) {
                let inputKey = key.split('_')[1]
                _values[inputKey] = value
                if (value === null || value === undefined) {
                    badValue = true
                    break
                }
            }
        }
        if (!badValue) {
            signalValues.value = _values
        }
        else {
            console.error('got badValue:', badValue)
            deactivateLayer()
            setupInputValuesWithDefaultValues()
        }
    }

    useEffect(() => {
        // if (state.loadCounterProcessing || state.counterLoadFromSheet || state.counterLoadFromApp || state.counterLoadFromFile) {
        //     setLoadingCounterState(true)
        // }
        // if (!state.loadCounterProcessing && loadingCounterState) {
        //     setLoadingCounterState('done')
        // }
    }, [state.loadCounterProcessing, state.counterLoadFromSheet, state.counterLoadFromApp, state.counterLoadFromFile])

    useEffect(() => {
        if (loadingCounterState === 'done') {
            setLoadingCounterState(null)
            syncInputValues()
        }
    }, [loadingCounterState])

    const syncInputValues = () => {
        let newValues = { ...signalValues.v }
        for (const [key, value] of Object.entries(state.activeLayerValues)) {
            if (key.startsWith(layer.layerKey + '_')) {
                if (key.split('_').length === 2) {
                    let inputKey = key.split('_')[1]
                    newValues[inputKey] = value
                }
            }
        }
        signalValues.v = newValues
    }

    useEffect(() => {
        if (autoActivateLayer) {
            setAutoActivateLayer(false)
            activateLayer()
        }
    }, [autoActivateLayer])


    const setValue = (inputKey, value, inputKey2, value2, lineDragType) => {
        if (lineDragType) {
            // console.log('setValue ', lineDragType, 'x1:', value[0], 'y1:', value[1],
            //                                        'x2:', value2[0], 'y2:', value2[1])
        }
        // patch for the string value of selected Google icon, which is undefined when no icon is selected.
        if (inputKey === 49 && value === undefined) {
            value = ''
        }
        let cloneValues = {}
        Object.assign(cloneValues, signalValues.v)
        cloneValues[inputKey] = value

        if (inputKey2) {
            cloneValues[inputKey2] = value2
        }
        //layerValuesPublish()
        signalValues.value = cloneValues

        if (value === undefined || value === null) {
            if (Utility.layerIsInActiveLayerValues(state.activeLayerValues, layer.layerKey) && layer.layerActiveRequiredInputKey === inputKey) {
                deactivateLayer()
            }
            checkLayerCanBeActive()
            return
        }

        // check if layer should be turned out automatically.
        if (layer.layerActive) {
            //if (signalDragActive.value === false) {
            layerValuesPublish()
            //}
        }
        else {
            // if this was a layer with required values, by selecting or inputing a valid value, it will
            // activate the layer. User can still deactivate the layer, even though the required value is set
            // to something valid, by clicking on the activate button to turn it off.
            if (inputKey === layer.layerActiveRequiredInputKey) {
                if (Utility.isSvgSelectType(layer)) {
                    if (value !== null && value !== undefined && value > 0) {
                        let newLayers = state.layers.filter(ly => ly.layerKey !== layer.layerKey)
                        let updatedLayer = { ...layer, layerActive: 1 }
                        actions.layers([...newLayers, updatedLayer])
                    }

                }
                else {
                    if (Utility.isSvgSelectType(layer) || Utility.isIconSelectType(layer)) {
                        if (value !== null && value !== undefined && value !== '') {
                            let newLayers = state.layers.filter(ly => ly.layerKey !== layer.layerKey)
                            let updatedLayer = { ...layer, layerActive: 1 }
                            actions.layers([...newLayers, updatedLayer])
                        }
                    }
                    else {
                        if (Utility.isTextType(layer)) {
                            if (value !== null && value !== undefined && value !== '') {
                                let newLayers = state.layers.filter(ly => ly.layerKey !== layer.layerKey)
                                let updatedLayer = { ...layer, layerActive: 1 }
                                actions.layers([...newLayers, updatedLayer])
                            }
                        }
                    }
                }
            }
            if (Utility.originalLayerName(state.layers, layer) === 'combat-movement') {
                if (inputKey === 60 || inputKey === 61) { // 60 is combat factor, 61 is movement factor.
                    if (value !== '' && value !== '0') {
                        // make it active automatically
                        let newLayers = state.layers.filter(ly => ly.layerKey !== layer.layerKey)
                        let updatedLayer = { ...layer, layerActive: 1 }
                        actions.layers([...newLayers, updatedLayer])
                    }
                }
            }
        }

        checkLayerCanBeActive()
    }

    effect(() => {
        // might want to filter out the mouse move for the counter coordinates, other wise this will constantly be considered changed.
        if (JSON.stringify(signalValues.value) !== JSON.stringify(signalValuesPrevious.value)) {
            signalValuesPrevious.value = JSON.parse(JSON.stringify(signalValues.value))
        }
    })

    const activateLayer = () => {
        let updatedLayer = { ...layer, layerActive: 1 }
        actions.layerUpdate(updatedLayer)
    }

    const deactivateLayer = () => {
        let updatedLayer = { ...layer, layerActive: 0 }
        actions.layerUpdate(updatedLayer)
        actions.activeLayerValuesRemove(layer.layerKey)
    }

    const checkLayerCanBeActive = () => {
        if (layer.layerActiveRequiredInputKey) {
            let requiredValue = signalValues.value[layer.layerActiveRequiredInputKey]
            if (requiredValue !== null && requiredValue !== undefined && requiredValue !== '') {
                if (requiredValue === 0 && Utility.isSvgSelectType(layer)) {
                    signalLayerCanBeActive.value = false
                }
                else {
                    signalLayerCanBeActive.value = true
                }
            }
            else {
                signalLayerCanBeActive.value = false
            }
        }
        else {
            signalLayerCanBeActive.value = true
        }
        return signalLayerCanBeActive.value
    }

    const triggerTimerForUserInputExpected = () => {
        if (signalTimer.value === null) {
            signalTimer.value = setTimeout(() => {
                signalTimerCombatMovement.value = false
                signalTimer.value = null
            }, 500)
            signalTimerCombatMovement.value = true
        }
    }

    const setupInputValuesWithDefaultValues = () => {

        if (Utility.checkIfCombatMovementLayer(state.layers, layer.layerKey)) {
            triggerTimerForUserInputExpected()
        }

        let storeValues = {}
        layer.inputs.forEach(lin => {

            if (lin.named !== 'spacer') {
                if (lin.stringForArray !== null && lin.named === 'fill color') {
                    let str = lin.stringForArray
                    storeValues[lin.inputKey] = str
                }
                // there seems to be a misnaming issue between defaultFloatArrayValue and 
                // defaultArrayFloatValue. edit - its from a misnaming error I made in the db api call. It changes
                // the name from defaultArrayFloatValue to defaultFloatArrayValue.
                if (lin.defaultFloatArrayValue) {
                    // fix for bug in defaultFloatArrayValue for identifier text width height
                    if (lin.inputKey === 211) {
                        storeValues[lin.inputKey] = [100, 100]
                    }
                    else {
                        let str = lin.defaultFloatArrayValue.replace("{", "[");
                        str = str.replace("}", "]");
                        let arr = JSON.parse(str)
                        storeValues[lin.inputKey] = arr
                    }
                }


                if (lin.defaultIntValue !== null) {
                    let dint = parseInt(lin.defaultIntValue)
                    storeValues[lin.inputKey] = dint
                }

                if (lin.defaultStrValue !== null) {
                    let dstr = lin.defaultStrValue
                    storeValues[lin.inputKey] = dstr
                }

                if (lin.named === 'svgKey' || lin.type === 'button_svg_group') {
                    storeValues[lin.inputKey] = 0
                }
            }
        })
        signalValues.value = storeValues
    }

    useEffect(() => {
        let layerOpenSetting = state.layersOpen.find(lo => lo.layerKey === layer.layerKey)
        if (layerOpenSetting) {
            signalLayerOpen.value = layerOpenSetting.layerOpen
        }
        else {
            signalLayerOpen.value = false
        }
    }, [state.layersOpen])

    useEffect(() => {
        checkLayerCanBeActive()
    }, [state.layers])

    const layerOpenChange = () => {
        signalLayerOpen.value = !signalLayerOpen.value
        let _layersOpen = state.layersOpen.filter(lo => lo.layerKey !== layer.layerKey)
        _layersOpen.push({ layerKey: layer.layerKey, layerOpen: signalLayerOpen.value })
        actions.layersOpen(_layersOpen)
    }

    // useEffect(() => {
    //     if( draggingMenuLayer ) {
    //         signalLayerOptionsOpener.value = false
    //     }
    // }, [draggingMenuLayer])

    const layerOptionsOpenerChange = () => {
        signalLayerOptionsOpener.value = !signalLayerOptionsOpener.value
        if (signalLayerOptionsOpener.value) {
            optionsOpen(layer.layerKey)
        }
        else {
            optionsOpen(false)
        }
    }

    useEffect(() => {
        if (state.draggingLayer === -1) {
            if (signalDragActive.value === true) {
                layerValuesPublish()
            }
            signalDragActive.value = false
        }
    }, [state.draggingLayer]) // eslint-disable-line react-hooks/exhaustive-deps

    const processRef = ref => {
        registerRef(ref)
    }

    useEffect(() => {
        if (state.draggingLayer) {
            if (state.draggingLayer === layer.layerKey) {
                setCurrentlyDragging(true)
                return
            }
        }
        setCurrentlyDragging(false)
    }, [state.draggingLayer])

    return (
        <div className={editingLayer === layer.layerKey && signalLayerOpen.value ? 'layer-menu-item editing' : 'layer-menu-item'}
            data-name={`layerKey_${layer.layerKey}`} onClick={() => clickRegister(layer.layerKey)} >

            <div className={`layer-name-flex ${currentlyDragging ? `dragging` : ` `}`}>


                <div className="layer-name-section" id={`layer_menu_${layer.layerKey}`} data-id={layer.layerKey} draggable={layer.layerKey === 1 ? false : true} ref={(ref) => processRef(ref)}>
                    <LayerActivateButton layer={layer} layerCanBeActive={signalLayerCanBeActive.value} />
                    <div onClick={layerOpenChange} className={signalLayerOpen.value ?
                        `layer-name opened ${editingLayer === layer.layerKey ? 'editing' : ''}` : layer.layerActive === 1 ? 'layer-name layer-active' : 'layer-name closed'}>{layer.layerName}</div>
                </div>
                <div className="layer-management-controls">
                    <div className="edit-opener" onClick={layerOpenChange}>
                        <LayerMenuOpenerEdit openerState={signalLayerOpen.value} />
                    </div>
                    <div className="options" onClick={layerOptionsOpenerChange}>
                        {layer.layerName === 'base counter' ? '' :
                            <LayerOptionsOpener layer={layer} active={signalLayerOptionsOpener.value} />
                        }
                    </div>
                </div>
            </div>
            <div className="drop-area">drop layer here to reorder</div>

            <div className={signalLayerOptionsOpener.value ? 'layer-options-open' : 'display-none'}>
                <LayerOptions layer={layer} closeOptions={layerOptionsOpenerChange} />
            </div>
            <div className={signalLayerOpen.value ? signalLayerEditingClass.value : 'display-none'}>
                {signalValues.value && layer.hasOwnProperty('inputs') && layer.inputs ? layer.inputs.map((input, index) => {
                    switch (input.type) {
                        case "xy_position": return <InputXyPosition key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                            dragActive={signalDragActive.value}
                        />
                        case "width_height": return <InputWidthHeight key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                        />
                        case "text": return <InputText key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                            inputRequired={layer.layerActiveRequiredInputKey}
                        />
                        case "slider": return <InputSlider key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                            disabled={
                                signalValues.v[131] ?
                                    signalValues.v[131] === 'equilateral' && (input.inputKey === 136 || input.inputKey === 137 || input.inputKey === 138 || input.inputKey === 139 || input.inputKey === 140) ? true :
                                        signalValues.v[131] === 'isosceles' && (input.inputKey === 135 || input.inputKey === 138 || input.inputKey === 139 || input.inputKey === 140) ? true :
                                            signalValues.v[131] === 'scalene' && (input.inputKey === 135 || input.inputKey === 136 || input.inputKey === 137) ? true : false
                                    : false
                            }
                        />
                        case "radio_group": return <InputRadioGroup key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                        />
                        case "font": return <InputFont key={index}
                            input={input}
                            layerKey={layer.layerKey}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                        />
                        case "counter shapes": return <InputCounterShapes key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                        />
                        case "material symbol": return <InputMaterialSymbol key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                            inputRequired={layer.layerActiveRequiredInputKey}
                        />
                        case "button_svg_group": return <InputSvgGroup key={index}
                            layerKey={layer.layerKey}
                            //input={JSON.parse(JSON.stringify(input))}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                            inputRequired={layer.layerActiveRequiredInputKey}
                        />
                        case "color": return <InputColor key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={signalValues.v[input.inputKey]}
                            parentLayerKey={layer.parentLayerKey}
                        />
                        case "fill": return <InputFill key={index}
                            layerKey={layer.layerKey}
                            input={input}
                            setValue={setValue}
                            useValue={Utility.safeJsonParse(signalValues.v[input.inputKey])}
                            //useValue={JSON.parse(signalValues.v[input.inputKey])}
                        />

                        default: return ''
                    }
                }) : ''}
            </div>

        </div>
    )
}
export default LayerMenuItem
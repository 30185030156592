import React from 'react'
import './DexieBusyOverlay.scss'
const DexieBusyOverlay = ({active}) => {

    return (
        <div className={ active ? 'dexie-busy-overlay' : 'display-none'}>
            
        </div>
    )

}

export default DexieBusyOverlay
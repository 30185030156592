import React, { useState, useEffect, useContext } from 'react'
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './InputText.scss'
const InputText = ({ layerKey, input, setValue, useValue, inputRequired }) => {
    const { state } = useContext(StoreContext)
    const [textVal, setTextVal] = useState(null);
    const [customWidth, setCustomWidth] = useState(150)

    useEffect(() => {
        // patch for a text input width that should had been a field in the db.
        // instead I put the custom width info in the comment field.
        if (input) {
            if (input.comment && input.comment.includes('custom width')) {
                let arr = input.comment.split('custom width')
                let widthStr = arr[1]
                widthStr = widthStr.trim().replace('px','')
                if (Utility.isNumeric(widthStr)) {
                    let customWidth = parseInt(widthStr)
                    setCustomWidth(customWidth)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            setTextVal(useValue)
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (textVal === null || textVal === undefined) {
            return
        }
        let existingValue = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
        // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
        if (existingValue !== textVal) {
            setValue(input.inputKey, textVal)
        }

    }, [textVal]) // eslint-disable-line react-hooks/exhaustive-deps


    const onChange = evt => {
        setTextVal(evt.target.value)
    }

    return (
        <div id={`input_text_${input.inputKey}`} className="default-input-container input-text">
            <span className="named">{input.named}:</span>
            <input type="text" id={`input_${input.inputKey}`} style={{ width: `${customWidth}px` }}
                value={textVal ? textVal : ''}
                onChange={onChange}
            />
            {inputRequired && !textVal ? <div className="input-required">*</div> : ''}
        </div>
    )
}

export default InputText
import React, { useState, useEffect, useRef, useContext, useFocus } from 'react'
import Snap from 'snapsvg-cjs'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { useSignal, effect } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import CloseX from "../CloseX/CloseX"
import './SaveCounterControl.scss'
const SaveCounterControl = ({ saveCounter, longName }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state, actions } = useContext(StoreContext)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [saveName, setSaveName] = useState('')
    const saveCounterDialogRef = useRef(null)
    const saveCounterInputRef = useRef(null)
    const [message, setMessage] = useState({ text: '', color: '' })
    const [savedHash, setSavedHash] = useState('')
    const [selfActivatedOverlay, setSelfActivatedOverlay] = useState(false)

    useEffect(() => {
        saveCounterDialogRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        }, { signal });

        return () => {
            setMessage({ text: '', color: '' })
            setSaveName('')
            setSavedHash('')
            controller.abort();
        };
    }, [])

    // useEffect(() => {
    //     if (state.overlayClick) {
    //         setMessage({ text: '', color: '' })
    //         setSaveName('')
    //         setSavedHash('')
    //         setDialogOpen(false)
    //     }
    // }, [state.overlayClick])

    useEffect(() => {
        if (selfActivatedOverlay) {
            setSelfActivatedOverlay(false)
            close()
        }
    }, [state.windowWidthHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.overlay === false && selfActivatedOverlay) {
            setSelfActivatedOverlay(false)
            close()
        }
    }, [state.overlay])


    const onNameChange = evt => {
        let checkedName = evt.target.value
        let _message = {}
        if (checkedName === '') {
            setSaveName('')
            return
        }
        let regexResult = checkedName.match(/[-_)( a-zA-Z0-9]+$/)
        if ((regexResult === null) || (regexResult && regexResult.index > 0)) {
            _message.text = "Only alpha-numeric, spaces, and - _ ) ('"
            _message.color = "red"
            setMessage(_message)
            return
        }
        _message.text = ''
        _message.color = ''
        setMessage(_message)

        setSaveName(evt.target.value)
    }

    const applyDateAndTime = () => {
        var currentdate = new Date();
        let mth = currentdate.getMonth() + 1
        if (mth < 10) {
            mth = '0' + mth
        }
        let yr = currentdate.getFullYear()
        let day = currentdate.getDate()
        let hour = currentdate.getHours()
        let min = currentdate.getMinutes()
        if (min < 10) {
            min = '0' + min
        }
        var datetime = `_${yr}-${mth}-${day}_${hour}-${min}`
        if (saveName.includes(datetime) === false) { // dont repeatedly insert it
            setSaveName(saveName + datetime)
        }
    }

    const close = () => {
        setMessage({ text: '', color: '' })
        setSaveName('')
        setSavedHash('')
        setDialogOpen(false)
    }

    const toggleDialog = () => {
        setDialogOpen(!dialogOpen)
    }

    useEffect(() => {
        if (dialogOpen) {
            setSelfActivatedOverlay(true)
            actions.counterAreaMenuState('save counter')
            actions.overlay(true)
            saveCounterInputRef.current.focus()
        }
        else {
            if (state.counterAreaMenuState === 'save counter') {
                actions.counterAreaMenuState('')
                close()
            }
        }
    }, [dialogOpen])

    useEffect(() => {
        if (state.counterAreaMenuState !== 'save counter' && dialogOpen) {
            toggleDialog()
        }
        if (state.counterAreaMenuState === '') {
            actions.overlay(false)
        }
    }, [state.counterAreaMenuState])

    const saveInAppCommit = async () => {
        if (saveName.length < 1) {
            return
        }

        // check if counter has already been saved
        for (const [key, value] of Object.entries(state.savedCounters)) {
            if (value.name === saveName) {
                let _message = {}
                _message.text = 'Name is already used'
                _message.color = 'red'
                setMessage(_message)
                return
            }
        }


        let packagedCounterState = packageCounterState('app')
        //console.log('packagedCounterState for app:', packagedCounterState)
        let hash = Utility.cyrb53(saveName, 'snapcounter3')
        setSavedHash(hash)
        let saveCounterObj = {}
        saveCounterObj[hash] = {
            hash: hash,
            name: saveName,
            layers: state.layers.filter( ly => ly.layerActive === 1 ),
            counterSideActive: state.counterSideActive,
            layerKeysOrdered: packagedCounterState.layerKeysOrdered,
            customImageSvgKeys: packagedCounterState.customImageSvgKeys,
            customSvgKeys: packagedCounterState.customSvgKeys,
            fonts: packagedCounterState.fonts,
            layerSvgs: packagedCounterState.layerSvgs,
            dateSaved: Utility.currentDate()
        }
        // savedCounters: ['hash', 'hash', 'name', 
        //     'layers', 
        //     'counterSideActive', 
        //     'layerKeysOrdered', 
        //     'customImageSvgKeys', 
        //     'customSvgKeys', 
        //     'fonts',
        //     'layerSvgs', 
        //     'dateSaved'],

        actions.savedCounters(saveCounterObj)
    }

    useEffect(() => {
        if (!Utility.emptyCheck(state.savedCounters)) {
            if (state.savedCounters[savedHash]) {
                let _message = {}
                _message.text = "Counter has been saved"
                _message.color = "green"
                setMessage(_message)
                setSavedHash('')
            }
        }
    }, [state.savedCounters])

    const saveToFileCommit = () => {
        if (saveName.length < 1) {
            return
        }
        let includeCustomSvgsData = true // we want externally saved counters and sheets to have with them the data needed to restore fonts and customSvgs
        let packagedCounterState = packageCounterState('file')
        //console.log('packagedCounterState for file:', packagedCounterState)
        let fileCounterObj = {
            fileName: saveName,
            version: 3,
            app: 'snapCounter',
            type: 'counter',
            version: 3,
            savedAs: 'file',
            dateSaved: Utility.currentDate(),
            counterState: packagedCounterState
        }
        var zip = new JSZip();
        zip.file(saveName + ".json", JSON.stringify(fileCounterObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, saveName + '.scz');
                setMessage({ text: "Counter has been saved in zipped file format", color: "green" })
            });

    }

    const saveToSvgCommit = async () => {
        //let combinedSvg = combineSvgs()
        let combinedSvg = combineSvgsOnCounter()
        let blob = new Blob([combinedSvg], { type: 'image/svg+xml' });
        saveAs(blob, saveName + '.svg');
        setMessage({ text: "Counter has been exported in svg", color: "green" })
        //setDialogOpen(false)
    }

    const getImageDataURL = (image, type = 'png') => {
        if (type === 'jpg') type = 'jpeg';
        const { width, height } = image;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        if (type === 'jpeg') {
            context.fillStyle = 'white';
            context.fillRect(0, 0, width, height);
        }
        context.drawImage(image, 0, 0, width, height);

        return canvas.toDataURL(`image/${type}`);
    }

    const convertSvg = (svgString, type = 'png') => new Promise((res, rej) => {
        const image = new Image();
        image.onload = () => res(getImageDataURL(image, type));
        image.src = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
    });

    const saveToPngCommit = () => {
        // let source = combineSvgs()
        let source = combineSvgsOnCounter()
        convertSvg(source, 'png').then(dataURL => {
            /* do something with it */
            saveAs(dataURL, saveName + '.png');
            setMessage({ text: "Counter has been saved as a png", color: "green" })
            //setDialogOpen(false)
            // if (feedbackOnFocus === '') {
            //     setFeedbackOnFocus('saving ' + saveName + ' to png file.')
            // }
        });
    }

    const layerSvgsToArray = () => {
        let layerSvgs = []
        let _layers = [...state.layers]
        let activeLayers = _layers.sort((a, b) => a.layerOrder - b.layerOrder).filter(sl => sl.layerActive === 1)
        activeLayers.forEach(layer => {
            let id = 'drawLayer_' + layer.layerKey
            let layerEle = document.getElementById(id)
            if (layerEle) {
                let layerSvg = layerEle.innerHTML + "\n\n"
                if (layerSvg) {
                    layerSvgs.push(layerSvg)
                }
            }
        })

        return layerSvgs
    }

    const combineSvgsOnCounter = () => {
        let _layers = [...state.layers]
        let activeLayers = _layers.sort((a, b) => a.layerOrder - b.layerOrder).filter(sl => sl.layerActive === 1)
        let fontsDetected = detectFonts(activeLayers)
        let fontsString = constructFontsString(fontsDetected)

        let utime = new Date().getTime()
        let builtUpSvg = '<svg id="combined_' + utime + '" xmlns="http://www.w3.org/2000/svg" width="1000" xmlns:xlink="http://www.w3.org/1999/xlink" height="1000" viewBox="20 20 200 200">'
        builtUpSvg += '<g transform="scale(1.2)">'

        // get the active layers 
        let layersOrder = activeLayers.map(ly => ly.layerActive ? { layerKey: ly.layerKey, layerOrder: ly.layerOrder } : null)
        layersOrder = layersOrder.filter(lo => lo)
        // drawLayer_ svgLayer
        layersOrder.forEach(alk => {
            let id = 'drawLayer_' + alk.layerKey
            builtUpSvg += document.getElementById(id).innerHTML + "\n\n"
        })
        builtUpSvg += '</g></svg>'

        if (fontsString) {
            builtUpSvg = insertFontsString(builtUpSvg, fontsString)
        }

        return builtUpSvg
    }

    const detectFontsBothSides = (frontAlv, rearAlv) => {
        let fontFamilies = []
        let frontActiveLayerKeys = Utility.activeLayerKeys(frontAlv)
        frontActiveLayerKeys.forEach(lk => {
            let originalLayerName = Utility.originalLayerNameLayerKey(state.layers, lk)
            if (originalLayerName === 'Google material symbols') {
                if (fontFamilies.includes('Material Icons') === false) {
                    fontFamilies.push('Material Icons')
                }
            }
            else {
                let layer = state.layers.find(sl => sl.layerKey === lk)
                if (layer) {
                    layer.inputs.forEach(ip => {
                        if (ip.named === 'font') {
                            if (frontAlv[layer.layerKey + '_' + ip.inputKey]) {
                                let fontFamily = frontAlv[layer.layerKey + '_' + ip.inputKey]
                                if (fontFamily) {
                                    if (fontFamilies.includes(fontFamily) === false) {
                                        fontFamilies.push(fontFamily)
                                    }
                                }

                            }
                        }
                    })
                }
            }
        })
        let rearActiveLayerKeys = Utility.activeLayerKeys(rearAlv)
        rearActiveLayerKeys.forEach(lk => {
            let originalLayerName = Utility.originalLayerNameLayerKey(state.layers, lk)
            if (originalLayerName === 'Google material symbols') {
                if (fontFamilies.includes('Material Icons') === false) {
                    fontFamilies.push('Material Icons')
                }
            }
            else {
                let layer = state.layers.find(sl => sl.layerKey === lk)
                if (layer) {
                    layer.inputs.forEach(ip => {
                        if (ip.named === 'font') {
                            if (rearAlv[layer.layerKey + '_' + ip.inputKey]) {
                                let fontFamily = rearAlv[layer.layerKey + '_' + ip.inputKey]
                                if (fontFamily) {
                                    if (fontFamilies.includes(fontFamily) === false) {
                                        fontFamilies.push(fontFamily)
                                    }
                                }

                            }
                        }
                    })
                }
            }
        })

        return fontFamilies
    }

    const detectFonts = activeLayers => {
        let fontFamilies = []
        let layers = [...state.layers]

        activeLayers.forEach(ly => {
            let originalLayerName = Utility.originalLayerName(layers, ly)
            if (originalLayerName === 'Google material symbols') {
                if (fontFamilies.includes('Material Icons') === false) {
                    fontFamilies.push('Material Icons')
                }
            }
            else {
                ly.inputs.forEach(ip => {
                    if (ip.named === 'font') {
                        if (state.activeLayerValues[ly.layerKey + '_' + ip.inputKey]) {
                            let fontFamily = state.activeLayerValues[ly.layerKey + '_' + ip.inputKey]
                            if (fontFamily) {
                                if (fontFamilies.includes(fontFamily) === false) {
                                    fontFamilies.push(fontFamily)
                                }
                            }
                        }
                    }
                })
            }
        })

        return fontFamilies
    }


    const packageCounterState = (saveType) => {
        let frontAlv = state.counterSideActive.front
        let rearAlv = state.counterSideActive.rear
        if (!frontAlv || !rearAlv) {
            console.error('invalid alv values from counterSideActive')
            return
        }
        frontAlv = JSON.parse(frontAlv)
        rearAlv = JSON.parse(rearAlv)

        // get images/svgs used
        // find any custom layers in state.layers
        let customImageLayers = []
        let customSvgLayers = []
        let customImageSvgKeys = []
        let customSvgKeys = []
        let customImageSvgsData = []
        let customSvgsData = []
        state.layers.forEach(ly => {
            if (Utility.isCustomImagesLayer2(ly, state.layers)) {
                customImageLayers.push(ly)
            }
            if (Utility.isCustomSvgsLayer2(ly, state.layers)) {
                customSvgLayers.push(ly)
            }
        })
        let layerKeys = Utility.layerKeysActive(frontAlv)
        layerKeys.forEach(lk => {
            let found = customImageLayers.find(cil => cil.layerKey === lk)
            if (found) {
                let imageKey = frontAlv[lk + '_' + found.layerActiveRequiredInputKey]
                if (imageKey) {
                    if (customImageSvgKeys.includes(imageKey) === false) {
                        customImageSvgKeys.push(imageKey)
                    }
                }
            }
            found = customSvgLayers.find(cil => cil.layerKey === lk)
            if (found) {
                let svgKey = frontAlv[lk + '_' + found.layerActiveRequiredInputKey]
                if (svgKey) {
                    if (customSvgKeys.includes(svgKey) === false) {
                        customSvgKeys.push(svgKey)
                    }
                }
            }
        })

        //let activeLayers = _layers.sort((a, b) => a.layerOrder - b.layerOrder).filter(sl => sl.layerActive === 1)
        //let layersUsed = Utility.activeLayersBothSides(frontAlv, rearAlv, state.layers)

        let fontsDetected = detectFontsBothSides(frontAlv, rearAlv)

        if (saveType === 'file') {
            customImageSvgKeys.forEach( cik => {
                let found = state.svgs.find( svg => svg.svgKey === cik )
                if( found ) {
                    customImageSvgsData.push(found)
                }
            })
            customSvgKeys.forEach( csk => {
                let found = state.svgs.find( svg => svg.svgKey === csk )
                if( found ) {
                    customSvgsData.push(found)
                }
            })
            let layersUsed = state.layers.filter( sl => layerKeys.includes( sl.layerKey ) )

            let fontsData = packageFonts(fontsDetected)
            // file save
            return {
                counterSideActive: state.counterSideActive,
                layers: layersUsed,
                customImageSvgsData,
                customSvgsData,
                fontsData: fontsData
            }
        }
        else {
            // app save
            let combinedSvg = combineSvgsOnCounter()
            let layerSvgs = layerSvgsToArray()
            //let layerSvgsRear = await( layerSvgsToArray('rear')
            let layersSorted = state.layers.sort((a, b) => a.layerOrder - b.layerOrder)
            let layerKeysOrdered = layersSorted.map(ly => ly ? { layerKey: ly.layerKey, layerOrder: ly.layerOrder } : null)
            layerKeysOrdered = layerKeysOrdered.filter(lko => lko !== null)
            return {
                counterSideActive: state.counterSideActive,
                layerKeysOrdered,
                customImageSvgKeys,
                customSvgKeys,
                fonts: fontsDetected,
                combinedSvg,
                layerSvgs
            }
        }


    }

    const packageCounterStateOld = () => {
        let svgsData = []
        let _layers = [...state.layers]
        // with counterSideActive, we need to to the same thing for the reverse side.
        // The reverse side may have different layers active, including different custom svgs.
        let activeLayers = _layers.sort((a, b) => a.layerOrder - b.layerOrder).filter(sl => sl.layerActive === 1)

        let fontsDetected = detectFonts(activeLayers)
        let fontsData = packageFonts(fontsDetected)
        //let fontsString = constructFontsString(fontsDetected)
        let customSvgsData = []
        let customImageSvgsData = []
        let viewBoxes = {}

        let otherSideAlv = state.counterSideActive.active === 'front' ? state.counterSideActive.front : state.counterSideActive.rear


        activeLayers.forEach(al => {


            let svgId = `svgLayer${al.layerKey}`
            let svgEle = document.getElementById(svgId)
            let vb = svgEle.getAttribute('viewBox')
            if (vb) {
                viewBoxes[svgId] = vb
            }
            else {
                viewBoxes[svgId] = null
            }
            if (svgEle) {
                let allIds = svgEle.querySelectorAll('*[id]')
                let prepend = ''
                for (let i = 0; i < allIds.length; i++) {
                    let aid = allIds[i]
                    if (aid.id.startsWith('p') && aid.id.indexOf('_') < aid.id.length - 1) {
                        prepend = aid.id.substring(0, aid.id.indexOf('_') + 1)
                        if (prepend.length > 5 && prepend.length < 7) {
                            break;
                        }
                    }
                }

                let serializedSvgForLayer = new XMLSerializer().serializeToString(svgEle)


                // find if there are any custom svgs or images, and add them to the package, in case the
                // user layer removes the custom svgs/images or they have to reset the app and they load
                // a counter that has the now missing svg or image.
                let originalLayerName = Utility.originalLayerName(state.layers, al)
                if (originalLayerName === 'custom svgs' || originalLayerName === 'custom images') {
                    let svgKeyUsed = state.activeLayerValues[al.layerKey + '_' + al.layerActiveRequiredInputKey]
                    let customSvgData = state.svgs.find(ss => Number(ss.svgKey) === Number(svgKeyUsed))
                    if (customSvgData) {
                        if (originalLayerName === 'custom svgs') {
                            customSvgsData.push(customSvgData)
                        }
                        if (originalLayerName === 'custom images') {
                            customImageSvgsData.push(customSvgData)
                        }
                    }
                }

                let newPrepend = 'p' + Utility.randomString(5) + '_'
                serializedSvgForLayer = serializedSvgForLayer.replaceAll(svgId, newPrepend + svgId)
                if (prepend) {
                    serializedSvgForLayer = serializedSvgForLayer.replaceAll(prepend, newPrepend)
                }

                if (al.layerKey && serializedSvgForLayer.length > 0 && newPrepend) {
                    let csObj = {
                        layerKey: al.layerKey,
                        svg: serializedSvgForLayer,
                        svgIdPrepend: newPrepend,
                        viewBox: viewBoxes['svgLayer' + al.layerKey]
                    }
                    svgsData.push(csObj)
                }
            }
        })

        return {
            activeLayerValues: state.activeLayerValues,
            counterSideActive: state.counterSideActive,
            layers: activeLayers,
            svgsData: svgsData,
            customSvgsData,
            customImageSvgsData,
            fontsData: fontsData
        }


    }


    // let fontFamilies = detectFonts()
    // let fontStyleString = ''
    // if (fontFamilies.length > 0) {

    //     for (let i = 0; i < fontFamilies.length; i++) {
    //         let fontFamily = fontFamilies[i]
    //         let fontData = await Utility.getDexieStoreSingleItem(state.dexie, 'fonts', fontFamily, 'fontFamily')
    //         if (fontData) {
    //             fontStyleString += "@font-face { font-family: '" + fontFamily + "'; "
    //             fontStyleString += 'font-weight: ' + fontData.fontWeight + ';'
    //             if (fontData.fontSrc.includes("format('woff2')") === false) {
    //                 fontStyleString += 'src: ' + fontData.fontSrc + " format('woff2');"
    //             }
    //             else {
    //                 fontStyleString += 'src: ' + fontData.fontSrc + ';'
    //             }
    //             fontStyleString += '} '
    //             fontStyleString = fontStyleString.replaceAll(';;', ';')
    //         }

    //     }
    //     if (fontStyleString) {
    //         fontStyleString = '<style>' + fontStyleString + '</style>'
    //     }

    // }

    const insertFontsString = (svgString, fontsString) => {
        let newSvgString = svgString
        let fontStyleString = fontsString
        if (fontStyleString) {
            let defSlashPos = newSvgString.indexOf('<defs/>')
            let defPos = newSvgString.indexOf('<defs>')
            // get the earliest one
            if (defSlashPos === -1) {
                defSlashPos = 99999999
            }
            if (defPos === -1) {
                defPos = 99999999
            }
            if (defSlashPos && (defSlashPos < defPos)) {
                newSvgString = newSvgString.replace('<defs/>', '<defs>' + fontStyleString + '</defs>')
            }
            if (defPos && (defPos < defSlashPos)) {
                newSvgString = newSvgString.substring(0, defPos + 6) + fontStyleString + newSvgString.substring(defPos + 6)
                //newSvgString = newSvgString.replace('<defs/>', fontStyleString + '<defs/>')
            }
        }

        return newSvgString
    }

    const packageFonts = fontFamilies => {
        let fontsData = []
        if (fontFamilies.length > 0) {
            for (let i = 0; i < fontFamilies.length; i++) {
                let fontFamily = fontFamilies[i]
                //let fontData = await Utility.getDexieStoreSingleItem(state.dexie, 'fonts', fontFamily, 'fontFamily')
                let fontData = state.fonts.find(sf => sf.fontFamily === fontFamily)
                if (fontData) {
                    fontsData.push(fontData)
                }
            }
        }
        return fontsData.length > 0 ? fontsData : null
    }

    const constructFontsString = fontFamilies => {
        let fontStyleString = ''
        if (fontFamilies.length > 0) {

            for (let i = 0; i < fontFamilies.length; i++) {
                let fontFamily = fontFamilies[i]
                //let fontData = await Utility.getDexieStoreSingleItem(state.dexie, 'fonts', fontFamily, 'fontFamily')
                let fontData = state.fonts.find(sf => sf.fontFamily === fontFamily)
                if (fontData) {
                    fontStyleString += "@font-face { font-family: '" + fontFamily + "'; "
                    fontStyleString += 'font-weight: ' + fontData.fontWeight + ';'
                    if (fontData.fontSrc.includes("format('woff2')") === false) {
                        fontStyleString += 'src: ' + fontData.fontSrc + " format('woff2');"
                    }
                    else {
                        fontStyleString += 'src: ' + fontData.fontSrc + ';'
                    }
                    fontStyleString += '} '
                    fontStyleString = fontStyleString.replaceAll(';;', ';')
                }

            }
            if (fontStyleString) {
                fontStyleString = '<style>' + fontStyleString + '</style>'
            }

        }
        return fontStyleString
    }

    return (
        <div className="save-counter-control">
            <button className={state.counterSideActive.active === 'front' ? 'standard-button blue' : 'standard-button disabled'} onClick={state.counterSideActive.active === 'front' ? toggleDialog : null}>Save{longName ? ' Counter' : ''}</button>

            <div ref={saveCounterDialogRef} id="dialog" className={dialogOpen ? 'dialog' : 'display-none'}>
                <div className="title">
                    <div></div>
                    <div>Save Counter</div>
                    <CloseX close={toggleDialog} />
                </div>
                <div className="name-input">
                    <div className="apply-date-time" onClick={applyDateAndTime}>click to append date+time to name</div>
                    <div className="save-input-inline">

                        <div>save name:</div>
                        <div><input id="wtf" className={message.color === "red" ? 'warning' : ''} ref={saveCounterInputRef} autoFocus={true} type="text"
                            value={saveName} onChange={onNameChange} /></div>
                    </div>
                </div>
                <div className="message-container">
                    <div className={`message ${message.color}`}>{message.text}</div>
                </div>
                <div className="save-counter-options-container">
                    <div className="save-counter-option">
                        <button className={saveName ? 'standard-button blue' : 'standard-button disabled'} onClick={saveName ? saveInAppCommit : null}>
                            save in app
                        </button>
                        <button className={saveName ? 'standard-button blue' : 'standard-button disabled'} onClick={saveName ? saveToFileCommit : null}>
                            save to file
                        </button>
                        <button className={saveName ? 'standard-button blue' : 'standard-button disabled'} onClick={saveName ? saveToSvgCommit : null}>
                            save to svg<span>*</span>
                        </button>
                        <button className={saveName ? 'standard-button blue' : 'standard-button disabled'} onClick={saveName ? saveToPngCommit : null}>
                            save to png<span>*</span>
                        </button>
                    </div>
                    {/*
                    <div className="save-counter-option">
                        <button className={saveName ? 'action-button cyanish' : 'action-button disabled'} onClick={saveName ? saveToFileCommit : null}>
                            save to file
                        </button>
                    </div>
                    <div className="save-counter-option">
                        <button className={saveName ? 'action-button cyanish' : 'action-button disabled'} onClick={saveName ? exportToSvgCommit : null}>
                            export to svg<span>*</span>
                        </button>
                    </div>
                    <div className="save-counter-option">
                        <button className={saveName ? 'action-button cyanish' : 'action-button disabled'} onClick={saveName ? exportToPngCommit : null}>
                            export to png<span>*</span>
                        </button>
    </div> */}
                </div>

                <div className="asterisk-explanation">
                    <span>*</span>These options are not importable back into the app.
                    <p>Note that with svg file exports, any fonts included in the svg file
                        will be ignored by most applications and editors, by default, due to
                        security and copyright concerns.</p>
                </div>
            </div>
        </div>
    )

}
export default SaveCounterControl


import React, { useState, useEffect, useContext } from 'react'
import { useSignal, effect } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility";
import './LayerDuplicateForm.scss'
const LayerDuplicateForm = ({ layer, closeMessage, closeOptions }) => {
    const { state, actions } = useContext(StoreContext)
    const signalExistingLayerNames = useSignal([])
    const [duplicateName, setDuplicateName] = useState('')
    const [layerDuplicated, setLayerDuplicated] = useState(false)

    useEffect(() => {
        signalExistingLayerNames.value = state.layers.map(function (ly) { return ly.layerName });
        setDuplicateName(generateSuggestedName())
    }, [])

    const generateSuggestedName = () => {
        let suggestedName = layer.layerName
        for (let i = 2; i < 100; i++) {
            if (!signalExistingLayerNames.value.includes(suggestedName + i)) {
                return suggestedName + i
            }
        }
    }

    const textChange = evt => {
        setDuplicateName(evt.target.value)
    }

    const onDuplicateLayerSubmit = evt => {
        evt.preventDefault()
        evt.stopPropagation();
        if (duplicateName.trim().length > 1 && !signalExistingLayerNames.value.includes(duplicateName.trim().value)) {
            // duplicate the layer
            duplicateLayer()
        }
    }

    const onCancelDuplicateLayer = evt => {
        evt.preventDefault()
        evt.stopPropagation();
        closeMessage()
    }

    const duplicateLayer = () => {
        let _layers = []
        let _layer = null

        let duplicateLayer = JSON.parse(JSON.stringify(layer))
        duplicateLayer.layerName = duplicateName.trim()
        if (layer.parentLayerKey === -1) {
            duplicateLayer.parentLayerKey = layer.layerKey
        }
        else {
            duplicateLayer.parentLayerKey = layer.parentLayerKey
        }
        duplicateLayer.layerHidden = 0
        duplicateLayer.layerActive = layer.layerActive
        duplicateLayer.layerOrder += 0.5

        let maxLayerKey = Math.max(...state.layers.map(ly => parseInt(ly.layerKey)), 0)
        duplicateLayer.layerKey = maxLayerKey + 1

        // fix up orderings, since we're jamming in a new layer inbetween two (unless its at the end)
        _layers = [...state.layers]
        _layers.push(duplicateLayer)
        _layers.sort((a, b) => a.layerOrder - b.layerOrder)
        _layers.forEach((ly, index) => {
            ly.layerOrder = index
        })
        setLayerDuplicated(true)

        actions.layers(_layers)

        if( layer.layerActive ) {
            let alv = {...state.activeLayerValues}
            for(const [key,value] of Object.entries(alv) ) {
                if(key.startsWith(layer.layerKey + '_') ) {
                    let newKey = key.replace(layer.layerKey + '_', duplicateLayer.layerKey + '_')
                    alv[newKey] = value 
                }
            }
            actions.activeLayerValuesReset(alv)
        }
    }

    useEffect(() => {
        if (layerDuplicated) {
            closeMessage()
            closeOptions()
        }

    }, [state.layers])

    return (
        <div className="layer-duplicate-form">
            <form id={`form_duplicate_${layer.layerKey}`} onSubmit={onDuplicateLayerSubmit}>
                <div>Name must be unique and greater than 1 character</div>
                <div>duplicate layer name: <input className={signalExistingLayerNames.value.includes(duplicateName.trim().value) ? 'warning' : ''}
                    type="text" onChange={textChange} value={duplicateName} /></div>
                <div>

                    <button type="submit"
                        className={duplicateName.length <= 1 || signalExistingLayerNames.value.includes(duplicateName) ? 'standard-button disabled' : 'standard-button blue'}
                        disabled={duplicateName.length <= 1 || signalExistingLayerNames.value.includes(duplicateName)}
                    ><div>submit</div></button>
                    <button className="standard-button yellow" onClick={onCancelDuplicateLayer} ><div>cancel</div></button>

                </div>
            </form>
            <div>Be aware that if you create a duplicate layer, and use it in counters and sheets, then delete the duplicated layer,
                the layer will soon disappear from your counters.
            </div>
        </div>
    )




}
export default LayerDuplicateForm
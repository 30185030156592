

const BaseCounter = {

    draw: (g, settings) => {
        if( ! settings || settings.length === 0 ) {
            return
        }
        let derivedValues = {}

        settings.forEach(setting => {
            switch (setting.name) {
                case 'fill color':
                    derivedValues.fillColor = setting.value
                    break
                case 'counter shape':
                    derivedValues.counterShape = parseInt(setting.value)
                    break
                case 'spacer': break
                default: 
                    console.warn('we seem to have missed one: ', setting.name)
                
            }
        })

        let fillColorObj = derivedValues.fillColor
        let fillInfo = ''
        if (fillColorObj.fillType === 'solid') {
            fillInfo = fillColorObj.fillColor
        }
        else { // gradient
            let type = derivedValues.fillColor.gradientDirection === 'radial' ? 'r' : 'l'
            let pxStart = derivedValues.fillColor.xyPositionStart.split(',')[0]
            let pyStart = derivedValues.fillColor.xyPositionStart.split(',')[1]
            let pxEnd = derivedValues.fillColor.xyPositionEnd.split(',')[0]
            let pyEnd = derivedValues.fillColor.xyPositionEnd.split(',')[1]
            let color1 = derivedValues.fillColor.colorStart
            let color2 = derivedValues.fillColor.colorMid
            let color3 = derivedValues.fillColor.colorEnd
            let defg = `${type}(${pxStart}, ${pyStart}, ${pxEnd}, ${pyEnd})${color1}-${color2}-${color3}`
            let parent = g.parent()
            var cg = parent.gradient(defg);
            fillInfo = cg
        }
        derivedValues.fillInfo = fillInfo
        switch (derivedValues.counterShape) {
            case 0: BaseCounter.drawSquare(g, derivedValues);
                break;
            case 1: BaseCounter.drawCircle(g, derivedValues);
                break;
            case 2: BaseCounter.drawRectangle(g, derivedValues);
                break;
            default: console.warn('no counter shape detected!:', derivedValues.counterShape)
        }
    },

    drawSquare: (g, derivedValues) => {
        g.rect(-100, -100, 200, 200).attr({ strokeWidth: 0, fill: derivedValues.fillInfo })
    },

    drawCircle: (g, derivedValues) => {
        g.circle(0, 0, 100).attr({
            "stroke": "none",
            fill: derivedValues.fillInfo
        })
    },

    drawRectangle: (g, derivedValues) => {
        g.rect(-100, -50, 200, 100).attr({ strokeWidth: 0, fill: derivedValues.fillInfo })
    },

}
export default BaseCounter
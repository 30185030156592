import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const Hexagon = {

    draw: (g, settings) => {
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'label': break
                case 'variable spacer': break
                case 'size':
                    derivedValues.size = Number(setting.value)
                    break
                case 'center xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0]) ) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1]) ) {
                        setting.value[1] = 1
                    }
                    derivedValues.centerXpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.centerYpos = Utility.roundFloat(setting.value[1], 1)
                    break
                    case 'fill color': derivedValues.fillColor = setting.value
                    break
                case 'stroke color':
                    derivedValues.stroke = setting.value
                    break
                case 'stroke width':
                    derivedValues.strokeWidth = Number(setting.value)
                    break
                case 'rotation':
                    derivedValues.rotation = Number(setting.value)
                    break
                case 'opacity':
                    derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'spacer': break
                default: console.warn('we seem to have missed one: ', setting.name, ' with value ', setting.value, ' setting:', setting)
            }
        })


        let fillColorObj = derivedValues.fillColor
        let fillInfo = ''
        if( fillColorObj.fillType === 'solid' ) {
            fillInfo = fillColorObj.fillColor
        }
        else { // gradient
            let type = derivedValues.fillColor.gradientDirection === 'radial' ? 'r' : 'l'
            let pxStart = derivedValues.fillColor.xyPositionStart.split(',')[0]
            let pyStart = derivedValues.fillColor.xyPositionStart.split(',')[1]
            let pxEnd = derivedValues.fillColor.xyPositionEnd.split(',')[0]
            let pyEnd = derivedValues.fillColor.xyPositionEnd.split(',')[1]
            let color1 = derivedValues.fillColor.colorStart
            let color2 = derivedValues.fillColor.colorMid
            let color3 = derivedValues.fillColor.colorEnd
            let defg = `${type}(${pxStart}, ${pyStart}, ${pxEnd}, ${pyEnd})${color1}-${color2}-${color3}`
            let parent = g.parent()
            var cg = parent.gradient(defg);
            fillInfo = cg
        }
        derivedValues.fill = fillInfo

        Hexagon.drawHexagon(g, derivedValues)

        if (derivedValues.opacity < 1) {
            g.attr({ "opacity": derivedValues.opacity })
        }

        if (derivedValues.rotation > 0) {
            Hexagon.applyRotation(g, derivedValues.rotation)
        }

        if (derivedValues.centerXpos !== 0 || derivedValues.centerYpos !== 0) {
            Hexagon.applyTranslation(g, derivedValues.centerXpos, derivedValues.centerYpos)
        }

    },

    drawHexagon: (g, derivedValues) => {
        let r = derivedValues.size / 2
        let x = 0
        let y = 0
        const a = 2 * Math.PI / 6;
        let d = ''
        let mx = 0
        let my = 0
        for (var i = 0; i < 6; i++) {
            mx = x + r * Math.cos(a * i)
            my = y + r * Math.sin(a * i)
            mx = Utility.roundFloat(mx, 1)
            my = Utility.roundFloat(my, 1)
            if (d === '') {
                d = `M ${mx} ${my} L`
            }
            else {
                d += ` ${mx} ${my}`
            }
        }
        d += ' z'
        g.path(d).attr({
            fill: derivedValues.fill,
            stroke: derivedValues.stroke,
            strokeWidth: derivedValues.strokeWidth
        })

    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
}

export default Hexagon
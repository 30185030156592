import React from 'react'
import './CloseX.scss'
const CloseX = ({close}) => {

    return (
        <div className="close-x">
            <button className="standard-button blue" onClick={close}><div>X</div></button>
        </div>
    )

}

export default CloseX